import React, { useEffect } from "react";
import { Marker, useMapEvent } from "react-leaflet";
import L from "leaflet";
import { useMap } from "Utils/hooks";
import { Box } from "@mui/system";
import ReactDOMServer from "react-dom/server";
import { GpsFixed } from "@mui/icons-material";

const LeafletGeolocationMarker = () => {
  const { geolocation, setGeolocation } = useMap();

  const map = useMapEvent("locationfound", (e) => {
    setGeolocation(e.latlng);
    // map.flyTo(e.latlng, 13);
  });

  useEffect(() => {
    map.locate({
      watch: true,
      timeout: 100000,
      maximumAge: 10000,
      enableHighAccuracy: true,
    });
    return () => map.stopLocate();
  }, []);

  return geolocation ? (
    <Marker
      icon={L.divIcon({
        className: "custom icon",
        html: ReactDOMServer.renderToString(<GeoMarker />),
      })}
      position={[geolocation.lat, geolocation.lng]}
    />
  ) : null;
};

export default LeafletGeolocationMarker;

const GeoMarker = () => {
  return (
    <Box
      style={{
        backgroundColor: "rgba(255,255,255,.6)",
        backdropFilter: "blur(3px)",
        borderColor: "transparent",
        borderWidth: "0px",
        borderStyle: "solid",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: 0,
        width: 40,
        height: 40,
        borderRadius: "50%",
        boxShadow: 3,
        position: "fixed",
        top: 0,
        left: 0,
      }}
    >
      <GpsFixed htmlColor="red" />
    </Box>
  );
};
