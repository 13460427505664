import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { Html } from "@react-three/drei";
import React from "react";
import { useTranslation } from "react-i18next";
import { animated } from "@react-spring/three";
const AnimHTML = animated(Html);

const EditorLabel = ({ active, position, onClick }) => {
  const [x, y, z] = position?.get();
  const { t } = useTranslation();
  return (
    <Html
      center
      distanceFactor={30}
      position={[x, y, z + 2]}
      zIndexRange={[0, 0]}
    >
      <Box
        sx={{
          bgcolor: "rgba(255,255,255,.7)",
          whiteSpace: "nowrap",
        }}
        borderRadius={1}
        p={1}
        boxShadow={2}
        display="flex"
        alignItems="center"
        justifyContent={"center"}
        onClick={onClick}
      >
        <Typography variant="caption">
          {active
            ? t("/editor.main.move_and_lock")
            : t("/editor.main.click_unlock")}
        </Typography>
      </Box>
    </Html>
  );
};

export default EditorLabel;
