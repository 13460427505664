import { useSpring } from "@react-spring/three";
import React, { useEffect } from "react";
import { useActualLayer, useMap } from "Utils/hooks";
import { convertCoords } from "Utils/positionConverter";
import Arrow from "../3D/models/Arrow";
import { MathUtils } from "three";
import Druid from "../3D/models/Druid";
import Sailor from "../3D/models/Sailor";

const GeoMarker = ({ position }) => {
  const { actualLayer } = useActualLayer();
  const { configuration } = useMap();
  const { model, scale: s } = configuration?.geoMarker ?? {};
  const [anim, api] = useSpring(
    () => ({
      from: { position: null, rotation: null },
      to: { position: [0, 0, 0], rotation: [0, 0, 0] },
      useNativeDriver: true,
    }),
    [model]
  );
  const handleMove = ({ latitude, longitude, speed, heading }) => {
    const {
      position: { x, y },
    } = convertCoords(latitude, longitude, actualLayer?.geoPoints) ?? {
      position: { x: 0, y: 0 },
    };

    api.start({
      position: [x.toFixed(3), 0, y.toFixed(3)],
      rotation: [0, 2 * Math.PI - MathUtils.degToRad(heading ?? 0), 0],
    });
  };

  useEffect(() => {
    let watchId = null;

    if ("geolocation" in navigator) {
      watchId = navigator.geolocation.watchPosition(
        function (position) {
          handleMove(position.coords);
        },

        (e) => console.log("e:", e),
        { timeout: 5000, enableHighAccuracy: true, maximumAge: 3000 }
      );
    }

    return () => {
      watchId && navigator.geolocation.clearWatch(watchId);
    };
  }, []);

  return model === "druid" ? (
    <Druid
      scale={s ? [s, s, s] : [2, 2, 2]}
      position={position ?? anim.position}
      rotation={anim.rotation}
    />
  ) : model === "sailor" ? (
    <Sailor
      scale={s ? [s, s, s] : [0.3, 0.3, 0.3]}
      position={position ?? anim.position}
      rotation={anim.rotation}
    />
  ) : (
    <Arrow
      scale={s ? [s, s, s] : [0.7, 0.1, 0.7]}
      position={position ?? anim.position}
      rotation={anim.rotation}
    />
  );
};

export default GeoMarker;
