import { useSearchParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { GET_POI, GET_COLLECTION } from "Config/API/queries";
import { useLayers } from "./layers";
import { useTranslation } from "react-i18next";

const useWiviContent = ({ id, type, i18n, title, iso : lang }) => {
  const QUERY = type === "pointOfInterest" ? GET_POI : GET_COLLECTION;
  const [params] = useSearchParams();
  const iso = lang ?? params.get("hl") ?? "fr";
  const { data, loading, refetch, error } = useQuery(QUERY, {
    variables: { id, iso, uuid: id },
    skip: !["pointOfInterest", "collection"].includes(type),
  });
  const { layer } = useLayers(type === "layerLink" && id);
  const {
    t,
    i18n: { resolvedLanguage },
  } = useTranslation();

  const getText = () => {
    const text = data?.[type]?.i18n?.title?.value?.text;
    const i18nValue = i18n?.title?.value?.text;
    const titleValue = title?.filter(
      (e) => e.language === resolvedLanguage
    )?.[0]?.value?.text;

    switch (type) {
      case "collection":
        return (
          text ??
          titleValue ??
          i18nValue ??
          t("/editor.sideBar.default_ph_title")
        );
      case "pointOfInterest":
        return (
          text ??
          titleValue ??
          i18nValue ??
          t("/editor.sideBar.default_ph_title")
        );
      case "mapLink":
        return titleValue ?? i18nValue ?? t("/editor.gui.maplink");
      case "layerLink":
        return (
          titleValue ??
          i18n?.title?.value?.text ??
          layer?.i18n?.title?.value?.text ??
          t("/editor.gui.layer")
        );
      default:
        return (
          text ??
          titleValue ??
          i18nValue ??
          t("/editor.sideBar.default_ph_title")
        );
    }
  };

  return {
    data: data?.[type],
    refetch,
    loading,
    error,
    title: getText(),
  };
};

export default useWiviContent;
