import React from "react";
import {
  EditorSidePanel,
  EditorMap,
  ConfigButton,
} from "Components/Threejs/Editor";
import Box from "@mui/material/Box";
import GUI from "Components/Global/GUI";
import { ToastContainer } from "react-toastify";
import { TopBar } from "Components//Global";

import LayerSelector from "Components/Global/LayerSelector";

export default function Editor() {
  return (
    <Box height="100%" display="flex" flexDirection="column">
      <ToastContainer />
      <TopBar />
      <Box height="100%" display="flex" position="relative">
        <Box zIndex={3} position="absolute" top={16} right={16}>
          <LayerSelector />
        </Box>
        {/* <LayersButtons /> */}
        <EditorSidePanel />
        <GUI />
        <EditorMap />
        <ConfigButton />
      </Box>
      {/* <BottomBar /> */}
    </Box>
  );
}
