import React from "react";
import {
  createTheme,
  ThemeProvider,
  StyledEngineProvider,
  responsiveFontSizes,
} from "@mui/material/styles";

import { useLocation } from "react-router-dom";
import { useStore } from "Store";
import { setPalette } from "Utils/palette";

const CustomMUITheme = ({ children }) => {
  const { pathname } = useLocation();
  const customTheme = useStore((s) => s.theme);

  const theme = ["/", "/indoor", "/outdoor", "/error", "///"].includes(pathname)
    ? customTheme
    : setPalette();
  const { projectId } = window.__CONFIG__ ?? {};

  const MUItheme =
    theme &&
    createTheme({
      typography: {
        fontFamily: projectId === "tatihou" ? "Brandon" : "Roboto",
        body1: {
          fontSize: 16,
        },
        h1: {
          fontSize: 89.76,
        },
        h2: {
          fontSize: 67.34,
        },
        h3: {
          fontSize: 50.52,
        },
        h4: {
          fontSize: 37.9,
        },
        h5: {
          fontSize: 28.43,
        },
        h6: {
          fontSize: 21.33,
          lineHeight: 3,
        },
      },
      palette: {
        primary: { ...theme?.primary },
        secondary: { ...theme?.secondary },
      },
      overrides: {
        MuiButton: {
          label: {
            fontFamily: "Roboto",
            fontWeight: 500,
            textTransform: "none",
          },
        },
        MuiTooltip: {
          tooltip: {
            fontFamily: "Avenir",
            fontSize: "14px",
          },
        },
      },
    });

  const responsiveTheme = theme && responsiveFontSizes(MUItheme);
  return (
    theme && (
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={responsiveTheme}>{children}</ThemeProvider>
      </StyledEngineProvider>
    )
  );
};

export default CustomMUITheme;
