import { Backdrop, Box, Fade, Modal } from "@mui/material";
import React from "react";
import { useGeoPositionMap } from "Utils/hooks";
import GeoPoints from "./geoPoints";
import GeoPositionMap from "./geoPositionMap";

const GeoPositionModal = () => {
 const {layer, close} = useGeoPositionMap()
  

  return layer ? (
    <Modal
      open
      onClose={close}
      closeAfterTransition
      // BackdropComponent={Backdrop}
      onBackdropClick={close}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in>
        <Box
          width="100vw"
          height="100vh"
          display="flex"
          alignItems="center"
          justifyContent="center"
          zIndex={3}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            height="80%"
            minWidth='80%'
            boxShadow={3}
            style={{ background: "white" }}
          >
            <Box width='50%' style={{background:'#DADFE1'}}>
              <GeoPositionMap />
            </Box>
            <Box  width='100%' height='100%' sx={{overflowY:"scroll"}}>
              <GeoPoints/>
            </Box>
          </Box>
        </Box>
      </Fade>
    </Modal>
  ) : null;
};

export default GeoPositionModal;
