import { Box, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

const Error = () => {
  const { t } = useTranslation();
  return (
    <Box
    
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      width="100%"
      height="100%"
      textAlign={'center'}
    >
      <Typography variant="h4" color="primary" fontFamily={"quattrocento"} maxWidth='80%'>
      😇 {t("/error.title")}
      </Typography>
    </Box>
  );
};

export default Error;
