import { Icon, Typography } from "@mui/material";
import { Box } from "@mui/system";
import chroma from "chroma-js";
import React from "react";
import { useMap } from "Utils/hooks";

const LeafletClusterMarker = ({ color, cluster, draggable, scale = 1 }) => {
  const { configuration } = useMap();
  const defaultColor =
    configuration?.clusterColor ??
    window?.__CONFIG__?.theme?.primaryColor ??
    "red";
  const light = chroma(defaultColor).alpha(0.2).css();

  return (
    <Box
      style={{
        backgroundColor: defaultColor,
        outline: `8px solid ${light}`,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: 3,
        width: 40 * scale ?? 1,
        height: 40 * scale ?? 1,
        borderRadius: "50%",
        boxShadow: 3,
        position: "fixed",
        left: -20 * scale ?? 1,
        top: -20 * scale ?? 1,
      }}
    >
      <Typography fontWeight="bold" variant="h2">
        {cluster.getChildCount()}
      </Typography>
    </Box>
  );
};

export default LeafletClusterMarker;
