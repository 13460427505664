import React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { useStore } from "Store";

export default function LightPosition({ index }) {
  const tempData = useStore((s) => s.tempData);
  const setTempData = useStore((s) => s.setTempData);
  const lights = tempData?.lights;
  const position = lights[index].position;

  const move = (key, value) => {
    position[key] += value;
    setTempData({ lights: lights });
  };

  return (
    <Box display="flex" alignItems="center">
      <Box width={80} mx={2} maxHeight={200}>
        <Grid container justifyContent="space-between">
          <Grid item xs={12}>
            <Box display="flex" justifyContent="center" alignItems="center">
              <IconButton size="small" onClick={() => move(2, -1)}>
                <KeyboardArrowUpIcon fontSize="small" />
              </IconButton>
            </Box>
          </Grid>

          <Grid item xs={4}>
            <Box display="flex" justifyContent="flex-start" alignItems="center">
              <IconButton size="small" onClick={() => move(0, -1)}>
                <KeyboardArrowLeftIcon fontSize="small" />
              </IconButton>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box display="flex" justifyContent="flex-end" alignItems="center">
              <Tooltip title="Déplacement horizontal">
                <FiberManualRecordIcon color="primary" size="small" />
              </Tooltip>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box display="flex" justifyContent="flex-end" alignItems="center">
              <IconButton size="small" onClick={() => move(0, 1)}>
                <KeyboardArrowRightIcon fontSize="small" />
              </IconButton>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Box display="flex" justifyContent="center" alignItems="center">
              <IconButton size="small" onClick={() => move(2, 1)}>
                <KeyboardArrowDownIcon fontSize="small" />
              </IconButton>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box display="flex" flexDirection="column">
        <Tooltip title="déplacement vertical">
          <Box my={0.5}>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => move(1, 1)}
            >
              +
            </Button>
          </Box>
        </Tooltip>
        <Box my={0.5}>
          <Button
            disabled={position[1] <= 0}
            variant="outlined"
            color="primary"
            onClick={() => move(1, -1)}
          >
            -
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
