import React, { useMemo, useRef } from "react";

import { Html } from "@react-three/drei";
import { Model, Model2D, Placeholders, Loading } from "../3DComponents";
import GeoMarker from "Components/Threejs/3DComponents/Placeholders/HTML/geoMarker";
import {
  useLayers,
  useMap,
  useActualLayer,
  useActualPlaceholder,
} from "Utils/hooks";
import { Object3D } from "three";
import { animated, useSpring } from "@react-spring/three";
import MapCanvas from "../3DComponents/Map/MapCanvas";

const Map = () => {
  const { layers } = useLayers();
  const { isGeolocation } = useActualLayer();
  const { configuration } = useMap();

  return (
    <MapCanvas>
      {layers?.length ? (
        <>
          <Spot />
          {isGeolocation && <GeoMarker />}
          {configuration?.mapType === "3d" ? <Model /> : <Model2D />}
          <Placeholders />
        </>
      ) : (
        <Html
          position={[0, 0, 0]}
          center
          style={{
            display: "flex",
            flexDirection: "column",
            height: "100vh",
            width: "100vw",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h3>Oups, il n'y a pas de données pour ce plan</h3>
        </Html>
      )}
    </MapCanvas>
  );
};

export default Map;

const Spot = () => {
  const { data } = useActualPlaceholder();
  const light = useRef();
  const target = useRef();
  const { x, y, z } = data?.position ?? { x: 0, y: 0, z: 0 };
  const targetObject = useMemo(() => new Object3D(), []);
  const { intensity } = useSpring({
    intensity: data?.id ? 0.3 : 0,
    delay: data?.id ? 300 : 0,
  });

  return (
    <>
      <primitive ref={target} object={targetObject} position={[x, y, z]} />;
      <animated.spotLight
        target={target?.current}
        ref={light}
        angle={Math.PI / 4}
        penumbra={0.4}
        position={[x, 8, y]}
        color="white"
        intensity={intensity}
      />
    </>
  );
};
