import { Button, Divider,  Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useTranslation } from "react-i18next";
import { useLayers, useMap } from "Utils/hooks";
import AddressFinder from "../Editor/addressFinder";
import LayerList from "./LayerList";
import { v4 as uuid } from "uuid";


const LayersPanel = () => {
    const { t } = useTranslation();
    const { addLayer, layers } = useLayers();
    const { leafletInstance } = useMap();
    const addLeafletLayer = (data) => {
        const { lat, lon, zoom } = data ?? {};
        const newLayer = {
          name: data.display_name,
          scale: 1,
          rotation: 0,
          id: uuid(),
          position: {
            x: parseFloat(lon),
            y: parseFloat(lat),
            z: layers?.length ?? 0,
          },
          data: {
            zoom: zoom,
            index: layers?.length ?? 0,
          },
        };
        addLayer(newLayer);
      };
    
      const handleAddress = (data) => {
        const { lat, lon, zoom } = data ?? {};
        leafletInstance.flyTo([parseFloat(lat), parseFloat(lon)], zoom);
      };
    
      const addFromMap = () => {
        const { lat, lng } = leafletInstance.getCenter();
        const zoom = leafletInstance.getZoom();
    
        addLeafletLayer({
          zoom,
          lat,
          lon: lng,
          display_name: t("/leafletconfig.layers.default_layer_name"),
        });
      };
  return (
    <>
    {layers?.length ? (
      <>
        <LayerList />
        <Divider color="#F7F7F7" />
      </>
    ) : null}
    <Box my={2}>
      <Typography variant="h6" color="primary">
        {t("/leafletconfig.layers.add_layer")}
      </Typography>
      <AddressFinder
        callback={handleAddress}
        i18n="/leafletconfig.layers.search_address"
      />
      <Button onClick={addFromMap} color="primary" variant="outlined">
        {t("/leafletconfig.layers.create_layer")}
      </Button>
    </Box>
  </>
  )
}

export default LayersPanel