import { useEffect, useMemo, useRef } from "react";
import {
  useStore,
  dataSelector,
  setDataSelector,
  cameraSelector,
  leafletMapInstanceSelector,
  geolocationSelector,
  setValueSelector,
  mapTypeSelector,
} from "Store";
import { v4 as uuid } from "uuid";



import { client, UPDATE_MAP } from "Config/API/mutations";

/**
 * Hook d'accès au data de la Map, sa config et methodes d'update
 */
export const useMap = () => {
  const setStoreData = useStore(setDataSelector);
  // const allData = useStore(dataSelector);
  const camera = useStore(cameraSelector);
  const leafletInstance = useStore(leafletMapInstanceSelector);
  const geolocation = useStore(geolocationSelector);
  const setStoreValue = useStore(setValueSelector);
  const mapType = useStore(mapTypeSelector);
  const test = useRef(useStore.getState().data);
  const allData = test?.current;

  useEffect(() => {
    useStore.subscribe((s) => (test.current = s.data));
  }, []);
  const data = useMemo(
    () => allData?.filter((d) => d.type === mapType)?.[0],
    [allData, mapType]
  );
  const isLeaflet = useMemo(() => data?.type === "leaflet", [data?.type]);
  const configuration = useMemo(() => {
    const { polarAngles, enablePan, enableRotate, geolocation, clustering } =
      data?.configuration ?? {};
    return {
      ...data?.configuration,
      polarAngles: polarAngles ?? [0, 13],
      enableRotate: enableRotate ?? true,
      enablePan: enablePan ?? true,
      geolocation: geolocation ?? false,
      clustering: clustering ?? true,
    };
  }, [data?.configuration]);

  const setData = (newData) => {
    const newMaps = allData?.map((m) =>
      m.id === data?.id ? { ...m, ...newData } : m
    );
    setStoreValue("data", newMaps);
  };

  const patchConfig = (key, value) => {
    const newConfig = { ...data?.configuration, [key]: value };
    setData({ ...data, configuration: newConfig });
  };

  const setCamera = () => {
    patchConfig("camera", {
      ...data?.configuration?.camera,
      position: camera?.position,
      zoom: camera?.zoom,
    });
  };

  const patchGeoMarker = (key, value) => {
    const geoMarker = data?.configuration?.geoMarker ?? {};
    const newMarker = { ...geoMarker, [key]: value };
    patchConfig("geoMarker", newMarker);
  };

  const createMap = () => {
    const newMap = {
      id: uuid(),
      i18n: { title: "New Map" },
      type: mapType || "threejs",
      layers: [],
      configuration: {},
    };

    // setData([newMap]);
    setStoreValue("data", allData ? [...allData, newMap] : [newMap]);
  };

  const setGeolocation = (location) => {
    setStoreValue("geolocation", location);
  };

  const setGeoProtocolAvailability = (bool) => {
    setStoreValue("geoProtocolAvailability", bool);
  };

  return {
    data,
    setData,
    patchConfig,
    configuration,
    setCamera,
    patchGeoMarker,
    type: data?.type,
    createMap,
    leafletInstance,
    isLeaflet,
    geolocation,
    setGeolocation,
    setGeoProtocolAvailability,
  };
};

export const useMaps = () => {
  const allMaps = useStore(dataSelector);
  const setStoreValue = useStore(setValueSelector);

  const leafletMap = allMaps?.filter((m) => m.type === "leaflet")?.[0];
  const isLeafletMap = leafletMap?.layers?.length > 0;
  const threejsMap = allMaps?.filter((m) => m.type === "threejs")?.[0];
  const isThreejsMap = threejsMap?.layers?.length > 0;

  const setMapTitle = ({ mapId, title }) => {
    const newMaps = allMaps.map((m) => (m.id === mapId ? { ...m, title } : m));
    setStoreValue("data", newMaps);
    updateMap(mapId, newMaps?.filter((m) => m.id === mapId)[0]);
  };

  const patchTitle = ({ code, value, mapId }) => {
    if (!mapId || !code) return;
    const mapData = allMaps?.filter((m) => m.id === mapId)?.[0];

    if (!mapData) return;

    let newTitle = mapData?.title?.length ? [...mapData?.title] : [];
    if (!value) {
      newTitle = [...newTitle].filter((t) => t.language !== code);
    } else {
      const exists = newTitle.find((t) => t.language === code);

      const add = newTitle?.map((t) =>
        t.language === code
          ? { ...t, value: { text: value, html: `<p>${value}</p>` } }
          : t
      );
      exists
        ? (newTitle = add)
        : (newTitle = [
            ...newTitle,
            { language: code, value: { text: value, html: `<p>${value}</p>` } },
          ]);
    }
    setMapTitle({ mapId, title: newTitle });
  };

  const updateMap = async (mapId, data) => {
    if (!mapId) return;
    try {
      await client.mutate({
        mutation: UPDATE_MAP,
        variables: {
          mapId,
          ...data,
        },
      });
    } catch (error) {
      console.error(["updateMap_ERROR", error]);
    }
  };

  return {
    allMaps,
    isLeafletMap,
    isThreejsMap,
    leafletMap,
    threejsMap,
    patchTitle,
    updateMap,
  };
};
