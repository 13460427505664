import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";
import { animated } from "@react-spring/three";

const Sailor = ({ color, opacity, position, rotation, scale, ...props }) => {
  
  const group = useRef();
  const { nodes, materials } = useGLTF("/GLTF/sailorhello.gltf");

  return (
    <animated.group
      rotation={rotation ?? [Math.PI / 2, 0, 0]}
      position={position ?? [0,0,0]}
      scale={scale ?? [1, 1, 1]}
      ref={group}
      {...props}
      dispose={null}
    >
      <animated.mesh
        castShadow
        receiveShadow
        geometry={nodes.uploads_files_628825_Sailor_01001.geometry}
        material={materials["Default OBJ.001"]}
        rotation={[Math.PI / 2, 0, 0]}
      />
    </animated.group>
  );
};

useGLTF.preload("/GLTF/sailorhello.gltf");
export default Sailor;
