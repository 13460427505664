import chroma from "chroma-js";

export const setPalette = (colors) => {
  const primary = colors?.primaryColor ?? "#028090";
  const secondary = colors?.secondaryColor ?? "#E0C03D";
  return {
    primary: {
      light: chroma(primary).brighten().css(),
      main: primary,
      dark: chroma(primary).darken().css(),
    },
    secondary: secondary && {
      light: chroma(secondary).brighten().css(),
      main: secondary,
      dark: chroma(secondary).darken().css(),
    },
    ...colors,
  };
};
