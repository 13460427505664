import React from "react";
import Box from "@mui/material/Box";
import makeStyles from '@mui/styles/makeStyles';
import LayerButton from "Components/Threejs/Editor/LayersButtons/LayerButton";
import { useActualLayer, useLayers } from "Utils/hooks";


export default function LevelSelector({ configMod }) {
  const styles = useStyles();
  const {layers} = useLayers()
  const {actualLayer} = useActualLayer()

  return (
    <Box
      display="flex"
      className={configMod ? styles.config : styles.container}
    >
      {layers?.length > 1 && (
        <Box display="flex" flexDirection="column">
          <LayerButton type={"+"} configMod />
          <LayerButton type={"-"} configMod />
        </Box>
      )}
      {layers?.length > 1 ? (
        <h3 style={{ marginLeft: "8px" }}>
          {actualLayer?.name}
        </h3>
      ) : null}
    </Box>
  );
}

const useStyles = makeStyles((theme) => {
  return {
    container: {
      zIndex: 1,
      position: "fixed",
      top: "6rem",
      left: 0,
      margin: "2%",
    },
    config: {
      margin: 2,
    },
    button: {
      margin: 2,
    },
  };
});
