import React from "react";
import { useSpring, animated } from "@react-spring/three";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Position from "./LightPosition";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import { CircularKnob } from "Components//Global";
import { debounce } from "lodash";
import { useStore } from "Store";


const LightListItem = ({ index, light: l }) => {
  const tempData = useStore((s) => s.tempData);
  const setTempData = useStore((s) => s.setTempData);
  const data = tempData?.lights;

  const update = () => setTempData({ lights: data });
  const debUpdate = debounce(update, 30);
  const deleteLight = (index) => {
    data.splice(index, 1);
    update();
  };

  const handleIntensity = (value) => {
    data.map((light) => light.id === l.id && (light.intensity = value / 100));
    debUpdate();
  };

  const AnimBox = animated(Box);
  const animation = useSpring({
    from: {
      x: 100 * (index + 1),
      opacity: 0,
    },
    to: {
      x: 0,
      opacity: 1,
    },
    config: {
      mass: 1,
      tension: 200,
      friction: 20,
    },
  });

  const lightName = (name) =>
    name === "ambient"
      ? "Globale"
      : name === "directional"
      ? "Directionelle"
      : "Spot";

  return (
    <AnimBox
      style={animation}
      boxShadow={3}
      padding={2}
      key={index}
      mb={2}
      display="flex"
      flexDirection="column"
    >
      <Typography variant="body1" style={{ flex: 1 }}>
        {index + 1}. Lumière {lightName(l.type)}
      </Typography>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        width="100%"
      >
        <Box display="flex" alignItems="center" my={2}>
          <CircularKnob
            defaultValue={l.intensity * 100}
            unit="%"
            title="Intensité"
            onChange={handleIntensity}
          />
          {l.position && <Position index={index} />}
        </Box>

        <Box mx={2}>
          <IconButton onClick={() => deleteLight(index)} size="large">
            <DeleteIcon />
          </IconButton>
        </Box>
      </Box>
    </AnimBox>
  );
};

export default LightListItem;
