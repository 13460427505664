import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Routes,
  Navigate,
} from "react-router-dom";
import { ReactKeycloakProvider, useKeycloak } from "@react-keycloak/web";
import ThemeProvider from "Config/theme";
import Loader from "Components//Global/Loader";
import { PROJECT_ID } from "Config/constantes";
import { useStoreInitalization } from "Store";
import keycloak from "Config/auth";
import Editor from "Pages/Editor";
import App from "Pages/Indoor";
import Config from "Pages/Config";
import { StyledEngineProvider } from "@mui/material/styles";
import { useTranslation, withTranslation } from "react-i18next";
import Outdoor from "Pages/Outdoor";
import Home from "Pages/Admin";
import LeafletEditor from "Pages/LeafletEditor";
import LeafletConfigPage from "Pages/LeafletConfig";
import HomePage from "Pages/Home";
import ErrorPage from "Pages/Error";

const AppRouter = () => {
  // return loading ? (
  //   <Loader />
  // ) : (
  return (
    <Router>
      <AppRoutes />
    </Router>
  );
};
export default withTranslation()(AppRouter);

const AppRoutes = () => {
  const { loading } = useStoreInitalization();
  const isMemorial = window.__CONFIG__.projectId === "caenmemorial";

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider>
        <Routes>
          <Route
            exact
            path="/editor"
            element={
              <KeycloakRoute>
                <Editor />
              </KeycloakRoute>
            }
          />

          <Route
            exact
            path="/configuration"
            element={
              <KeycloakRoute>
                <Config />
              </KeycloakRoute>
            }
          />
          {!isMemorial && (
            <>
              <Route
                exact
                path="/leafletconfig"
                element={
                  <KeycloakRoute>
                    <LeafletConfigPage />
                  </KeycloakRoute>
                }
              />
              <Route
                exact
                path="/leafleteditor"
                element={
                  <KeycloakRoute>
                    <LeafletEditor />
                  </KeycloakRoute>
                }
              />
              <Route exact path="/outdoor" element={<Outdoor />} />
            </>
          )}
          <Route
            exact
            path="/admin"
            element={
              <KeycloakRoute>
                {isMemorial ? <Navigate to={"/editor"} /> : <Home />}
              </KeycloakRoute>
            }
          />
          <Route exact path="/indoor" element={<App />} />
          <Route path="/error" element={<ErrorPage />} />
          <Route
            path="/"
            element={
              isMemorial ? (
                <Navigate to="/indoor" />
              ) : (
                <HomePage loading={loading} />
              )
            }
          />
        </Routes>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

const KeycloakRoute = ({ children }) => {
  const { t } = useTranslation();
  return (
    <ReactKeycloakProvider
      authClient={keycloak}
      LoadingComponent={<Loader msg={t("auth")} />}
    >
      <PrivateRoute>{children}</PrivateRoute>
    </ReactKeycloakProvider>
  );
};

const PrivateRoute = ({ children }) => {
  const { keycloak: kc, initialized } = useKeycloak();

  const isAuth = () => {
    if (!kc.authenticated) {
      return false;
    } else {
      const authorizedGroups = [
        "/superAdmin",
        `/${PROJECT_ID}/owner`,
        `/${PROJECT_ID}/admin`,
        `/${PROJECT_ID}/editor`,
      ];
      const userGroups = kc?.idTokenParsed?.wivi ?? [];
      const isAuthorized = authorizedGroups.some((ag) =>
        userGroups.includes(ag)
      );
      return isAuthorized;
    }
  };

  return initialized ? isAuth() ? children : <Navigate to={"/"} /> : <Loader />;
};
