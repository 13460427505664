// Original: https://github.com/chenglou/react-motion/tree/master/demos/demo8-draggable-list

import {
  Box,
  Button,
  IconButton,
  MenuItem,
  Stack,
  TextField,
  Typography,
  Select,
  Tooltip,
} from "@mui/material";
import swap from "lodash-move";
import clamp from "lodash/clamp";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { a, animated, useSprings } from "@react-spring/web";
import { useDrag } from "react-use-gesture";
import { useActualLayer, useLayers, useMap } from "Utils/hooks";
import "./styles.css";
import {
  CropFree,
  Delete,
  DragHandle,
  Edit,
  Save,
  Visibility,
} from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import tiles from "Config/leafletTilesServers.json";
import TitleInput from "Components/Global/TitleInput";

const AnimBox = animated(Box);
const size = 100;
// Returns fitting styles for dragged/idle layers
const fn = (order, active, originalIndex, curIndex, y) => (index) =>
  active && index === originalIndex
    ? {
        y: curIndex * size + y,
        scale: 1.1,
        zIndex: "3",
        shadow: 15,
        immediate: (n) => n === "y" || n === "zIndex",
      }
    : {
        y: order.indexOf(index) * size,
        scale: 1,
        zIndex: "0",
        shadow: 1,
        immediate: false,
      };

const LayerList = () => {
  const { t } = useTranslation();
  const { layers, setLayers } = useLayers();

  const [stopped, setStopPropagation] = useState(false);
  const order = useRef(layers?.map((_, index) => _?.data?.index ?? index)); // Store indicies as a local ref, this represents the item order
  const [springs, setSprings] = useSprings(layers?.length, fn(order.current), [
    layers,
    order,
  ]); // Create springs, each corresponds to an item, controlling its transform, scale, etc.

  useEffect(() => {
    order.current = layers?.map((_, index) => _?.data?.index ?? index);
  }, [layers]);

  const bind = useDrag(
    ({ args: [originalIndex], active, movement: [, y] }) => {
      const curIndex = order.current.indexOf(originalIndex);
      const curRow = clamp(
        Math.round((curIndex * size + y) / 100),
        0,
        layers?.length - 1
      );
      const newOrder = swap(order.current, curIndex, curRow);
      setSprings(fn(newOrder, active, originalIndex, curIndex, y)); // Feed springs new style data, they'll animate the view without causing a single render

      if (!active) {
        let newLayers = [...layers];
        newOrder.map((_, index) => {
          const layer = layers?.find((l) => l?.id === layers[_].id);

          const newData = [...newLayers].map((l) => {
            return l.id === layer.id ? { ...l, data: { ...l.data, index } } : l;
          });
          newLayers = newData;
        });
        setLayers(newLayers);
      }
    },
    {
      enabled: !stopped,
    }
  );

  return (
    <Box
      height={layers?.length * size}
      position="relative"
      width="100%"
      maxWidth={'calc("100% - 32px")'}
      zIndex={1}
    >
      {springs.map(({ zIndex, shadow, y, scale }, i) => (
        <AnimBox
          position="absolute"
          display="flex"
          alignItems="center"
          flexWrap="wrap"
          maxWidth="100%"
          width="100%"
          {...bind(i)}
          key={layers[i].id}
          style={{
            zIndex,
            boxShadow: shadow.to(
              (s) => `rgba(0, 0, 0, 0.15) 0px ${s}px ${2 * s}px 0px`
            ),
            y,
            scale,
            height: size * 0.8,
            backgroundColor: "white",
          }}
        >
          <LayerRow
            key={layers[i].id}
            layer={layers[i]}
            stopped={stopped}
            setStopPropagation={setStopPropagation}
          />
        </AnimBox>
      ))}
    </Box>
  );
};

export default LayerList;

const LayerRow = ({ layer, setStopPropagation }) => {
  const { set, actualLayer } = useActualLayer();
  const { removeLayer, setName, updateLayer, setLeafletTilesStyle, patchTitle } = useLayers(
    layer.id
  );
  const isActual = useMemo(
    () => layer.id === actualLayer?.id,
    [actualLayer?.id]
  );

  const [isEditing, setEditing] = useState(false);
  const [name, changeName] = useState(layer.name);
  const { leafletInstance } = useMap();

  const { t, i18n } = useTranslation();
  const saveName = (e) => {
    e.stopPropagation();
    isEditing && setName(name);
    setEditing((pv) => !pv);
  };

  //met à jour la vue par défaut du layer leaflet
  const handleZoom = () => {
    if (!leafletInstance) return;
    const { lat, lng } = leafletInstance?.getCenter() ?? { lat: 0, lng: 0 };
    const position = { x: lng, y: lat, z: 0, unit: "geographic" };
    const zoom = leafletInstance?.getZoom();
    const newLayer = { ...layer, position, data: { ...layer.data, zoom } };
    updateLayer(newLayer);
    set(layer.id);
  };

  return (
    <Stack
      spacing={3}
      direction="row"
      alignItems="center"
      justifyContent={"space-between"}
      width="100%"
      px={2}
    >
      <Stack spacing={1} direction="row" alignItems="center">
        <DragHandle color="primary" />
        <Tooltip title={t("/configuration.layers.preview")}>
          <IconButton
            onClick={() => set(layer.id)}
            onMouseEnter={() => setStopPropagation(true)}
            onMouseLeave={() => setStopPropagation(false)}
            color={isActual ? "primary" : "default"}
          >
            <Visibility />
          </IconButton>
        </Tooltip>
        <Box
          sx={{
            display: "-webkit-box",
            WebkitLineClamp: 3,
            WebkitBoxOrient: "vertical",
            overflow: "hidden",
          }}
        >
          {isEditing ? (
             <TitleInput
             onMouseEnter={() => setStopPropagation(true)}
             onMouseLeave={() => setStopPropagation(false)}
             placeholder={t("/configuration.layers.level_name")}
             patchFunction={patchTitle}
             values={layer?.title}
             width="100%"
           />
          ) : (
            <Typography
            color={isActual ? "primary" : "default"}
            fontWeight={isActual && "bold"}
            width="100%"
            mx={2}
            variant="body1"
          >
            {layer?.title?.filter?.(
              (e) => e.language === i18n.resolvedLanguage
            )[0]?.value.text ?? layer?.data?.index}
          </Typography>
          )}
        </Box>
      </Stack>
      <Stack
        spacing={1}
        direction="row"
        alignItems="center"
        onMouseEnter={() => setStopPropagation(true)}
        onMouseLeave={() => setStopPropagation(false)}
      >
        <Select
          size="small"
          value={layer?.data?.tilesStyle ?? "light"}
          onChange={(e) => setLeafletTilesStyle(e.target.value)}
        >
          {Object.keys(tiles).map((style, index) => (
            <MenuItem key={index} value={style}>
              {t(`/leafletconfig.layers.tiles_${style}`)}
            </MenuItem>
          ))}
        </Select>
        <Tooltip title={t("/leafletconfig.layers.set_zoom_tooltip")}>
          <IconButton variant="contained" onClick={handleZoom}>
            <CropFree />
          </IconButton>
        </Tooltip>
        <Tooltip title={t("/configuration.layers.edit_name")}>
          <IconButton
            onMouseEnter={() => setStopPropagation(true)}
            onMouseLeave={() => setStopPropagation(false)}
            onClick={(e) => (isEditing ? saveName(e) : setEditing(true))}
          >
            {isEditing ? <Save /> : <Edit />}
          </IconButton>
        </Tooltip>
        <Tooltip title={t("/configuration.layers.delete")}>
          <IconButton
            onMouseEnter={() => setStopPropagation(true)}
            onMouseLeave={() => setStopPropagation(false)}
            onClick={() => removeLayer(layer.id)}
          >
            <Delete />
          </IconButton>
        </Tooltip>
      </Stack>
    </Stack>
  );
};
