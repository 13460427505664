import React, { Suspense, useEffect } from "react";
import MemoLayer from "./Layer2D";
import { useLayers, useMap } from "Utils/hooks";
import { Environment, Lightformer } from "@react-three/drei";
import { useThree } from "@react-three/fiber";

const AllLayers = ({ configMod, ...props }) => {
  const { layers } = useLayers();
  // const {gl} = useThree()
  // const {configuration} = useMap()


  return layers?.map((layer, index) => {
    return layer?.image ? (
      <MemoLayer key={layer.id} data={layer} configMod {...props} />
    ) : null;
  });
};

export default AllLayers;

