import {
  Box,
  Switch,
  Typography,
  Slider,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { Stage } from "@react-three/drei";
import { Canvas } from "@react-three/fiber";
import Arrow from "Components/Threejs/3DComponents/Placeholders/3D/models/Arrow";
import Sailor from "Components/Threejs/3DComponents/Placeholders/3D/models/Sailor";
import React, { useState, useEffect, Suspense } from "react";
import { useMap } from "Utils/hooks";
import BackgroundColorControls from "./BackgroundColor";
import { useTranslation } from "react-i18next";

const { projectId } = window.__CONFIG__ ?? {};
const withGeoLocation = ["tatihou", "chfb", "dev"].includes(projectId);

const GlobalSettingsControls = () => {
  const { t } = useTranslation();
  return (
    <Box>
      <Box mb={2}>
        <Typography variant="h6" color="primary">
          {t("/configuration.settings.navigation")}
        </Typography>
        <Typography variant="body1">
          {t("/configuration.settings.navigation_instruction")} :
        </Typography>
        <Selector title={t('/configuration.settings.map_rotation')} value="enableRotate" />
        <AngleSlider />
        <Selector title={t('/configuration.settings.map_panning')} value="enablePan" />
        {withGeoLocation && (
          <>
            <Selector
              title="Géolocalisation de l'utilisateur"
              value="geolocation"
            />
            <GeoMarkerConfig />
          </>
        )}
      </Box>
      {/* <CameraControls /> // TOFIX : CameraControls is not working */}
      <BackgroundColorControls />
    </Box>
  );
};

export default GlobalSettingsControls;

const Selector = ({ value, title }) => {
  const { configuration, patchConfig } = useMap();
  const [checked, setChecked] = useState(configuration?.[value]);
  const handleChange = (a, v) => {
    patchConfig(value, v);
    setChecked(v);
  };
  return (
    <Box display="flex" alignItems="center" width="100%">
      <Switch onChange={handleChange} checked={checked} color="primary" />
      <Typography>{title}</Typography>
    </Box>
  );
};

const AngleSlider = () => {
  const { configuration, patchConfig } = useMap();
  const [value, setValue] = useState(configuration?.polarAngles ?? [0, 60]);
  const {t} = useTranslation()
  const handleChange = (v) => {
    setValue(v);
    patchConfig("polarAngles", v);
  };

  return (
    <Box mx={2} display="flex" alignItems="center" width="100%">
      <Typography>{t('/configuration.settings.angle_limitation')}</Typography>
      <Slider
        min={0}
        max={90}
        valueLabelFormat={(v) => `${v}°`}
        valueLabelDisplay="auto"
        display="flex"
        value={value}
        onChange={(e, v) => setValue(v)}
        onChangeCommitted={(e, v) => handleChange(v)}
        style={{ width: "50%", maxWidth: 200, marginLeft: 24 }}
        marks={[
          { label: "min", value: 0 },
          { label: "max", value: 90 },
        ]}
      />
    </Box>
  );
};

const GeoMarkerConfig = () => {
  const { configuration, patchGeoMarker } = useMap();
  const { model, scale } = configuration?.geoMarker ?? {};
  const [slider, setSlider] = useState(scale ?? 1);
  const {t} = useTranslation()

  useEffect(() => {
    setSlider(scale);
  }, [scale]);

  return (
    <Box display="flex" alignItems="center" m={2}>
      <Box width="20%" height={150}>
        <Canvas mode="concurrent" camera={{ zoom: 1.5 }}>
          <Suspense fallback={null}>
            <Stage
              contactShadow
              shadows
              adjustCamera
              intensity={0.4}
              environment="city"
              preset="rembrandt"
              // controls={controlsRef}
            >
              {/* {model === "druid" ? (
                <Druid key={model} 
                rotation={[2.5, 0, 0]} 
                />
              ) :  */}
              {model === "sailor" ? (
                <Sailor rotation={[0, 0, 0]} />
              ) : (
                <Arrow rotation={[Math.PI / 2, 0, 0]} />
              )}
            </Stage>
          </Suspense>
        </Canvas>
      </Box>
      <Box mx={3}>
        <InputLabel shrink>{t('/configuration.settings.geomarker_shape')}</InputLabel>
        <Select
          value={model ?? "arrow"}
          onChange={(e) => patchGeoMarker("model", e.target.value)}
        >
          <MenuItem value="arrow">{t('/configuration.settings.arrow')}</MenuItem>
          {/* <MenuItem value="druid">Druide</MenuItem> */}
          <MenuItem value="sailor">{t('/configuration.settings.sailor')}</MenuItem>
        </Select>
        <Box my={2}>
          <InputLabel shrink>{t('/configuration.settings.geomarker_size')}</InputLabel>
          <Slider
            value={slider}
            onChange={(e, v) => setSlider(v)}
            min={0.1}
            max={3}
            step={0.1}
            valueLabelDisplay="auto"
            onChangeCommitted={(e, v) => patchGeoMarker("scale", v)}
          />
        </Box>
      </Box>
    </Box>
  );
};
