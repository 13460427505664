import { Box } from "@mui/material";
import React, { Suspense, useEffect } from "react";

import "leaflet/dist/leaflet.css";

import BottomBar from "Components/Global/BottomBar";
import { useSpring } from "@react-spring/web";
import { useLayers } from "Utils/hooks";
import LeafletMap from "Components/Leaflet/Map";
import ContentTopBar from "Components/Global/ContentTopBar";
import customProtocolCheck from "custom-protocol-check";
import { Loader } from "Components/Global";
import { useQueryParam } from "Utils/hooks/queries";

// Globals.assign({
//   frameLoop: "always",
// });

const OutdoorPage = () => {
  const { layers } = useLayers();
  useQueryParam();
  return (
    <Suspense fallback={<Loader/>}>
      <Box height="100%" width={"100%"} zIndex={1}>
        {layers?.length ? (
          <>
            <ContentTopBar />
            <LeafletMap />
          </>
        ) : null}
      </Box>
      <BottomBar />
    </Suspense>
  );
};

export default OutdoorPage;
