import { Check, Close, Layers } from "@mui/icons-material";
import {
  Backdrop,
  Box,
  Divider,
  Fab,
  List,
  ListItem,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import { animated, useSpring } from "@react-spring/web";
import { reverse } from "lodash";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useActualLayer, useLayers } from "Utils/hooks";
const AnimBox = animated(Box);

const LayersChangeButton = () => {
  const { actualLayer, set } = useActualLayer();
  const { layers } = useLayers();
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  const anim = useSpring({
    from: {
      height: 0,
      width: "0vw",
    },
    height: open ? "auto" : 0,
    width: open ? "70vw" : "0vw",
    config: { mass: 0.4, tension: 230, friction: 15 },
  });
  const handleClick = (id) => {
    set(id);
    setOpen(false);
  };
  return (
    <Box position="relative">
      <Backdrop open={open} />
      <AnimBox
        style={anim}
        overflow="hidden"
        width="50vw"
        maxWidth={500}
        maxHeight={"60vh"}
        bottom={0}
        borderRadius={1}
        boxShadow={3}
        zIndex={17}
        bgcolor="white"
        position="absolute"
        sx={{
          transform: "translate(-90%, -16px)",
          transformOrigin: "bottom right",
        }}
      >
        <List>
          {reverse(layers).map((layer, index) => (
            <Box key={layer.id} px={1} mb={2}>
              <Stack direction="row" alignItems="center">
                <MenuItem
                  sx={{ width: "100%" }}
                  onClick={() => handleClick(layer.id)}
                >
                  {actualLayer?.id === layer.id && (
                    <Check color="primary" sx={{ mr: 1 }} />
                  )}
                  <Typography
                    textOverflow={"ellipsis"}
                    overflow="hidden"
                    whiteSpace="nowrap"
                    variant="body1"
                  >
                    {" "}
                    {layer?.i18n?.title?.value?.text ??
                      layer?.title?.[0]?.value?.text ??
                      `${t("/visit.layer")} ${layers.length - (index + 1)}`}
                  </Typography>
                </MenuItem>
              </Stack>
              <Divider light variant="middle" />
            </Box>
          ))}
        </List>
      </AnimBox>
      <Fab
        size="small"
        color="primary"
        aria-label="change level"
        onClick={() => setOpen((o) => !o)}
      >
        {open ? <Close /> : <Layers />}
        {/* Change level */}
      </Fab>
    </Box>
  );
};

export default LayersChangeButton;
