/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef, useMemo } from 'react'
import { meshBounds, useGLTF } from '@react-three/drei'
import { animated } from "@react-spring/three";
import { MeshStandardMaterial } from "three";

const Tree = ({color, rotation, opacity, scale, raycast, ...props}) => {
  const group = useRef()
  const { nodes, materials } = useGLTF(
    '/GLTF/tree.gltf'
  )
  const material = useMemo(
    () =>
      new MeshStandardMaterial({
        color: color ?? "#57813f",
        roughness: 0.2,
        emissive: "#000",
        emissiveIntensity: .5,
        transparent: true,
        opacity: opacity ?? 1
      }),
    [color, opacity]
  );
  return (
    <animated.group ref={group} scale={scale ? scale.map(e => e*4) : [4,4,4]}  rotation={[ 0,rotation ?? 0,0]} {...props} dispose={null}>
      <animated.mesh raycast={meshBounds} geometry={nodes.Mesh_tree_large.geometry} material={material} />
      <animated.mesh raycast={meshBounds} geometry={nodes.Mesh_tree_large_1.geometry} material={materials['wood.001']} />
    </animated.group>
  )
}

useGLTF.preload(
    '/GLTF/tree.gltf'
)

export default Tree