export const stringToSeconds = (value) => {
    const [h, m] = value.split(":");
    const secs = parseFloat(h) * 3600 + parseFloat(m) * 60;
    return secs
}

export const secsToString = (value) => {
    const test = value ? new Date(value * 1000).toISOString().substr(11, 5) : null
    const [h,m] = test?.split(":") ?? []
    return {h, m}
}