import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Polyline, Popup, useMap as useLeafletMap } from "react-leaflet";
import ReactDOMServer from "react-dom/server";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import DraggableMarker from "Components/Leaflet/Markers/Draggable";
import {
  useActualLayer,
  useActualPlaceholder,
  useDisplayedPlaceholders,
} from "Utils/hooks";
import axios from "axios";
import polyline from "@mapbox/polyline";
import MarkerClusterGroup from "react-leaflet-cluster";
import { latLngBounds } from "leaflet";
import { isEqual } from "lodash";
import usePrevious from "Utils/hooks/previous";
import { useMap } from "Utils/hooks/map";
import { Duration } from "luxon";
import StandardLeafletMarker from "../Markers/Standard";
import LeafletClusterMarker from "../Markers/LeafletClusterMarker";
const defaultPosition = {
  y: 49.18619985426114,
  x: -0.3655160218477249,
};

const LeafletPlaceholders = ({ editMode }) => {
  // const { placeholders } = usePlaceholders();
  const { placeholders, filters, showPathway, pathwayColor } =
    useDisplayedPlaceholders();
  const { data: actualPlaceholder } = useActualPlaceholder();
  const lineRef = useRef();
  const map = useLeafletMap();
  const [lines, setLines] = useState(null);

  const { actualLayer } = useActualLayer();
  const { configuration } = useMap();
  const position = useMemo(() => {
    const { x, y } = actualLayer?.position ?? defaultPosition;

    return [y, x];
  }, [actualLayer?.position]);

  //AJUSTE la taille de la map en fonction des positions au changement d'affichage parent/enfant
  const adjustBounds = useCallback(() => {
    if (!placeholders.length) return;
    let bounds = latLngBounds([
      placeholders?.[0]?.position.y ?? defaultPosition.y,
      placeholders?.[0]?.position?.x ?? defaultPosition.x,
    ]);
    placeholders.map((placeholder) => {
      const { x, y } = placeholder?.position;
      bounds.extend({ lat: y, lng: x });
    });
    map.fitBounds(bounds, {
      animate: true,
      duration: 1,
      padding: [100, 100],
    });
  }, [placeholders]);

  const prevPlaceholders = usePrevious(placeholders.map((p) => p.id));
  useEffect(() => {
    if (
      !isEqual(
        prevPlaceholders,
        placeholders.map((p) => p.id)
      ) &&
      placeholders.length
    ) {
      adjustBounds();
    }
  }, [placeholders, prevPlaceholders, filters]);

  //zoom sur la position du layer choisie
  useEffect(() => {
    map.flyTo(position, actualLayer?.data?.zoom ?? 12, {
      animate: true,
      duration: 1,
    });
  }, [position, actualLayer?.data?.zoom]);

  // zoom sur un placeholder actif
  useEffect(() => {
    if (actualPlaceholder) {
      const { position, name } = actualPlaceholder ?? {};

      const { x, y } = position ?? defaultPosition;
      map.flyTo([y, x], 17, {
        animate: true,
        duration: 1,
        padding: [50, 50],
      });
    }
  }, [actualPlaceholder?.id]);

  useEffect(() => {
    setLines(null);

    if (!showPathway) return;

    if (filters?.placeholderId) {
      if (placeholders?.length < 2) return;
      const positions = placeholders.reduce((acc, placeholder, idx) => {
        acc += `${placeholder.position.x},${placeholder.position.y}${
          idx < placeholders.length - 1 ? ";" : ""
        }`;
        return acc;
      }, "");
      req(positions);
    }
  }, [filters, placeholders, showPathway]);

  const path = ({ service, profile, coords }) =>
    `http://router.project-osrm.org/${service}/v1/${profile}/${coords}/`;

  const req = async (points) => {
    const { data } = await axios.get(
      `https://router.project-osrm.org/route/v1/driving/${points}?overview=full`
    );

    const test = polyline.decode(data.routes[0].geometry);
    const newLine = {
      geometry: test,
      duration: data.routes[0].duration,
      distance: data.routes[0].distance,
    };
    setLines(newLine);
    lineRef.current?.redraw();
  };

  useEffect(() => {
    lineRef?.current?.openPopup?.();
  }, [lineRef?.current]);

  return (
    <>
      {lines && filters && placeholders?.length >= 2 && (
        <Polyline
          ref={lineRef}
          pathOptions={{
            color: pathwayColor,
            weight: 5,
            dashArray: [12, 10],
            dashOffset: 10,
            opacity: 0.8,
          }}
          positions={lines.geometry}
        >
          <Popup>
            <p>
              <b>Distance:</b> {(lines.distance / 1000).toFixed(2)} km
            </p>
            <p>
              <b>Duration:</b>{" "}
              {Duration.fromMillis(lines.duration * 1000).toFormat("hh:mm:ss")}
            </p>
          </Popup>
        </Polyline>
      )}
      {configuration?.clustering && !editMode ? (
        <Cluster editMode={editMode} />
      ) : (
        <RenderedPlaceholders editMode={editMode} />
      )}
    </>
  );
};

const RenderedPlaceholders = ({ editMode }) => {
  const { placeholders } = useDisplayedPlaceholders();
  return placeholders.map((marker, index) =>
    editMode ? (
      <DraggableMarker key={index} {...marker} />
    ) : (
      <StandardLeafletMarker key={index} {...marker} />
    )
  );
};

export default LeafletPlaceholders;

const Cluster = ({ editMode }) => {
  const createClusterCustomIcon = function (cluster) {
    return L.divIcon({
      className: "custom icon",
      html: ReactDOMServer.renderToString(
        <LeafletClusterMarker cluster={cluster} />
      ),
    });
  };
  return (
    <MarkerClusterGroup
      iconCreateFunction={createClusterCustomIcon}
      spiderfyOnMaxZoom
      disableClusteringAtZoom={16}
    >
      <RenderedPlaceholders editMode={editMode} />
    </MarkerClusterGroup>
  );
};
