import React from "react";
import Marker3D from "./3D/EditorMarker";
import Marker2D from "./HTML/EditorMarker";

const EditorMarker = ({ data, ...props }) => {
  const {x,y,z} = data?.position ?? {x:0, y:0, z:0} 
  return data?.marker?.type === "2D" ? (
    <>
    <Marker2D data={data} {...props} />
    </>
  ) : (
    <>
    <Marker3D data={data} {...props} />
    </>
  );
};
export default EditorMarker;
