import React from "react";
import AddIcon from "@mui/icons-material/Add";
import Fab from "@mui/material/Fab";
import { v4 as uuidv4 } from "uuid";
import { client, UPLOAD_IMAGE } from "Config/API/mutations";
import { useActualLayer, useLayers } from "Utils/hooks";
import { useTranslation } from "react-i18next";
import { useQuery } from "@apollo/client";
import { GET_LANGUAGES } from "Config/API/queries";

export const UploadButton = () => {
  const { layers, setLayers } = useLayers();
  const { set } = useActualLayer();
  const { t, i18n } = useTranslation();
  const { data } = useQuery(GET_LANGUAGES);
  const appLanguage = i18n?.resolvedLanguage;
  const defaultIso = Object.keys(data?.languages ?? {})?.includes(appLanguage)
    ? appLanguage
    : data?.languages?.[0]?.code ?? "fr";
  const position = layers?.length ?? 0;
  const defaultName = `${t("/configuration.layers.my_level")} ${position}`;

  const addLayer = ({ url, width, height }, id) => {
    let layerId = uuidv4();
    const newLayer = {
      image: {
        file: {
          url: url,
          id: id,
          width,
          height,
        },
      },

      scale: 0.03,
      rotation: 0,
      position: { x: 0, y: 0, z: position },
      title: [
        {
          language: defaultIso,
          value: { text: defaultName, html: `<p>${defaultName}</p>` },
        },
      ],
      name: defaultName,
      placeholders: [],
      id: layerId,
    };
    setLayers(layers ? [...layers, newLayer] : [newLayer]);
    set(layerId);
  };

  const uploadFile = async (e) => {
    try {
      const file = e.target.files[0];
      const { data } = await client.mutate({
        mutation: UPLOAD_IMAGE,
        variables: { file },
      });

      console.log('data:', data)

      data &&
        addLayer(
          data.mapImage.formats[data.mapImage.formats.length - 1].file,
          data.mapImage.id
        );
    } catch (error) {
      console.log("error:", error);
    }
  };

  return (
    <>
      <input
        accept="image/*"
        style={{ display: "none" }}
        id="raised-button-file"
        type="file"
        onChange={uploadFile}
      />
      <label htmlFor="raised-button-file">
        <Fab color="primary" variant="extended" component="span">
          <AddIcon />
          {t("/configuration.layers.add_level")}
        </Fab>
      </label>
    </>
  );
};

export default UploadButton;
