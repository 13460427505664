import {
  Box,
  Icon,
  Select,
  TextField,
  Typography,
  MenuItem,
  InputLabel,
  Slider,
  Stack,
  Switch,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import {
  useActualPlaceholder,
  usePlaceholders,
  useDisplayedPlaceholders,
  useMap,
} from "Utils/hooks";
import { ColorPicker } from "mui-color";
import { debounce } from "lodash";
import markerIcons from "./icons.json";
import { secsToString, stringToSeconds } from "Utils/durations";
import { useTranslation } from "react-i18next";
import TitleInput from "../TitleInput";
import useWiviContent from "Utils/hooks/wiviContent";

const MarkerConfig = ({ data }) => {
  const {
    setColor,
    setName,
    setType,
    setMarkerIcon,
    setScale,
    setRotation,
    setModel,
    setMarkerIconColor,
    setDuration,
    patchData,
    patchTitle,
  } = useActualPlaceholder(data?.parentId);

  const { placeholders, changeParent } = usePlaceholders();
  const { clearFilters } = useDisplayedPlaceholders();
  const { isLeaflet } = useMap();
  const { t } = useTranslation();

  const debounceColor = debounce((e) => setColor(e), 300);
  const debounceIconColor = debounce((e) => setMarkerIconColor(e, 300));
  const debouncePathwayColor = debounce((e) => handlePathway("color", e), 300);

  const [duration, setDurationInput] = useState("");
  const [color, setColorPicker] = useState(data?.marker?.color);
  const [iconColor, setIconColorPicker] = useState(data?.marker?.iconColor);
  const [pathwayColor, setPathwayColorPicker] = useState(
    data?.data?.pathway?.color
  );
  const [scale, setScaleSlider] = useState(data?.marker?.scale ?? 1);
  const [rotation, setRotationSlider] = useState(data?.marker?.rotation ?? 0);

  const markersModels = ["pin", "basic", "flag", "tree"];

  //met à jour les parentId et tableaux de children
  const handleParentChange = (e) => {
    const parentId = e.target.value;
    if (data?.parentId) {
      changeParent(
        data?.id,
        data?.parentId,
        parentId === "none" ? null : parentId
      );
    } else {
      changeParent(data?.id, null, parentId);
    }
    clearFilters();
  };

  //set les valeurs par défaut
  useEffect(() => {
    const { h, m } = secsToString(data?.duration);
    setColorPicker(data?.marker?.color ?? window.__CONFIG__.theme.primaryColor);
    setScaleSlider(data?.marker?.scale ?? 1);
    setRotationSlider(data?.marker?.rotation ?? 0);
    setIconColorPicker(data?.marker?.iconColor ?? "black");
    setDurationInput(`${h}:${m}`);
  }, [data?.id]);

  //set la liste des icons 2D
  const { projectId } = window?.__CONFIG__;
  const icons = useMemo(
    () => [...markerIcons.base, ...(markerIcons?.[projectId] ?? [])],
    [projectId]
  );

  const handleDuration = (e) => {
    const dur = e.target.value;
    setDurationInput(dur);
    if (!dur) {
      setDuration(null);
    } else {
      const secs = stringToSeconds(dur);
      setDuration(secs);
    }
  };

  const handlePathway = (key, val) => {
    const pathwayData = { ...(data?.data?.pathway ?? {}), [key]: val };
    patchData("pathway", pathwayData);
  };

  return (
    <>
      <Box my={2} id="name">
        <InputLabel shrink>{t("/editor.gui.marker_name")}</InputLabel>
        <TitleInput
          values={data?.title}
          markerData={data}
          patchFunction={patchTitle}
        />
      </Box>
      {data?.children?.length > 0 ? (
        isLeaflet ? (
          <>
            <Box my={2} id="itinéraire">
              <InputLabel shrink>{t("/leafleteditor.gui.pathway")}</InputLabel>
              <Stack spacing={2} direction="row" alignItems={"center"}>
                <Switch
                  color="primary"
                  checked={data?.data?.pathway?.active ?? false}
                  onChange={(e) => {
                    handlePathway("active", e.target.checked);
                  }}
                />
                <Typography variant="button">
                  {data?.data?.pathway?.active ? t("ON") : t("OFF")}
                </Typography>
              </Stack>
            </Box>
            <Box my={2} id="itinéraire">
              <InputLabel shrink>
                {t("/leafleteditor.gui.pathwayColor")}
              </InputLabel>
              <Stack spacing={2} direction="row" alignItems={"center"}>
                <ColorPicker
                  value={pathwayColor}
                  onChange={(e) => {
                    setPathwayColorPicker(`#${e?.hex}`);
                    !e.error && debouncePathwayColor(e.css.backgroundColor);
                  }}
                  hideTextfield
                  disableAlpha={data?.marker?.type === "3D"}
                />
              </Stack>
            </Box>
          </>
        ) : (
          <Box my={2} id="timing">
            <InputLabel shrink>{t("/editor.gui.duration")}</InputLabel>
            <TextField value={duration} type="time" onChange={handleDuration} />
          </Box>
        )
      ) : (
        <Box my={1} id="parent">
          <InputLabel shrink>{t("/editor.gui.parent")}</InputLabel>
          <Select
            size="small"
            sx={{ maxWidth: 300 }}
            value={data?.parentId ?? "none"}
            onChange={handleParentChange}
            renderValue={(value) =>
              value === "none" ? (
                <MenuItem value="none">{t("/editor.gui.none")}</MenuItem>
              ) : (
                <ParentMenuItem p={data} value={value} />
              )
            }
          >
            <MenuItem value="none">{t("/editor.gui.none")}</MenuItem>
            {placeholders
              ?.filter((p) => !p?.parentId && p?.id !== data?.id)
              .map((p) => (
                <ParentMenuItem key={p.id} value={p.id} p={p} />
              ))}
          </Select>
        </Box>
      )}
      {!isLeaflet && (
        <Box>
          <InputLabel shrink>{t("/editor.gui.marker_type")}</InputLabel>
          <Select
          
            disabled={isLeaflet}
            value={isLeaflet ? "2D" : data?.marker?.type ?? "3D"}
            onChange={(e) => setType(e.target.value)}
          >
            <MenuItem value={"2D"}>2D</MenuItem>
            {!isLeaflet && <MenuItem value={"3D"}>3D</MenuItem>}
          </Select>
        </Box>
      )}
      <Box my={2} id="color">
        <InputLabel shrink>{t("/editor.gui.color")}</InputLabel>
        <ColorPicker
          value={color}
          onChange={(e) => {
            setColorPicker(`#${e?.hex}`);
            !e.error && debounceColor(e.css.backgroundColor);
          }}
          hideTextfield
          disableAlpha={data?.marker?.type === "3D"}
        />
      </Box>
      {data?.marker?.type === "2D" || isLeaflet ? (
        <>
          <Box id="icon" mb={2}>
            <InputLabel shrink>{t("/editor.gui.marker_icon")}</InputLabel>
            <Box display="flex" alignItems="center">
              <ColorPicker
                value={iconColor}
                onChange={(e) => {
                  setIconColorPicker(`#${e?.hex}`);
                  !e.error && debounceIconColor(`#${e?.hex}`);
                }}
                hideTextfield
              />
              <Select
                value={data?.marker?.icon?.value ?? "home"}
                onChange={(e) =>
                  setMarkerIcon(
                    icons.filter((i) => i.value === e.target.value)[0]
                  )
                }
              >
                {icons.map((icon, i) => (
                  <MenuItem key={i} value={icon?.value}>
                    <Box display="flex" alignItems="center">
                      {icon?.type === "svg" ? (
                        <Box
                          sx={{
                            backgroundColor: iconColor,
                            mask: `url(/icons/${icon?.file}) no-repeat center`,
                          }}
                        >
                          <img
                            width={35}
                            height={35}
                            src={`/icons/${icon?.file}`}
                            alt={icon?.name}
                            draggable={false}
                            style={{ opacity: 0 }}
                          />
                        </Box>
                      ) : icon?.type ? (
                        <img
                          width={35}
                          height={35}
                          src={`/icons/${icon?.file}`}
                          alt={icon?.name}
                          draggable={false}
                        />
                      ) : (
                        <Icon style={{ marginRight: 8, color: iconColor }}>
                          {icon.value}
                        </Icon>
                      )}
                      <Typography>{icon.name}</Typography>
                    </Box>
                  </MenuItem>
                ))}
              </Select>
            </Box>
          </Box>
        </>
      ) : (
        <Box id="model" mb={2}>
          <InputLabel shrink>{t("/editor.gui.marker_shape")}</InputLabel>
          <Select
            value={data?.marker?.model ?? "pin"}
            onChange={(e) => setModel(e.target.value)}
          >
            {markersModels.map((model, i) => (
              <MenuItem key={i} value={model}>
                {t(`/editor.gui.model_${model}`)}
              </MenuItem>
            ))}
          </Select>
        </Box>
      )}
      <Box id="scale" px={1}>
        <InputLabel shrink>{t("/editor.gui.scale")}</InputLabel>

        <Slider
          min={0.1}
          max={2}
          step={0.1}
          value={scale}
          marks
          onChangeCommitted={(e, v) => setScale(v)}
          onChange={(e, v) => setScaleSlider(v)}
          valueLabelDisplay="auto"
        />
      </Box>
      {data?.marker?.type === "3D" && (
        <Box id="rotation" px={1}>
          <InputLabel shrink>{t("/editor.gui.rotation")}</InputLabel>
          <Slider
            min={0}
            max={360}
            step={1}
            value={rotation}
            onChangeCommitted={(e, v) => setRotation(v)}
            onChange={(e, v) => setRotationSlider(v)}
            marks
            valueLabelDisplay="auto"
          />
        </Box>
      )}
    </>
  );
};

export default MarkerConfig;

const ParentMenuItem = ({ value, p, ...props }) => {
  const { title } = useWiviContent({
    ...p,
    id: p?.target?.id,
    type: p?.target?.type,
  });

  return (
    <MenuItem
      value={value}
      sx={{ overflow: "hidden", textOverflow: "ellipsis" }}
      {...props}
    >
      {title}
    </MenuItem>
  );
};
