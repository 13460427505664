import { useQuery } from "@apollo/client";
import { GET_MAPS } from "Config/API/queries";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { dataSelector, useStore } from "Store";
import { useMap } from "Utils/hooks";
import { setPalette } from "Utils/palette";

export const useStoreInitalization = () => {
  const { i18n } = useTranslation();
  const { loading, data, error } = useQuery(GET_MAPS, {
    variables: { iso: [i18n?.resolvedLanguage ?? "fr"] },
  });

  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { setValue, mapType } = useStore();
  const mapsData = useStore(dataSelector);
  const { createMap } = useMap();

  const setMapType = (v) => setValue("mapType", v);

  useEffect(() => {
    const leafletPages = [
      "/outdoor",
      "/leafleteditor",
      "/leafletconfig",
      "/",
      "/admin",
    ];
    const threejsPages = [
      "/editor",
      "/indoor",
      "/configuration",
      "/admin",
      "/",
    ];
    const isLeaflet = leafletPages.includes(pathname);
    const isThreejs = threejsPages.includes(pathname);

    setMapType(isLeaflet ? "leaflet" : isThreejs ? "threejs" : null);
  }, [pathname]);

  useEffect(() => {
    if (loading) return;
    if (
      // redirige la visite vers page d'erreur si pas de data
      !loading &&
      !data?.maps?.length &&
      ["/indoor", "/outdoor", "/"].includes(pathname)
    ) {
      return navigate("/error");
    }

    if (data?.maps?.length) {
      const mapTypeExists = data.maps.some((m) => m.type === mapType);
      setValue("data", [...mapsData, ...data?.maps]);

      //initialisation d'une map du type demandé si elle n'existe pas déjà parmis les maps existantes
      mapType &&
        !mapTypeExists &&
        !["/admin", "/"].includes(pathname) &&
        createMap({ type: mapType });
    } else {
      //initialisation d'une map du type demandé si aucune map n'existe
      if (data?.maps?.length < 1) createMap({ type: mapType });
    }
  }, [data, mapType, loading, pathname]);

  useEffect(() => {
    // initialisation de la palette de couleur du client
    window.__CONFIG__?.theme &&
      setValue("theme", setPalette(window.__CONFIG__.theme));
  }, []);

  return { loading, error, mapType };
};
