import { useEffect, useMemo, useState } from "react";
import {
  useStore,
  dataSelector,
  actualLayerIdSelector,
  setValueSelector,
  layersSelector,
  setDataSelector,
} from "Store";
import _, { orderBy, sortBy } from "lodash";
import { useMap } from "./map";

/**
 * Hook permettant d'accéder aux infos et methodes de l'étage actuel
 * Un tableau de directions indique les possibilité de changement de layer
 */
export const useActualLayer = () => {
  const setStoreValue = useStore(setValueSelector);
  const actualLayerId = useStore(actualLayerIdSelector);

  const { data, configuration } = useMap();

  const set = (id) => setStoreValue("actualLayerId", id);
  const actualLayer = useMemo(
    () =>
      data?.layers?.filter((f) => f?.id === actualLayerId)?.[0] ??
      data?.layers?.[0],
    [data, actualLayerId]
  );

  const placeholders = useMemo(
    () => actualLayer?.placeholders ?? [],
    [actualLayer, data]
  );

  const defaultDirections = useMemo(
    () => ({
      up: false,
      down: false,
      left: false,
      right: false,
      back: false,
      front: false,
    }),
    []
  );

  const [directions, setDirections] = useState(defaultDirections);
  const setDirection = (dir, layer) => {
    setDirections((pv) => ({ ...pv, [dir]: layer }));
  };

  useEffect(() => {
    setDirections(defaultDirections);
    const { x, y, z } = actualLayer?.position ?? { x: 0, y: 0, z: 0 };

    data?.layers?.forEach((f) => {
      const { __typename, ...pos } = f.position;
      _.isEqual(pos, { x, y, z: z + 1 }) && setDirection("up", f);
      _.isEqual(pos, { x, y, z: z - 1 }) && setDirection("down", f);
      _.isEqual(pos, { x: x + 1, y, z }) && setDirection("right", f);
      _.isEqual(pos, { x: x - 1, y, z }) && setDirection("left", f);
      _.isEqual(pos, { x, y: y + 1, z }) && setDirection("back", f);
      _.isEqual(pos, { x, y: y - 1, z }) && setDirection("front", f);
    });
  }, [actualLayer, data?.layers, defaultDirections]);

  const isGeolocation =
    configuration?.geolocation && actualLayer?.geoPoints?.length >= 2;
  const otherLayers = useMemo(
    () => data?.layers?.filter((f) => f.id !== actualLayer?.id),
    [actualLayer, data?.layers]
  );

  return {
    actualLayer,
    placeholders,
    id: actualLayerId,
    set,
    directions,
    isGeolocation,
    geoPoints: actualLayer?.geoPoints,
    otherLayers,
  };
};

/**
 * Hook de gestion d'un layer
 * Permet de récupérer les data et les modifier
 * Permet d'ajouter ou supprimer ce layer (layerId !== null) ou un autre layer (passage de l'id en argument d'une fonction)
 * @param {String} layerId
 */
export const useLayers = (layerId) => {
  const { data, setData } = useMap();
  const layers = data?.layers ?? [];
  // const setData = useStore(setDataSelector);
  const { set } = useActualLayer();

  const getLayer = (id) => {
    return layers?.filter((f) => f?.id === id)?.[0];
  };

  const setLayers = (layers) => {
    console.log('layers:', layers)
    const ordered = updateIndexes(layers);
    console.log('ordered:', ordered)
    setData({ layers: ordered });
    set(ordered[0].id);
  };

  const addLayer = (layerObj) => {
    const newLayers = [...layers, layerObj];
    setData({ layers: newLayers });
    set(newLayers?.[0]?.id);
    const ordered = updateIndexes(newLayers);
    setLayers(ordered);
  };

  const removeLayer = (id) => {
    const newLayers = [...layers].filter((f) => f.id !== id);

    setData({ layers: newLayers });
    set(newLayers?.[0]?.id);
    if (newLayers.length) {
      const ordered = updateIndexes(newLayers);
      setLayers(ordered);
    }
  };

  const setLayerValue = (key, val) => {
    const newLayers = layers?.map((f) => ({
      ...f,
      [key]: f.id === layerId ? val : f[key],
    }));
    setData({ layers: newLayers });
  };

  const updateLayer = (layerObj) => {
    console.log('layerObj:', layerObj)
    const newLayers = layers?.map((f) => (f.id === layerObj.id ? layerObj : f));
    setData({ layers: newLayers });
    set(layerObj.id);
    const ordered = updateIndexes(newLayers);
    setLayers(ordered);
  };
  // Met à jour les valeurs des clé index dans l'ordre de tri actuel des layers.
  const updateIndexes = (data) => {
    // Maj des index
    const layersArr = data ?? layers;

    if (!layersArr?.length) return;
    const newLayers = sortBy(
      layersArr,
      [
        function (l) {
          return l?.data?.index;
        },
      ],
      ["asc"]
    ).map((p, i) => {
      return {
        ...p,
        data: {
          ...p.data,
          index: i,
        },
      };
    });
    // Save changes
    return newLayers;
  };

  const setDataValue = (key, val) => {
    if (!layerId) return;
    setLayerValue("data", { ...getLayer(layerId).data, [key]: val });
  };

  const setPlaceholders = (val) => {
    setLayerValue("placeholders", val);
  };

  const setRotation = (val) => {
    setLayerValue("rotation", val);
  };

  const setScale = (val) => {
    setLayerValue("scale", val);
  };

  const setName = (val) => {
    setLayerValue("name", val);
  };

  const setGeoPoints = (val) => {
    setLayerValue("geoPoints", val);
  };

  const setLeafletZoom = (zoom) => {
    setDataValue("zoom", zoom);
  };

  const setLeafletTilesStyle = (style) => {
    setDataValue("tilesStyle", style);
  };

  const setLeafletLayerIndex = (index) => {
    setDataValue("index", index);
  };

  const setPosition = (val) => {
    setLayerValue("position", val);
  };

  const setTitle = (val) => {
    setLayerValue("title", val);
  };

  const patchTitle = ({ code, value }) => {
    if (!layerId || !code) return;
    const actualLayer = getLayer(layerId);

    let newTitle = actualLayer?.title?.length ? [...actualLayer?.title] : [];
    if (!value) {
      newTitle = [...newTitle].filter((t) => t.language !== code);
    } else {
      const exists = newTitle.find((t) => t.language === code);

      const add = newTitle?.map((t) =>
        t.language === code
          ? { ...t, value: { text: value, html: `<p>${value}</p>` } }
          : t
      );
      exists
        ? (newTitle = add)
        : (newTitle = [
            ...newTitle,
            { language: code, value: { text: value, html: `<p>${value}</p>` } },
          ]);
    }

    setTitle(newTitle);
  };

  return {
    layers: sortBy(
      layers,
      [
        function (l) {
          return l.data?.index ?? 0;
        },
      ],
      ["asc"]
    ),
    layer: layerId && getLayer(layerId),
    getLayer,
    addLayer,
    remove: () => layerId && removeLayer(layerId),
    removeLayer: (id) => removeLayer(id),
    setRotation,
    setScale,
    setName,
    setGeoPoints,
    setLayers,
    setPlaceholders,
    updateIndexes,
    setLeafletLayerIndex,
    setLeafletZoom,
    setLeafletTilesStyle,
    setPosition,
    updateLayer,
    patchTitle,
  };
};
