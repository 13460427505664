import { Stack, Switch, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useMap } from "Utils/hooks";

const SettingsPanel = () => {
  const { t } = useTranslation();
  const { configuration, patchConfig } = useMap();

  const handleGeolocationChange = (e) => {
    patchConfig("geolocation", e.target.checked);
  };
  const handleSwitch = (e) => {
    patchConfig(e.target.name, e.target.checked);
  };
  return (
    <Stack direction="column" spacing={2}>
      <Typography variant="h6" color="primary">
        {t("/leafletconfig.settings.title")}
      </Typography>
      <Stack direction="row" alignItems="center">
        <Switch
          name="geolocation"
          checked={configuration?.geolocation}
          onChange={handleSwitch}
        />
        <Typography variant="body1">
          {t("/leafletconfig.settings.geolocation_instruction")}
        </Typography>
      </Stack>
      <Stack direction="row" alignItems="center">
        <Switch
          name="clustering"
          checked={configuration?.clustering}
          onChange={handleSwitch}
        />
        <Typography variant="body1">
          {t("/leafletconfig.settings.clustering_instruction")}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default SettingsPanel;
