import React from "react";
import {
  EditorSidePanel,
  ConfigButton,
} from "Components/Threejs/Editor";
import Box from "@mui/material/Box";
import GUI from "Components/Global/GUI";
import { ToastContainer } from "react-toastify";
import { TopBar } from "Components//Global";
import LeafletEditorMap from "Components/Leaflet/Editor/LeafletAdminMap";
import { Globals } from "@react-spring/web";

Globals.assign({
  frameLoop: "always",
});

export default function LeafletEditor() {
  return (
    <Box height="100%" display="flex" flexDirection="column">
      <ToastContainer />
      <TopBar />
      <Box height="100%" display="flex">
        <EditorSidePanel />
        <GUI />
        <LeafletEditorMap createOverlay editMode />
        <ConfigButton />
      </Box>
    </Box>
  );
}
