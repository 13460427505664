import { client } from "./apollo";
import { gql } from "@apollo/client";
export { client };

export const SAVE_CHANGES = gql`
  mutation FiberMap($value: JSON!) {
    storeDataset(key: "fibermap", value: $value) {
      key
      value
    }
  }
`;

export const CREATE_MAP = gql`
  mutation CreateMap(
    $name: String
    $iso: [LanguageCode!]!
    $id: UUID
    $type: MapType
  ) {
    createMap(name: $name, id: $id, type: $type) {
      id
      i18n(languages: $iso) {
        title {
          value {
            text
          }
        }
      }
      layers {
        name
        position {
          x
          y
          z
        }
        geoPoints {
          color
          latitude
          longitude
          altitude
          position {
            x
            y
            z
          }
        }
        scale
        rotation
        i18n(languages: $iso) {
          title {
            value {
              text
            }
          }
          description {
            value {
              text
            }
          }
        }
        placeholders {
          id
          index
          data
          layerId
          title {
            value {
              text
            }
            language
          }
          position {
            x
            y
            z
          }
          marker
          duration
          name
          target
          parentId
          children {
            id
            layerId
            name
            marker
            duration
            parentId
            target
            position {
              x
              y
              z
            }
          }
        }
        geographyTransformationMatrix
        id
        image {
          id
          file {
            width
            height
            url
          }
        }
      }
      configuration
    }
  }
`;

export const UPLOAD_IMAGE = gql`
  mutation UploadImage($file: Upload!) {
    mapImage: createMediaImage(file: $file) {
      id
      formats {
        file {
          width
          height
          url
        }
        name
      }
    }
  }
`;

export const DELETE_IMAGE = gql`
  mutation DeleteImage($id: UUID!) {
    deleteImage(id: $id)
  }
`;

export const CREATE_LAYER = gql`
  mutation CreateLayer(
    $mapId: UUID!
    $position: GeometryPointInput!
    $geoPoints: [MapGeoPointInput!]
    $scale: Float!
    $rotation: Float!
    $imageId: UUID
    $name: String
    $data : JSON
    $title: [HTMLTranslationInput!]
  ) {
    createLayer(
      imageId: $imageId
      mapId: $mapId
      name: $name
      position: $position
      scale: $scale
      geoPoints: $geoPoints
      rotation: $rotation
      data : $data
      title: $title
    ) {
      id
      placeholders {
        id
        data
        position {
          x
          y
          z
        }
        marker
        name
      }
    
    }
  }
`;

export const DELETE_LAYER = gql`
  mutation DeleteLayer($layerId: UUID!) {
    deleteLayer(id: $layerId)
  }
`;

export const UPDATE_LAYER = gql`
  mutation UpdateLayer(
    $layerId: UUID!
    $rotation: Float
    $scale: Float
    $name: String
    $position: GeometryPointInput
    $geoPoints: [MapGeoPointInput!]
    $data : JSON
    $title: [HTMLTranslationInput!]
  ) {
    updateLayer(
      id: $layerId
      geoPoints: $geoPoints
      rotation: $rotation
      scale: $scale
      name: $name
      position: $position
      title: $title
      data : $data
    ) {
      id
    }
  }
`;

export const UPDATE_MAP = gql`
  mutation UpdateMap(
    $mapId: UUID!
    $slug: Slug
    $name: String
    $enablePreview: Boolean
    $imageId: UUID
    $configuration: JSON
    $type: MapType
    $title: [HTMLTranslationInput!]
  ) {
    updateMap(
      id: $mapId
      slug: $slug
      name: $name
      enablePreview: $enablePreview
      imageId: $imageId
      configuration: $configuration
      type: $type
      title : $title
    ) {
      id
    }
  }
`;

export const UPDATE_PLACEHOLDER = gql`
  mutation UpdatePlaceholder(
    $id: UUID!
    $name: String
    $position: GeometryPointInput
    $marker: JSON
    $parentId: UUID
    $children: [CreatePlaceholderArgs!]
    $target: JSON
    $duration: Float
    $index: Float
    $data : JSON
    $title:  [HTMLTranslationInput!]
  ) {
    updatePlaceholder(
      id: $id
      duration: $duration
      parentId: $parentId
      name: $name
      position: $position
      marker: $marker
      children: $children
      target: $target
      index: $index
      data : $data
      title: $title
    ) {
      id
    }
  }
`;

export const CREATE_PLACEHOLDER = gql`
  mutation CreatePlaceholder(
    $id: UUID
    $name: String
    $position: GeometryPointInput!
    $marker: JSON
    $layerId: UUID
    $parentId: UUID
    $children: [CreatePlaceholderArgs!]
    $target: JSON
    $duration: Float
    $index: Float
    $data : JSON
    $title:  [HTMLTranslationInput!]
  ) {
    createPlaceholder(
      id: $id
      children: $children
      duration: $duration
      parentId: $parentId
      target: $target
      name: $name
      position: $position
      marker: $marker
      layerId: $layerId
      index: $index
      data : $data
      title : $title
    ) {
      id
    }
  }
`;

export const DELETE_PLACEHOLDER = gql`
  mutation DeletePlaceholder($id: UUID!) {
    deletePlaceholder(id: $id)
  }
`;
