import React, { useMemo, useRef } from "react";
import Slider from "./Slider";
import styles from "./bottomBar.module.scss";
import { Box, Grid, IconButton, Typography } from "@mui/material";
import { useActualLayer, useDisplayedPlaceholders } from "Utils/hooks";
import { Map } from "@mui/icons-material";
import LayersChangeButton from "./LayersChangeButton";
import useWiviContent from "Utils/hooks/wiviContent";

const BottomBar = () => {
  const { actualLayer, otherLayers } = useActualLayer();
  const { parent, clearFilters } = useDisplayedPlaceholders();
  const isBottomBar = useMemo(() => otherLayers?.length, [otherLayers]);
  const bottomBarRef = useRef();
  const { title: parentTitle } = useWiviContent({
    ...parent,
    type: parent?.target?.type,
    id: parent?.target?.id,
  });

  return (
    // <Box className={styles.container}>
    <Box
      //  position="relative"
      height="100%"
      width="100vw"
      zIndex={999999}
    >
      {isBottomBar ? (
        <Box ref={bottomBarRef} className={styles.barContainer} py={2}>
          <Box
            px={4}
            display="flex"
            height="100%"
            width="100%"
            justifyContent="space-between"
            alignItems={"center"}
          >
            {parent ? (
              <Box alignItems="center" display="flex">
                <IconButton color="primary" onClick={clearFilters} size="large">
                  <Map />
                </IconButton>
                <Box className={styles.titleContainer}>
                  <Typography variant="body1">| {parentTitle}</Typography>
                </Box>
              </Box>
            ) : (
              <Box className={styles.titleContainer}>
                <Typography>
                  {actualLayer?.i18n?.title?.value?.text ??
                    actualLayer?.title?.[0]?.value?.text}
                </Typography>
              </Box>
            )}

            <LayersChangeButton />
          </Box>
        </Box>
      ) : null}
      <Slider isBottomBar={isBottomBar} bottomBarRef={bottomBarRef} />
    </Box>
    // </Box>
  );
};

export default BottomBar;


