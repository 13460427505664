// Original: https://github.com/chenglou/react-motion/tree/master/demos/demo8-draggable-list

import { Box } from "@mui/material";
import swap from "lodash-move";
import clamp from "lodash/clamp";
import React, { useEffect, useRef, useState } from "react";
import { animated, useSprings } from "@react-spring/web";
import { useDrag } from "react-use-gesture";
import { useLayers } from "Utils/hooks";
import LayerListItem from "./LayerListItem";
import "./styles.css";
const AnimBox = animated(Box);
const size = 170;
// Returns fitting styles for dragged/idle layers
const fn = (order, active, originalIndex, curIndex, y) => (index) =>
  active && index === originalIndex
    ? {
        y: curIndex * size + y,
        scale: 1.1,
        zIndex: "1",
        shadow: 15,
        immediate: (n) => n === "y" || n === "zIndex",
      }
    : {
        y: order.indexOf(index) * size,
        scale: 1,
        zIndex: "0",
        shadow: 1,
        immediate: false,
      };

const LayerList = () => {
  const { layers, setLayers } = useLayers();
  const [stopped, setStopPropagation] = useState(false);
  const order = useRef(layers?.map((_, index) => _?.data?.index ?? index)); // Store indicies as a local ref, this represents the item order
  const [springs, setSprings] = useSprings(layers.length, fn(order.current), [
    layers,
    order,
  ]); // Create springs, each corresponds to an item, controlling its transform, scale, etc.

  useEffect(() => {
    order.current = layers?.map((_, index) => _?.data?.index ?? index);
  }, [layers]);

  const bind = useDrag(
    ({ args: [originalIndex], active, movement: [, y] }) => {
      const curIndex = order.current.indexOf(originalIndex);
      const curRow = clamp(
        Math.round((curIndex * 170 + y) / 100),
        0,
        layers.length - 1
      );
      const newOrder = swap(order.current, curIndex, curRow);
      setSprings(fn(newOrder, active, originalIndex, curIndex, y)); // Feed springs new style data, they'll animate the view without causing a single render

      if (!active) {
        // let newData = layers.map((e, i) => {
        //   const {x, y} = e?.position;
        //   return { ...e, position: {x, y, z : (order.current?.length <= 1) ? 0 : newOrder[i]} };
        // });
        // setLayers(newData);
        let newLayers = [...layers];
        newOrder.map((_, index) => {
          const layer = layers?.find((l) => l?.id === layers[_].id);

          const newData = [...newLayers].map((l) => {
            return l.id === layer.id ? { ...l, data: { ...l.data, index } } : l;
          });
          newLayers = newData;
        });
        setLayers(newLayers);
      }
    },
    {
      enabled: !stopped,
    }
  );

  return (
    <Box height={layers.length * size} position="relative">
      {springs.map(({ zIndex, shadow, y, scale }, i) => (
        <AnimBox
          position="absolute"
          display="flex"
          alignlayers="center"
          flexWrap="wrap"
          maxWidth="100%"
          width="100%"
          {...bind(i)}
          key={layers[i].id}
          style={{
            zIndex,
            boxShadow: shadow.to(
              (s) => `rgba(0, 0, 0, 0.15) 0px ${s}px ${2 * s}px 0px`
            ),
            y,
            scale,
            height: size * 0.8,
            backgroundColor: "white",
          }}
        >
          <LayerListItem
            togglePropagation={() => setStopPropagation((v) => !v)}
            setStopPropagation={setStopPropagation}
            order={order.current}
            id={layers[i]?.id}
          />
        </AnimBox>
      ))}
    </Box>
  );
};

export default LayerList;
