import React, { useMemo, useRef } from "react";
import { useGLTF } from "@react-three/drei";
import { MeshStandardMaterial } from "three";
import { animated } from "@react-spring/three";

const Arrow = ({ color, opacity, position, rotation, scale, ...props }) => {
  const group = useRef();
  const { nodes } = useGLTF("/GLTF/arrow.gltf");
  const material = useMemo(
    () =>
      new MeshStandardMaterial({
        color: color ?? "#c43737",
        roughness: 0.7,
        emissive: "#ff1111",
        emissiveIntensity: 0.4,
        transparent: true,
        opacity: opacity ?? 1,
      }),
    [color, opacity]
  );

  return (
    <animated.group
      ref={group}
      rotation={rotation ?? [Math.PI / 2, 0, 0]}
      scale={scale ? [scale[0], scale[1], scale[2]] : [1, 1, 1]}
      position={position ?? [0, 0, 0]}
      {...props}
      dispose={null}
    >
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane.geometry}
        material={material}
      />
    </animated.group>
  );
};

useGLTF.preload("/GLTF/arrow.gltf");
export default Arrow;
