import { FormControl, MenuItem, Select } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useActualLayer, useLayers } from "Utils/hooks";

const LayerSelector = () => {
  const { actualLayer, set } = useActualLayer();
  const { layers } = useLayers();
  const { i18n } = useTranslation();

  const handleChange = (e) => {
    set(e.target.value);
  };
  return layers?.length > 1 ? (
    <FormControl size="small">
      <Select
        value={actualLayer?.id}
        onChange={handleChange}
        sx={{ backgroundColor: "rgba(255,255,255,.7)", maxWidth: 200 }}
      >
        {layers?.map((l) => (
          <MenuItem key={l?.id} value={l?.id}>
            {l?.title?.filter?.(
              (e) => e.language === i18n.resolvedLanguage
            )?.[0]?.value?.text ??
              l?.i18n?.title?.value?.text ??
              `${l?.data?.index}`}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  ) : null;
};

export default LayerSelector;
