import { Place } from "@mui/icons-material";
import {
  Button,
  InputAdornment,
  List,
  ListItem,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import { debounce } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { layersSelector } from "Store";
import { useLayers } from "Utils/hooks";
import { v4 as uuid } from "uuid";
import AddressFinder from "./addressFinder";

const CreateLayer = () => {
  const { t } = useTranslation();
  
  const { addLayer, layers } = useLayers();

 

  const handleAddress = async (data) => {
    try {
      const { lon, lat, boundingbox } = data ?? {};
      const newLayer = {
        name: data.display_name,
        scale: 1,
        rotation : 0,
        id: uuid(),
        position: {
          x: parseFloat(lon),
          y: parseFloat(lat),
          z: 0,
        },
      };
      addLayer(newLayer);
    } catch (error) {
      console.log("error:", error);
    }
  };



  return layers?.length < 1 && (
    <Box
      width="100%"
      height="100%"
      position="absolute"
      top={0}
      left={0}
      zIndex={2}
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection={"column"}
      bgcolor="rgba(255,255,255,0.4)"
      sx={{ backdropFilter: "blur(6px)" }}
    >
      <Typography variant="h6">
        {t("/leafleteditor.createlayer.title")}
      </Typography>
      <Typography variant="body1">
        {t("/leafleteditor.createlayer.description")}
      </Typography>
      <AddressFinder callback={handleAddress} />
    
      {/* <Button variant="contained" color="primary">
          {t("/leafleteditor.createlayer.set_address")}
        </Button> */}
    </Box>
  );
};

export default CreateLayer;
