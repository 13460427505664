import { sortBy } from "lodash";
import { useEffect, useMemo, useState } from "react";

import {
  activePlaceholderIdSelector,
  dataFilterSelector,
  parentSelector,
  setValueSelector,
  useStore,
} from "Store";
import { convertCoords } from "Utils/positionConverter";
import { v4 as uuidv4 } from "uuid";
import { useActualLayer, useLayers } from "./layers";
import { useMap } from "./map";

export const useActualPlaceholder = (parentId) => {
  const setStoreValue = useStore(setValueSelector);
  const activePlaceholderId = useStore(activePlaceholderIdSelector);
  const {
    updatePlaceholder,
    updateChildPlaceholder,
    placeholder: data,
    addChildPlaceholder,
  } = usePlaceholders(activePlaceholderId);

  const { geoPoints } = useActualLayer();

  const set = (id) => {
    setStoreValue("activePlaceholderId", id);
  };

  const updatePH = (id, key, val) => {
    parentId
      ? updateChildPlaceholder(activePlaceholderId, key, val, parentId)
      : updatePlaceholder(id, key, val);
  };
  const updateMarker = (key, value) => {
    const newMarker = { ...data?.marker, [key]: value };
    updatePH(data?.id, "marker", newMarker);
  };

  const setColor = (v) => {
    updateMarker("color", v);
  };

  const setType = (type) => {
    updateMarker("type", type);
  };

  const setMarkerIcon = (icon) => {
    updateMarker("icon", icon);
  };

  const setMarkerIconColor = (color) => {
    updateMarker("iconColor", color);
  };

  const setModel = (model) => {
    updateMarker("model", model);
  };

  const setName = (v) => {
    updatePH(data?.id, "name", v);
  };
  const setPosition = (v) => {
    updatePH(data?.id, "position", v);
  };

  const setDuration = (v) => {
    updatePH(data?.id, "duration", v);
  };
  const setParent = (v) => {
    // updatePH(data?.id, "parentId", v);
    addChildPlaceholder(v, data?.id);
  };

  const setScale = (v) => {
    updateMarker("scale", v);
  };

  const setRotation = (v) => {
    updateMarker("rotation", v);
  };

  const setTarget = (v) => {
    updatePH(data?.id, "target", v);
  };

  const setPosFromCoords = (lat, lng) => {
    const { position } = convertCoords(lat, lng, geoPoints);
    setPosition(position);
  };

  const patchData = (key, val) => {
    const newData = { ...data?.data, [key]: val };
    updatePH(data?.id, "data", newData);
  };

  const patchTitle = ({ code, value }) => {
    let newTitle = data?.title?.length ? [...data?.title] : [];
    if (!value) {
      newTitle = [...newTitle].filter((t) => t.language !== code);
    } else {
      const exists = newTitle.find((t) => t.language === code);

      const add = newTitle?.map((t) =>
        t.language === code
          ? { ...t, value: { text: value, html: `<p>${value}</p>` } }
          : t
      );
      exists
        ? (newTitle = add)
        : (newTitle = [
            ...newTitle,
            { language: code, value: { text: value, html: `<p>${value}</p>` } },
          ]);
    }
    updatePH(data?.id, "title", newTitle);
  };

  return {
    set,
    data,
    setColor,
    setPosition,
    setParent,
    setType,
    setName,
    setMarkerIcon,
    setMarkerIconColor,
    setModel,
    activePlaceholderId,
    setTarget,
    setScale,
    setRotation,
    setPosFromCoords,
    setDuration,
    patchData,
    patchTitle,
  };
};

export const usePlaceholders = (id) => {
  const { placeholders, actualLayer } = useActualLayer();
  const { setPlaceholders } = useLayers(actualLayer?.id);
  const { leafletInstance, isLeaflet } = useMap();

  const defaultLeafletPosition = () => {
    const { lat, lng } = leafletInstance?.getBounds().getCenter() ?? {
      lat: 0,
      lng: 0,
    };
    return { x: lng, y: lat, z: 0 };
  };
  // tableau applatit de tous les placeholders parents et enfants au meme niveau
  const allPh = placeholders.reduce((acc, v) => {
    const children = v?.children ?? [];
    return [...acc, { ...v }, ...children];
  }, []);

  //data du placeholder sélectionné dans le hook
  const placeholder = allPh?.filter((p) => p.id === id)[0];

  // renvoi les data d'un placeholder
  const getPlaceholder = (placeholderId) =>
    allPh?.filter((p) => p.id === placeholderId)?.[0];

  // model 3D de marker par defaut
  const defaultMarker = isLeaflet
    ? {
        type: "2D",
        icon: {
          name: "Accueil",
          value: "home",
        },
        color: "#e74c3c",
        iconColor: "#FFF",
      }
    : {
        type: "3D",
        model: "pin",
        color: "#e74c3c",
      };

  // SAFETY - créé un marker par défaut à tout placeholder enregistré en base qui n'aurait pas de marker
  useEffect(() => {
    placeholders?.length >= 1 &&
      placeholders?.map(
        (p) => !p.marker && updatePlaceholder(p.id, "marker", defaultMarker)
      );
  }, [placeholders]);

  const deletePlaceholder = (id) => {
    const newPhs = [...placeholders].filter((p) => p.id !== id);
    updateIndexes(newPhs);
  };

  // Renvoi valeur par défaut d'un nouveau placeholder
  const getNewPh = (val) => {
    return {
      ...val,
      id: uuidv4(),
      type: val?.type ?? "content",
      position: val?.position
        ? val.position
        : leafletInstance
        ? defaultLeafletPosition()
        : { x: 0, y: 0, z: 0 },
      layerId: actualLayer?.id,
      marker: defaultMarker,
      index: val?.index ?? placeholders.length,
      data: {},
    };
  };

  const addPlaceholder = (val, placeholderId) => {
    const newPh = getNewPh(val);
    setPlaceholders([...placeholders, newPh]);
    return newPh;
  };

  const addChildPlaceholder = (parentId, childId) => {
    const parentPlaceholder = [...placeholders].find((p) => p.id === parentId);
    const child = childId
      ? allPh?.find((p) => p.id === childId)
      : getNewPh({
          parentId: parentId,
          index: parentPlaceholder?.children?.length ?? 0,
        });

    const newPlaceholders = [...placeholders].map((p) => {
      const children = p?.children ?? [];
      return p.id === parentId
        ? { ...p, children: [...children, child] }
        : p?.id === childId
        ? { ...p, parentId: parentId }
        : p;
    });
    setPlaceholders(newPlaceholders);
  };

  const updatePlaceholder = (id, key, val) => {
    const newData = [...placeholders].map((p) =>
      p.id === id ? { ...p, [key]: val } : p
    );
    setPlaceholders(newData);
  };

  const updateChildPlaceholder = (id, key, val, parentId) => {
    const children = [...placeholders]?.find(
      (p) => p.id === parentId
    )?.children;
    const newChildren = children?.map((p) =>
      p.id === id ? { ...p, [key]: val } : p
    );
    updatePlaceholder(parentId, "children", newChildren);
  };

  const removeChildPlaceholder = async (childId, parentId) => {
    try {
      const parentPlaceholder = [...placeholders].find(
        (p) => p.id === parentId
      );
      const newChildren = [...parentPlaceholder?.children].filter(
        (p) => p.id !== childId
      );
      const newPlaceholders = [...placeholders].map((p) =>
        p.id === parentId ? { ...p, children: newChildren } : p
      );
      updateIndexes(newPlaceholders);
    } catch (error) {
      console.log("error:", error);
    }
  };

  // Met à jour les valeurs des clé index dans l'ordre de tri actuel des placeholders parents et enfants d'un parent (tableau children).
  const updateIndexes = (placeholders) => {
    // Maj des index
    const newPh = sortBy(placeholders, ["index"], ["asc"]).map((p, i) => {
      return {
        ...p,
        index: i,
        children: p?.children?.map((c, i) => ({ ...c, index: i })),
      };
    });
    // Save changes
    setPlaceholders(newPh);
  };

  const changeParent = (childId, oldParentId, newParentId) => {
    const child = allPh?.find((p) => p.id === childId); //data du child
    const newParent = getPlaceholder(newParentId); //data du nouveau parent
    const oldParent = getPlaceholder(oldParentId); //data de l'ancien parent

    //mise à jour de l'ancien et nouveau parent
    const changedParents = [...placeholders]?.map((p) =>
      p.id === oldParentId
        ? { ...p, children: p?.children?.filter((p) => p.id !== childId) }
        : p.id === newParentId
        ? {
            ...p,
            children: [
              ...(p?.children ?? []),
              { ...child, parentId: p?.id, index: newParent.children?.length },
            ],
          }
        : p
    );

    const filteredParents = !oldParentId
      ? changedParents.filter((p) => p.id !== childId)
      : changedParents;
    const newPhs = newParentId
      ? filteredParents
      : [
          ...filteredParents,
          { ...child, parentId: null, index: placeholders.length },
        ];

    updateIndexes(newPhs);
  };

  //change les index dans un tableau
  const swapArrayLocs = (array, index1, index2) => {
    let arr = [...array];
    [arr[index1], arr[index2]] = [arr[index2], arr[index1]];
    return arr;
  };

  // change index value of a placeholder
  const changeRow = (id, index, direction) => {
    const { parentId } = getPlaceholder(id) ?? {};
    const phArray = parentId
      ? getPlaceholder(parentId)?.children
      : placeholders;

    const ordered = swapArrayLocs(
      phArray,
      index,
      direction === "desc" ? index + 1 : index - 1
    );
    if (parentId) {
      const newPh = [...ordered].map((p, i) => ({ ...p, index: i }));
      updatePlaceholder(parentId, "children", newPh);
    } else {
      const newPh = [...ordered].map((p, i) => ({ ...p, index: i }));
      setPlaceholders(newPh);
    }
  };

  const swapRow = (id, index, newIndex) => {
    const { parentId } = getPlaceholder(id) ?? {};
    const phArray = parentId
      ? getPlaceholder(parentId)?.children
      : placeholders;
    const ordered = swapArrayLocs(phArray, index, newIndex);
    const newPh = [...ordered].map((p, i) => ({ ...p, index: i }));
    if (parentId) {
      updatePlaceholder(parentId, "children", newPh);
    } else {
      setPlaceholders(newPh);
    }
  };

  return {
    placeholder,
    addChildPlaceholder,
    removeChildPlaceholder,
    placeholders: sortBy(placeholders, ["index"], ["asc"]),
    setPlaceholders,
    addPlaceholder,
    updatePlaceholder,
    updateChildPlaceholder,
    deletePlaceholder,
    changeParent,
    changeRow,
    swapRow
  };
};

// Hook qui gère les placeholders affiché sur le plan (parents/enfants)
export const useDisplayedPlaceholders = (config) => {
  const dataFilter = useStore(dataFilterSelector);
  const setStoreValue = useStore(setValueSelector);
  const { set } = useActualPlaceholder();
  const { id, placeholders } = useActualLayer();
  const defaultPlaceholder = placeholders.filter((p) => !p.parentId);
  const [displayed, setDisplayed] = useState(defaultPlaceholder);
  const parent = useStore(parentSelector);
  const setParent = (v) => setStoreValue("parent", v);
  const [layerId] = useState(id);
  const showPathway = useMemo(
    () => parent?.data?.pathway?.active,
    [parent?.data?.pathway?.active]
  );
  const pathwayColor = useMemo(
    () => parent?.data?.pathway?.color ?? "red",
    [parent?.data?.pathway?.color]
  );

  // filtre les placeholders affiché si un parent est selectionné
  // focus sur le premier ph enfant si la page n'est pas /editor
  useEffect(() => {
    const { placeholderId } = dataFilter ?? {};
    if (placeholderId) {
      const placeholderData =
        placeholders?.find((p) => p.id === placeholderId) ?? {};

      const { children } = placeholderData;
      children?.length && setDisplayed(children);
      // children?.length  && set(children[0].id);
      setParent(children?.length ? placeholderData : null);
    } else setDisplayed(placeholders?.filter((p) => !p.parentId));
  }, [dataFilter, placeholders]);

  // supprime le filtre parent au changement de niveau
  useEffect(() => {
    id !== layerId && clearFilters();
  }, [id]);

  const clearFilters = () => {
    setStoreValue("dataFilter", null);
    setParent(null);
    set(null);
  };

  const setFilter = (filter) => {
    setStoreValue("dataFilter", filter);
  };

  return {
    placeholders: displayed,
    filters: dataFilter,
    parent: parent,
    clearFilters,
    setFilter,
    showPathway,
    pathwayColor,
  };
};
