import Map from "Components/Threejs/Map/Map";
import BottomBar from "Components/Global/BottomBar";
import React from "react";

import { useQueryParam } from "Utils/hooks/queries";
import ContentTopBar from "Components/Global/ContentTopBar";

const App = () => {
  useQueryParam();

  return (
    <>
      <ContentTopBar />
      <Map />
      <BottomBar />
    </>
  );
};
export default App;
