import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Add from './AddLightButton'
import LightItem from './LightsListItem'
import { useStore } from 'Store'


const LightControls = () => {
    const tempData = useStore(s => s.tempData)
    const data = tempData?.lights

    return (
        <Box>
            <Typography variant='h6' color='primary'>Lumières</Typography>
            {data?.length ? data.map((l, index) =>
                <LightItem light={l} index={index} key={l.id} />
            ) : null}
            <Add />
        </Box>
    )
}

export default LightControls

