import {
  Box,
  Collapse,
  IconButton,
  Stack,
  Typography,
  useTheme,
  Tooltip,
  Badge,
} from "@mui/material";
import {
  Add,
  Delete,
  Edit,
  ExpandLess,
  ExpandMore,
  Folder,
  GpsFixed,
  KeyboardArrowDown,
  KeyboardArrowUp,
  PinDrop,
} from "@mui/icons-material";
import React, { useEffect, useMemo, useState } from "react";
import { useActualPlaceholder, usePlaceholders } from "Utils/hooks";
import { useDisplayedPlaceholders } from "Utils/hooks/placeholders";
import { useTranslation } from "react-i18next";
import useWiviContent from "Utils/hooks/wiviContent";

const ContentPlaceholder = ({ data }) => {
  const { palette } = useTheme();
  const { set, activePlaceholderId } = useActualPlaceholder();
  const {
    deletePlaceholder,
    placeholder,
    addChildPlaceholder,
    removeChildPlaceholder,
  } = usePlaceholders(data?.id);

  const { setFilter, parent, clearFilters } = useDisplayedPlaceholders();
  const [open, setOpen] = useState(false);
  const { children, id, target, index, title, ...p } = placeholder ?? [];
  const hasChildren = children?.length >= 1;
  const isActive = useMemo(
    () => activePlaceholderId === id,
    [activePlaceholderId, id]
  );

  const handleRemove = async () => {
    deletePlaceholder(id);
    clearFilters();
  };

  useEffect(() => {
    open && hasChildren && setFilter({ placeholderId: id });
    open && set(null);
  }, [open]);

  useEffect(() => {
    parent?.id !== id && setOpen(false);
  }, [parent]);

  const addNewChild = async () => {
    addChildPlaceholder(id);
  };

  const deleteChild = (childId) => {
    removeChildPlaceholder(childId, id);
    // clearFilters();
  };

  const toggleParent = () => {
    if (hasChildren) {
      open && clearFilters();
      setOpen(!open);
    }
  };

  const viewParent = () => {
    open && clearFilters();
    set(id);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      boxShadow={isActive ? 6 : 1}
      border={isActive ? `2px solid ${palette?.primary?.main} ` : "none"}
      borderRadius="3px"
    >
      <ItemContent
        toggle={toggleParent}
        open={open}
        children={children}
        add={addNewChild}
        title={title}
        view={viewParent}
        remove={handleRemove}
        hasChildren={hasChildren}
        index={index}
        id={id}
        target={target}
        {...p}
      />
      <Collapse in={open} timeout="auto" unmountOnExit>
        <Box id="childrenContainer" display="flex" flexDirection="column">
          {children?.map((p, i) => (
            <Box m={1} pl={2} key={p.id}>
              <ItemContent
                {...p}
                primary={palette?.primary?.main}
                isActive={p.id === activePlaceholderId}
                title={p?.title}
                view={() => set(p?.id)}
                remove={() => deleteChild(p?.id)}
                children={children}
                isChild
              />
            </Box>
          ))}
        </Box>
      </Collapse>
    </Box>
  );
};

export default ContentPlaceholder;

const ItemContent = ({
  title,
  add,
  view,
  remove,
  toggle,
  open,
  hasChildren,
  isActive,
  primary,
  index,
  id,
  children,
  target,
  i18n,
  isChild,
}) => {
  const { placeholders, changeRow } = usePlaceholders();
  const { t } = useTranslation();

  return (
    <Stack
      display="flex"
      justifyContent="space-between"
      direction="row"
      alignItems="center"
      spacing={1}
      width="100%"
      // width="100%"
      // borderRadius="3px"
      boxShadow={isActive ? 3 : 0}
      border={isActive ? `2px solid ${primary} ` : "none"}
    >
      {hasChildren && (
        <Box display="flex" alignItems="center">
          <Tooltip title={t(`/editor.sideBar.${open ? "close" : "open"}`)}>
            <IconButton onClick={toggle} size="small">
              {open ? <ExpandLess /> : <ExpandMore />}
            </IconButton>
          </Tooltip>
        </Box>
      )}

      <Box ml={!hasChildren ? 2 : 0} mr={1}>
        {hasChildren ? (
          <Badge
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            badgeContent={children?.length}
            color="secondary"
          >
            <Folder color="primary" />
          </Badge>
        ) : (
          <PinDrop color="primary" />
        )}
      </Box>
      <Box flex={1} width="100%">
        <PlaceholderTitle id={id} target={target} i18n={i18n} title={title} />
        <TargetSubTitle {...target} />
      </Box>
      <Stack alignItems="center" direction="row" spacing={1}>
        {add && (
          <Box display="flex" alignItems="center">
            <Tooltip title={t("/editor.sideBar.add_child")}>
              <IconButton onClick={add} size="small">
                <Add />
              </IconButton>
            </Tooltip>
          </Box>
        )}
        <Box display="flex" alignItems="center">
          <Tooltip title={t("/editor.sideBar.edit")}>
            <IconButton onClick={view} size="small">
              <Edit />
            </IconButton>
          </Tooltip>
        </Box>
        <Box display="flex" alignItems="center">
          <Tooltip title={t("/editor.sideBar.delete")}>
            <span>
              <IconButton
                onClick={remove}
                disabled={toggle && hasChildren}
                size="small"
              >
                <Delete />
              </IconButton>
            </span>
          </Tooltip>
        </Box>
        <Box>
          <Stack direction="column">
            <Tooltip title={t("/editor.sideBar.move_up")}>
              <span>
                <IconButton
                  color="primary"
                  size="small"
                  disabled={index === 0}
                  onClick={() => changeRow(id, index, "asc")}
                >
                  <KeyboardArrowUp />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title={t("/editor.sideBar.move_down")}>
              <span>
                <IconButton
                  color="primary"
                  size="small"
                  disabled={
                    isChild
                      ? index >= children.length - 1
                      : index >= placeholders.length - 1
                  }
                  onClick={() => changeRow(id, index, "desc")}
                >
                  <KeyboardArrowDown />
                </IconButton>
              </span>
            </Tooltip>
          </Stack>
        </Box>
      </Stack>
    </Stack>
  );
};

const TargetSubTitle = ({ id, type }) => {
  const { data } = useWiviContent({ id, type });

  const title = data?.i18n?.value?.text ?? data?.title?.[0]?.value?.text;

  return data ? (
    <Tooltip title={title}>
      <Stack width={120} direction="row" alignItems={"center"} spacing={0.3}>
        <GpsFixed color="primary" sx={{ fontSize: 12 }} />
        <Typography
          whiteSpace={"nowrap"}
          overflow={"hidden"}
          textOverflow="ellipsis"
          variant="caption"
        >
          {title}
        </Typography>
      </Stack>
    </Tooltip>
  ) : null;
};

const PlaceholderTitle = ({ target, i18n, title }) => {
  const { title: resolvedTitle } = useWiviContent({ ...target, i18n, title });
  const { t } = useTranslation();

  return (
    <Typography
      whiteSpace={"nowrap"}
      textOverflow="ellipsis"
      maxWidth={130}
      overflow="hidden"
    >
      {resolvedTitle ?? t("/editor.sideBar.default_ph_title")}
    </Typography>
  );
};
