import { Box } from "@mui/material";
import {
  AdaptiveDpr,
  Environment,
  Lightformer,
  Preload,
} from "@react-three/drei";
import { Canvas } from "@react-three/fiber";
import { Controls } from "Components/Threejs/Map";
import React, { Suspense, useEffect } from "react";
import { useActualPlaceholder, useMap } from "Utils/hooks";
import { Bounds } from "Utils/hooks/bounds";

import Loading from "../Loading";

const MapCanvas = ({ controlProps, children }) => {
  const { configuration } = useMap();
  return (
    <Box
      zIndex={1}
      height={"100%"}
      width="100%"
      position="relative"
      sx={{ bgcolor: configuration?.backgroundColor }}
    >
      <Canvas
        mode="concurrent"
        flat={true}
        dpr={[1, 3]}
        frameloop="demand"
        // linear
        // legacy={true}
        onCreated={({ gl, scene }) => {
          // gl.setClearColor(configuration?.backgroundColor ?? "#dadfe1");
          gl.getContext().canvas.addEventListener(
            "webglcontextlost",
            function (event) {
              event.preventDefault();
              setTimeout(() => gl.forceContextRestore(), 1);
            },
            false
          );
          gl.getContext().canvas.addEventListener(
            "webglcontextrestored",
            function (event) {
              console.log("handle webglcontextrestored");
            },
            false
          );
        }}
        style={{ touchAction: "none" }}
      >
        <Suspense
          fallback={
            <Suspense fallback={null}>
              <Loading scale={[1, 1, 1]} />
            </Suspense>
          }
        >
          <Environment>
            <Lightformer
              form="rect" // circle | ring | rect (optional, default = rect)
              intensity={1} // power level (optional = 1)
              color="white" // (optional = white)
              position={[0, 20, 0]}
              scale={[100, 100]} // Scale it any way you prefer (optional = [1, 1])
              target={[0, 0, 0]} // Target position (optional = undefined)
            />
          </Environment>

          <Controls.Orbital {...controlProps} />
          <Bounds
            fixedOrientation={true}
            // margin is set conditionally in the hook file
            observe
            damping={12}
          >
            {children}
          </Bounds>

          <AdaptiveDpr pixelated />
        </Suspense>
      </Canvas>
    </Box>
  );
};

export default MapCanvas;
