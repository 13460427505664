import { Box, Typography, Collapse, IconButton } from "@mui/material";
import { Cancel, ExpandLess, ExpandMore, Save } from "@mui/icons-material";
import { useSpring, animated } from "@react-spring/web";
import React, { useState } from "react";
import { useActualLayer, useActualPlaceholder, useMap } from "Utils/hooks";
import GeoPosConfig from "./GeoPosConfig";
import MarkerConfig from "./MarkerConfig";
import TargetConfig from "./TargetConfig";
import { useTranslation } from "react-i18next";
const AnimBox = animated(Box);

const GUI = () => {
  const [showMarkerConfig, toggleMarkerConfig] = useState(true);
  const [showTargetConfig, toggleTargetConfig] = useState(false);
  const [showGeoPosConfig, toggleGeoPosConfig] = useState(false);
  const { isGeolocation } = useActualLayer();
  const { isLeaflet } = useMap();
  const { data, set } = useActualPlaceholder();
  const { t } = useTranslation();

  const transi = useSpring({
    from: { right: "-50vw" },
    right: data ? "0vw" : "-50vw",
  });

  const handleClose = () => {
    set(null);
  };

  return (
    <AnimBox
      minWidth="20vw"
      maxHeight="70vh"
      mt={3}
      height="100%"
      p={3}
      zIndex={30}
      borderRadius={3}
      display="flex"
      flexDirection="column"
      position="fixed"
      style={{ ...transi, background: "white" }}
    >
      <Box
        borderRadius="200px"
        sx={{
          bgcolor: "white",
          border: "1px solid",
          borderColor: "primary.main",
        }}
        zIndex={4}
        position="absolute"
        top={-8}
        left={-8}
      >
        <IconButton size="medium" color="primary" onClick={handleClose}>
          <Save />
        </IconButton>
      </Box>
      <Box mx={2} style={{ overflow: "scroll" }}>
        <Box id="markerConfig" display="flex" alignItems="center">
          <IconButton
            color="primary"
            onClick={() => toggleMarkerConfig((v) => !v)}
            size="large"
          >
            {showMarkerConfig ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
          <Typography color="primary" style={{ fontWeight: "bold" }}>
            {t("/editor.gui.marker_config")}
          </Typography>
        </Box>
        <Collapse in={showMarkerConfig}>
          <MarkerConfig data={data} />
        </Collapse>
        <Box id="targetConfig" display="flex" alignItems="center">
          <IconButton
            color="primary"
            onClick={() => toggleTargetConfig((v) => !v)}
            size="large"
          >
            {showTargetConfig ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
          <Typography color="primary" style={{ fontWeight: "bold" }}>
            {t("/editor.gui.marker_target")}
          </Typography>
        </Box>
        <Collapse in={showTargetConfig}>
          <TargetConfig data={data} />
        </Collapse>
        {isGeolocation ||
          (isLeaflet && (
            <>
              {" "}
              <Box id="geoPositionConfig" display="flex" alignItems="center">
                <IconButton
                  color="primary"
                  onClick={() => toggleGeoPosConfig((v) => !v)}
                  size="large"
                >
                  {showGeoPosConfig ? <ExpandLess /> : <ExpandMore />}
                </IconButton>
                <Typography color="primary" style={{ fontWeight: "bold" }}>
                  {t("/editor.gui.geolocation")}
                </Typography>
              </Box>
              <Collapse in={showGeoPosConfig}>
                <GeoPosConfig data={data} />
              </Collapse>
            </>
          ))}
      </Box>
    </AnimBox>
  );
};

export default GUI;
