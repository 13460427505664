import React from "react";
import makeStyles from '@mui/styles/makeStyles';
import Backdrop from "@mui/material/Backdrop";
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import SpotLightIcon from "@mui/icons-material/Highlight";
import DirectIcon from "@mui/icons-material/PlayForWork";
import FlareIcon from "@mui/icons-material/Flare";
import { Typography } from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import { useStore } from "Store";

const useStyles = makeStyles((theme) => ({
  tooltip: {
    display: "flex",
    flexDirection: "column",
    height: "90px",
    marginRight: "8px",
    justifyContent: "center",
    alignItems: "center",
  },
  label: {
    position: "static",
  },
  speedDial: {
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
}));

const actions = [
  { icon: <SpotLightIcon />, name: "Spot", type: "point" },
  { icon: <DirectIcon />, name: "Directionnelle", type: "directional" },
  { icon: <FlareIcon />, name: "Globale", type: "ambient" },
];

export default function AddLightButton() {
  const tempData = useStore((s) => s.tempData);
  const setTempData = useStore((s) => s.setTempData);

  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const createLight = (type, name) => {
    const data = tempData?.lights ?? [];
    setTempData({
      lights: [
        ...data,
        {
          color: "white",
          position: type !== "ambient" && [0, 5, 0],
          id: uuidv4(),
          intensity: 0.5,
          type: type,
          name: name,
        },
      ],
    });
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <Backdrop open={open} />
      <Typography variant="button" style={{ color: open && "white" }}>
        Ajouter une lumière
      </Typography>
      <SpeedDial
        ariaLabel="Ajouter une lumière"
        direction="right"
        icon={<SpeedDialIcon />}
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}
      >
        {actions.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            tooltipOpen
            tooltipPlacement="bottom-end"
            classes={{
              staticTooltip: classes.tooltip,
              staticTooltipLabel: classes.label,
            }}
            onClick={() => createLight(action.type, action.name)}
          />
        ))}
      </SpeedDial>
    </div>
  );
}
