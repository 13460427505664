import { Environment } from "@react-three/drei";
import Layer from "Components/Threejs/3DComponents/Map/2D/Layer2D";
import MapCanvas from "Components/Threejs/3DComponents/Map/MapCanvas";
import PinModel from "Components/Threejs/3DComponents/Placeholders/3D/models/Pin";
import React, { Suspense, useRef } from "react";
import { useGeoPositionMap } from "Utils/hooks";

const GeoPositionMap = () => {
  const { layer, addPoint, geoPoints } = useGeoPositionMap();

  return (
    <MapCanvas controlProps={{ freeControls: true }}>
      <Suspense fallback={null}>
        <axesHelper args={[5]} />

        <Layer gpsMod data={layer} onDoubleClick={({ point }) => addPoint(point)} />
        {geoPoints?.map((p, i) => (
          <Point data={p} index={i} key={i} />
        ))}
      </Suspense>
    </MapCanvas>
  );
};
//Vector3 {x: 3.076913219138827, y: -4.119092567837767, z: -6.8212102632969615e-15}

export default GeoPositionMap;

const Point = ({ data, index }) => {
  const { deletePoint } = useGeoPositionMap();
  const ref = useRef();
  const handleClick = (e) => {
    e.stopPropagation();
    deletePoint(index);
  };
  const { x, z } = data?.position;

  return (
    <>
      <PinModel
        ref={ref}
        onClick={handleClick}
        castShadow
        color={data?.color}
        scale={0.7}
        position={[x, 0, z]}
      />
      <spotLight
        position={[x, 2, z]}
        intensity={0.2}
        penumbra={0.2}
        target={ref.current}
      />
    </>
  );
};
