import { Tab, Tabs } from "@mui/material";
import { Box } from "@mui/system";

import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import LayersPanel from "./layersPanel";
import SettingsPanel from "./SettingsPanel";

const ConfigPanel = () => {
  const { t } = useTranslation();

  const [tab, setTab] = useState("layers");

  return (
    <Box
      mt={4}
      boxShadow={3}
      width="100%"
      p={3}
      overflow="auto"
      height={"100%"}
    >
      <Tabs
        sx={{ mb: 3 }}
        variant="fullWidth"
        indicatorColor="primary"
        textColor="primary"
        value={tab}
        onChange={(e, value) => setTab(value)}
      >
        <Tab
          label={t("/leafletconfig.layers.layers")}
          value="layers"
          color="primary"
        />
        <Tab label={t("settings")} value="settings" color="primary" />
      </Tabs>
      {tab === "layers" ? <LayersPanel /> : <SettingsPanel />}
    </Box>
  );
};

export default ConfigPanel;
