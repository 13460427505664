import { useState, useEffect, useRef } from "react";
import { useSpring, animated } from "@react-spring/three";
import Basic from "./models/Basic";
import Pin from "./models/Pin";
import Flag from "./models/Flag";
import Tree from "./models/Tree";
import Chroma from "chroma-js";
import { useActualLayer, useActualPlaceholder } from "Utils/hooks";
import { useDisplayedPlaceholders } from "Utils/hooks/placeholders";
import { useBounds } from "Utils/hooks/bounds";
import { meshBounds } from "@react-three/drei";
import { useNavigate } from "react-router-dom";

//Render de chaque placeholder animé
const Placeholder = ({ index, data, pos, opacity }) => {
  const { marker, id, children, target } = data ?? {};
  const markerRef = useRef();

  const { set, activePlaceholderId } = useActualPlaceholder();
  const { setFilter, clearFilters, filters } = useDisplayedPlaceholders();
  const [isActive, setActive] = useState(false);
  const { set: setLayer } = useActualLayer();
  const bounds = useBounds();

  const disableFocus = filters && children?.length;

  useEffect(() => {
    !children?.length && setActive(activePlaceholderId === id);
    if (activePlaceholderId === id && children?.length >= 1) {
      setFilter({ placeholderId: id });
      set(children[0]?.id);
    }

    activePlaceholderId === id &&
      !disableFocus &&
      bounds.refresh(markerRef.current).clip().fit();
  }, [activePlaceholderId, id]);

  const handleMarkerClick = () => {
    isActive ? set(null) : set(id);
    if(target.type === 'mapLink'){
      window.location.replace('/outdoor')
    }
    if (children?.length >= 1) {
      isActive ? clearFilters() : setFilter({ placeholderId: id });
    } else if (target?.type === "layerLink") {
      clearFilters();
      return setLayer(target?.id);
    }
  };

  const poiColor =
    data.type === "collection" ? window.__CONFIG__?.primary?.main : "#E0C03D";
  const placeholderColor = marker?.color ?? poiColor;
  const markerScale = marker?.scale ?? 1;

  const props = useSpring({
    scale: isActive
      ? [1.5 * markerScale, 1.5 * markerScale, 1.5 * markerScale]
      : [markerScale, markerScale, markerScale],
    config: { mass: 1, tension: 800, friction: 20 },
    // useNativeDriver: true,
  });

  const AnimatedPlaceholder =
    marker?.model === "pin"
      ? animated(Pin)
      : marker?.model === "flag"
      ? animated(Flag)
      : marker?.model === "tree"
      ? animated(Tree)
      : animated(Basic);

  useEffect(() => {
    children && setActive(filters?.uuid === id);
  }, [filters]);

  return (
    <AnimatedPlaceholder
      ref={markerRef}
      isActive={isActive}
      rotation={props?.rotation ?? 0}
      color={
        isActive
          ? Chroma(placeholderColor).brighten(0.7).css()
          : placeholderColor
      }
      scale={props.scale}
      opacity={opacity}
      position={pos}
      onPointerDown={opacity > 0 && handleMarkerClick}
      castShadow
    />
  );
};

export default Placeholder;
