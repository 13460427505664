import React, { Suspense } from "react";
import { useFrame } from "@react-three/fiber";
import Map2D from "Components/Threejs/3DComponents/Map/2D/Model2D";
import { Box } from "@mui/material";
import LevelSelector from "Components/Threejs/Map/LevelSelector";
import { useLayers, useMap } from "Utils/hooks";
import GeoMarker from "Components/Threejs/3DComponents/Placeholders/HTML/geoMarker";
import MapCanvas from "../3DComponents/Map/MapCanvas";
import LayerSelector from "Components/Global/LayerSelector";

const PreviewMap = () => {
  const { layers } = useLayers();

  return (
    <Box
      height="500px"
      width="100%"
      mt={4}
      position="sticky"
      top={32}
      display="flex"
    >
      <Box position='absolute' zIndex={12} right={8} top={8}>
      <LayerSelector/>
      </Box>
      <MapCanvas
        controlProps={{
          previewMap: true,
          freeControls: true,
        }}
      >
        {layers?.length && (
          <Suspense fallback={null}>
            <CanvasContent />
          </Suspense>
        )}
      </MapCanvas>
      {/* <LevelSelector configMod /> */}
    </Box>
  );
};
export default PreviewMap;

const CanvasContent = () => {
  const { configuration } = useMap();

  return (
    <>
      {configuration?.geolocation && <GeoMarker position={[0, 0, 0]} />}
      <axesHelper args={[5]} />
      <Map2D />
    </>
  );
};
