import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import Router from "Config/Router";
import * as serviceWorker from "./serviceWorker";
import { DOMAIN } from "Config/constantes";
import "./index.css";
import "@fontsource/quattrocento";
import "@fontsource/roboto";
import ApolloProvider from "Config/API/apollo";
import "@fontsource/material-icons"; 
import "./Config/i18n";
import "react-toastify/dist/ReactToastify.css";


const { cacheStrategy } = window.__CONFIG__ ?? {};
// const withSW = true // ["tatihou", "dev"].includes(projectId);
const withSW = cacheStrategy && cacheStrategy !== 'no-cache'
          

const Index = () => {
  const [ready, setReady] = useState(false);

  useEffect(() => {
    init();
  }, []);

  // permet le passage d'un WIVI à la MAP
  const init = async () => {
    process?.env.NODE_ENV !== "development" &&
      !window.location.host.includes("localhost") &&
      (document.domain = DOMAIN);
  };

  return (
    <ApolloProvider>
      <Router />
    </ApolloProvider>
  );
};

withSW ? serviceWorker.register() : 
serviceWorker.unregister();
const root = document.getElementById("root");

ReactDOM.createRoot(root).render(<Index />);

//ReactDOM.render(<Index />, document.getElementById("root"));
