import { Map } from "@mui/icons-material";
import { Box, Button, Stack, Typography } from "@mui/material";
import { Loader } from "Components/Global";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, Navigate } from "react-router-dom";
import { useMaps } from "Utils/hooks/map";

const Test = ({ loading }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isLeafletMap, isThreejsMap, leafletMap, threejsMap, allMaps } =
    useMaps();

  return (
    <Box
      height="100%"
      width="100%"
      display="flex"
      justifyContent={"center"}
      alignItems={"center"}
      p={3}
    >
      {loading || !allMaps?.length ? (
        <Loader />
      ) : isThreejsMap && isLeafletMap ? (
        <Stack direction="column" spacing={2} alignItems="center">
          <Typography
            variant="h4"
            color="primary"
            fontFamily={"Quattrocento"}
            fontWeight="bold"
          >
            <Map /> {t("/visit.title")}
          </Typography>
          <Typography variant="body1" color="textSecondary">
            {t("/visit.description")}
          </Typography>
          <Stack
            direction="row"
            py={4}
            flexWrap="wrap"
            justifyContent={"center"}
          >
            <Box
              p={4}
              width="100%"
              m={2}
              borderRadius={2}
              boxShadow={2}
              maxWidth={250}
            >
              <Typography variant="h5" color="primary" mb={2}>
                {leafletMap?.i18n?.title?.value?.text ?? t("/visit.leaflet")}
              </Typography>
              {/* <Typography my={2} variant="body1" color="textSecondary">
                {t("/visit.leaflet.description")}
              </Typography> */}
              <Button
                variant="contained"
                color="primary"
                onClick={() => navigate("/outdoor")}
              >
                {t("/visit.button")}
              </Button>
            </Box>
            <Box
              m={2}
              maxWidth={250}
              width="100%"
              p={4}
              borderRadius={2}
              boxShadow={2}
            >
              <Typography variant="h5" color="primary" mb={2}>
                {threejsMap?.i18n?.title?.value?.text ?? t("/visit.threejs")}
              </Typography>
              {/* <Typography my={2} variant="body1" color="textSecondary">
                {t("/visit.threejs.description")}
              </Typography> */}
              <Button
                variant="contained"
                color="primary"
                onClick={() => navigate("/indoor")}
              >
                {t("/visit.button")}
              </Button>
            </Box>
          </Stack>
        </Stack>
      ) : (
        <Navigate
          to={isLeafletMap ? "/outdoor" : isThreejsMap ? "/indoor" : "/error"}
          replace
        />
      )}
    </Box>
  );
};

export default Test;
