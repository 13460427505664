import { useState, useEffect, useRef } from "react";
import Basic from "./models/Basic";
import Pin from "./models/Pin";
import Flag from "./models/Flag";
import Tree from "./models/Tree";
import { useSpring, animated } from "@react-spring/three";
import { useActualPlaceholder } from "Utils/hooks";
import { useStore } from "Store";
import { MathUtils } from "three";
import { Bounds } from "@react-three/drei";
import { useBounds } from "Utils/hooks/bounds";
import { useThree } from "@react-three/fiber";
import EditorLabel from "../EditorLabel";
import { ApiTwoTone } from "@mui/icons-material";

const EditablePlaceHolder = (
  {
    target,
    resetTarget,
    getMapPosition,
    data,
    editorMap,
    setMapPosition,
    ...props
  },
  ref
) => {
  const { position, marker, parentId } = data ?? {};
  const phRef = useRef();
  const setStoreValue = useStore((s) => s.setValue);
  const { x, y, z } = position;

  const [active, setActive] = useState(false);
  const [movable, setMovable] = useState(false);
  const { set, activePlaceholderId, setPosition, setScale } =
    useActualPlaceholder(parentId);
  const { controls } = useThree();
  const bounds = useBounds();

  const [{ xyz }, api] = useSpring(
    () => ({
      to: { xyz: [x, 0, z] },
      from: { xyz: [x, y, z] },
      config: { mass: 0.5, tension: 120, friction: 15 },
      delay: 200,
    }),
    [position]
  );

  const markerScale = marker?.scale ? marker?.scale : 1;
  const { scale } = useSpring({
    scale: active
      ? [1.5 * markerScale, 1.5 * markerScale, 1.5 * markerScale]
      : [markerScale, markerScale, markerScale],
  });

  useEffect(() => {
    movable && target && api.start({ xyz: [target[0], 0, target[1]] });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [target]);

  useEffect(() => {
    const [x, y, z] = xyz.get();
    setStoreValue("editorMapPan", !movable);
    !movable && y === 0 && setPosition({ x, y, z });
  }, [movable]);

  useEffect(() => {
    setActive(activePlaceholderId === data?.id);
  }, [activePlaceholderId]);

  useEffect(() => {
    // set(active ? data?.id : null);
    setMovable(false);
    getMapPosition(active);
    active && bounds.refresh(phRef.current).clip().fit();

    // !active && console.log('phRef.current:', phRef.current)
  }, [active]);

  const handleClick = (e) => {
    e.stopPropagation();
    set(active ? null : data?.id);
  };

  const AnimatedPlaceholder =
    marker?.model === "pin"
      ? animated(Pin)
      : marker?.model === "flag"
      ? animated(Flag)
      : marker?.model === "tree"
      ? animated(Tree)
      : animated(Basic);

 const handleActivation = e => {
    e.stopPropagation();
    active && setMovable((m) => !m);
 }
 
  return (
    <>
      <AnimatedPlaceholder
        ref={phRef}
        position={xyz}
        // onWheel={handleWeel}
        // onPointerOver={() =>  controls.enableZoom = false}
        // onPointerOut={() =>  controls.enableZoom = true}
        // position={[x,y,z]}
        // onPointerMissed={handleMiss}
        onPointerDown={handleActivation}
        // onPointerUp={() => active && setMovable(false)}
        onDoubleClick={handleClick}
        color={movable ? "orange" : marker?.color}
        scale={scale}
        rotation={MathUtils.degToRad(Math.floor(marker?.rotation ?? 0))}
        {...props}
      />
      {active && <EditorLabel position={xyz} active={movable} onClick={handleActivation} />}
    </>
  );
};

export default EditablePlaceHolder;
