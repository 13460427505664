import React from "react";

import "leaflet/dist/leaflet.css";

import CreateLayer from "./createLayer";

import LeafletWrapper from "../Map/leafletWrapper";
import { Box } from "@mui/system";
import LeafletPlaceholders from "../Map/leafletPlaceholders";
import LayerSelector from "Components/Global/LayerSelector";

const LeafletAdminMap = ({createOverlay, editMode}) => (
  <Box width="100%" height="100%" position="relative">
    {createOverlay && <CreateLayer />}
    <Box zIndex={3} position="absolute" top={16} right={16}>
      <LayerSelector />
    </Box>
    <LeafletWrapper>
      <LeafletPlaceholders editMode={editMode} />
    </LeafletWrapper>
  </Box>
);

export default LeafletAdminMap;
