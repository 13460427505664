import { Icon } from "@mui/material";
import { Box } from "@mui/system";
import chroma from "chroma-js";
import React from "react";

const LeafletMarker = ({ color, iconColor, icon, draggable, scale, isActive }) => {
  return (
    <Box
      style={{
        backgroundColor: color ?? "white",
        borderColor: draggable ? "orange" : isActive ? chroma(iconColor).brighten(1).css() :  "transparent",
        borderWidth: draggable ?  "5px" : isActive ? "3px" :  "0px",
        borderStyle: "solid",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: 3,
        width: 40 * scale ?? 1,
        height: 40 * scale ?? 1,
        borderRadius: "50%",
        boxShadow: 3,
        position: "fixed",
        left: -20 * scale ?? 1,
        top: -20 * scale ?? 1,
      }}
    >
      {icon?.type === "svg" ? (
        <Box
          style={{
            backgroundColor: iconColor ?? "#000",
            WebkitMask: `url(/icons/${icon?.file}) no-repeat center`,
          }}
        >
          <img
            width={35 * scale ?? 1}
            height={35 * scale ?? 1}
            src={`/icons/${icon?.file}`}
            alt={icon?.name}
            draggable={false}
            style={{ opacity: 0 }}
          />
        </Box>
      ) : icon?.type ? (
        <img
          width={35 * scale ?? 1}
          height={35 * scale ?? 1}
          src={`/icons/${icon?.file}`}
          alt={icon?.name}
          draggable={false}
        />
      ) : (
        <Icon
          style={{
            color: iconColor ?? "white",
            fontSize: `${2 * scale ?? 1}em`,
          }}
        >
          {icon?.value || "home"}
        </Icon>
      )}
      {/* <Icon style={{ color: iconColor ?? "red" }}>{icon.value}</Icon> */}
    </Box>
  );
};

export default LeafletMarker;
