import { ArrowBack, Help, Home } from "@mui/icons-material";
import {
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { uploadConfigChanges, uploadEditorChanges } from "Config/API/methods";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";

const TopBar = () => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const configPaths = ["/configuration", "/leafletconfig"];
  const isConfig = configPaths.includes(pathname);
  const navigate = useNavigate();
  const [search] = useSearchParams();

  const handleClose = () => {
    navigate(
      `${
        pathname === "/leafletconfig" ? "/leafleteditor" : "/editor"
      }?${search?.toString()}`
    );
  };

  const refresh = () => {
    toast.success(t("saving.success"));
    isConfig
      ? document.location.replace(
          pathname === "/leafletconfig" ? "/leafleteditor" : "/editor"
        )
      : document.location.reload();
  };

  const showError = () => {
    toast.error(t("saving.error"), {
      closeButton: () => (
        <Button color="error" size="small" onClick={handleSave}>
          {t("saving.retry")}
        </Button>
      ),
    });
  };

  const handleSave = async (e) => {
    setLoading(true);
    try {
      isConfig ? await uploadConfigChanges() : await uploadEditorChanges();
      refresh();
    } catch (error) {
      console.log("error:", error);
      showError();
    }
    setLoading(false);
  };

  return (
    <Box py={1} px={3} boxShadow={2}>
      <Stack
        direction="row"
        justifyContent={"space-between"}
        alignItems="center"
      >
        <Stack spacing={2} direction="row" alignItems={"center"}>
          {isConfig ? (
            <IconButton color="primary" onClick={handleClose}>
              <ArrowBack />
            </IconButton>
          ) : (
            <IconButton color="primary" onClick={() => navigate("/admin")}>
              <Home />
            </IconButton>
          )}
          <Typography variant="h6">{t(`${pathname}.topBar.title`)}</Typography>
          <Tooltip title={t("help")}>
            <IconButton
              size="small"
              onClick={() =>
                window.open("https://doc.wivi.io/documentation/plan")
              }
            >
              <Help fontSize="small" />
            </IconButton>
          </Tooltip>
        </Stack>
        <Button
          disabled={loading}
          color="primary"
          variant="contained"
          onClick={handleSave}
          startIcon={
            loading ? <CircularProgress size={12} color="primary" /> : null
          }
        >
          {loading
            ? t("saving.loading")
            : t(`saving.${isConfig ? "save_and_close" : "save"}`)}
        </Button>
      </Stack>
    </Box>
  );
};

export default TopBar;
