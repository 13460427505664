import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { PinDrop, Star } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { CircularKnob } from "Components/Global";
import { debounce } from "lodash";
import React, { useState } from "react";
import { useStore } from "Store";
import { useActualLayer, useLayers, useMap } from "Utils/hooks";
import { useTranslation } from "react-i18next";
import { Stack, Tooltip } from "@mui/material";
import TitleInput from "Components/Global/TitleInput";

const LayerListItem = ({
  order,
  item,
  id,
  items,
  originalIndex: i,
  save,
  togglePropagation,
  setStopPropagation,
  ...props
}) => {
  const { configuration } = useMap();
  const { set: setActualLayer, id: actualLayerId } = useActualLayer();
  const [isEditing, setEditing] = useState(false);
  const setStoreValue = useStore((s) => s.setValue);
  const { layer, remove, setRotation, setScale, setName, patchTitle } =
    useLayers(id);
  const [value, setValue] = useState(layer?.name);
  const { t, i18n } = useTranslation();

  const handleChange = (ev) => {
    setValue(ev.target.value);
  };

  const handleGeoModal = () => {
    setActualLayer(layer.id);
    setStoreValue("geoModal", layer);
  };

  const handleDefaultLayer = () => {
    //TODO: ajouter une cle default layer
  };
  const saveName = (e) => {
    e.stopPropagation();
    isEditing && setName(value);
    setEditing((pv) => !pv);
  };

  const cancelEdit = (e) => {
    e.stopPropagation();
    setEditing(false);
    isEditing ? setValue(layer?.title) : remove();
  };

  const showItem = (e) => {
    e.stopPropagation();
    setActualLayer(layer?.id);
  };

  const changeScale = (v) => {
    setScale(v / 1000);
  };
  const changeRotation = (v) => {
    setRotation(v);
  };

  const debRot = debounce((v) => changeRotation(v), 30);
  const debScale = debounce((v) => changeScale(v), 30);
  
  return (
    // <Box>
    <Stack
      id="layerListitems"
      alignItems="center"
      direction="row"
      justifyContent={"space-between"}
      width="100%"
    >
      <Stack direction="row" alignItems="center" spacing={1}>
        <Box display="flex" alignItems="center" ml={2}>
          <DragIndicatorIcon color="primary" style={{ marginRight: "8px" }} />
          {/* <Box mr={1}> */}
          {/* <IconButton size="small" onClick={handleDefaultLayer}>
                <Star />
              </IconButton> */}
          {/* </Box> */}
          {isEditing ? (
            <TitleInput
              onMouseEnter={() => setStopPropagation(true)}
              onMouseLeave={() => setStopPropagation(false)}
              placeholder={t("/configuration.layers.level_name")}
              patchFunction={patchTitle}
              values={layer?.title}
              width="100%"
            />
          ) : (
            <Typography
              color={id === actualLayerId ? "primary" : "default"}
              fontWeight={id === actualLayerId && "bold"}
              width="100%"
              mx={2}
              variant="body1"
            >
              {layer?.title?.filter?.(
                (e) => e.language === i18n.resolvedLanguage
              )[0]?.value.text ?? layer?.data?.index}
            </Typography>
          )}
        </Box>
        <Box
          display="flex"
          alignItems="center"
          onMouseEnter={() => setStopPropagation(true)}
          onMouseLeave={() => setStopPropagation(false)}
        >
          <CircularKnob
            title={t("/configuration.layers.rotation")}
            max={360}
            unit={"°"}
            defaultValue={layer?.rotation}
            onChange={debRot}
          />
          <CircularKnob
            title={t("/configuration.layers.scale")}
            defaultValue={layer?.scale * 1000}
            unit="%"
            max={250}
            onChange={debScale}
          />
        </Box>
      </Stack>
      <Box
        display="flex"
        alignItems="center"
        mr={2}
        onMouseEnter={() => setStopPropagation(true)}
        onMouseLeave={() => setStopPropagation(false)}
      >
        <Tooltip title={t("/configuration.layers.preview")}>
          <IconButton onMouseDown={showItem} size="small">
            <VisibilityIcon />
          </IconButton>
        </Tooltip>
        {configuration?.geolocation && (
          <Tooltip title={t("/configuration.layers.geolocation")}>
            <IconButton onMouseDown={handleGeoModal} size="small">
              <PinDrop />
            </IconButton>
          </Tooltip>
        )}
        <Tooltip title={t("/configuration.layers.edit_name")}>
          <IconButton onMouseDown={saveName} size="small">
            {isEditing ? <SaveIcon /> : <EditIcon />}
          </IconButton>
        </Tooltip>
        <Tooltip title={t("/configuration.layers.delete")}>
          <IconButton onMouseDown={cancelEdit} size="small">
            {isEditing ? <CloseIcon /> : <DeleteIcon />}
          </IconButton>
        </Tooltip>
      </Box>
    </Stack>
    // </Box>
  );
};

export default LayerListItem;
