import React, { useState, useRef, useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import Knob, { SkinWrap } from "react-dial-knob";
import { Typography, Box } from "@mui/material";
import { animated, useSpring } from "@react-spring/web";

const MyKnobSkin = (props) => {
  const {
    palette: { primary },
  } = useTheme();
  const [angle, setAngle] = useState(0);
  const [isActive, setIsActive] = useState(false);
  const activeColor = primary.light || "#b56a7a";
  const defaultColor = primary.main || "#100";
  const bgrColor = isActive ? activeColor : defaultColor;
  // const [value, setValue] = useState(props.defaultValue)

  const animation = useSpring({
    transform: `translate(0,0) rotate(${angle} 50 50)`,
    config: {
      mass: 0.5,
      tension: 120,
      friction: 10,
    },
  });
  const AnimKnob = animated(Knob);
  const handleInteractionChange = (isUsed) => {
    setIsActive(isUsed);
    !isUsed && props.onInteractionChange(props.value);
  };

  return (
    <SkinWrap style={props.style}>
      <AnimKnob
        diameter={props.diameter}
        value={props.value}
        min={props.min}
        max={props.max}
        step={props.step}
        spaceMaxFromZero={props.spaceMaxFromZero}
        ariaLabelledBy={props.ariaLabelledBy}
        ariaValueText={props.ariaValueText}
        knobStyle={{ cursor: "ns-resize", ...props.knobStyle }}
        onAngleChange={(a) => setAngle(a)}
        onInteractionChange={handleInteractionChange}
        onValueChange={props.onValueChange}
      >
        <animated.svg
          viewBox="0 0 100 100"
          style={{ transformOrigin: "center" }}
        >
          <animated.path
            transform={animation.transform}
            fill={bgrColor}
            d="M50 4A46 46 0 004 50a46 46 0 0046 46 46 46 0 0046-46A46 46 0 0050 4zm0 2.141a4.276 4.276 0 014.276 4.277A4.276 4.276 0 0150 14.694a4.276 4.276 0 01-4.276-4.276A4.276 4.276 0 0150 6.141z"
          />
          <text
            x="50%"
            y="50%"
            fontFamily="Avenir"
            fontSize="20"
            textAnchor="middle"
            stroke="#fff"
            strokeWidth="2px"
            dy=".3em"
          >
            {props.value} {props.unit && props.unit}
          </text>
        </animated.svg>
      </AnimKnob>
      {props.children}
    </SkinWrap>
  );
};

const KnobComp = ({
  defaultValue = 0,
  unit,
  max = 100,
  size = 56,
  min = 0,
  step = 1,
  title,
  onChange = () => {},
}) => {
  const [value, setValue] = useState(defaultValue);
  const ref = useRef();

  useEffect(() => {
    let item = ref.current;
    const cancelWheel = (event) =>{ event.preventDefault(); }
    
    item.addEventListener("mousewheel", cancelWheel, { passive: false});
 
    return () => {
      item.removeEventListener("mousewheel", cancelWheel);

    };
  }, [ref]);

  const handleValue = (value) => {
    value && setValue(value);
  };

  const handleWheel = ({ deltaY }) => {
    let timeout;
    timeout && clearTimeout(timeout);

    if (deltaY < 0 && value < max) {
      setValue((pv) => pv + step);
      onChange(value)
    } else if (value >= step) {
      setValue((pv) => pv - step);
      onChange(value)
    }
   
  };

  return (
    <Box
      m={1}
      ref={ref}
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      onWheel={(e)=> {handleWheel(e)}}
    //   onClick={(e)=> {e.preventDefault(); e.stopPropagation()}}
    //   onTouchStart={e => {e.preventDefault(); e.stopPropagation();}}
    //   onMouseDown={(ev) => {
    //     ev.preventDefault();
    //     ev.stopPropagation();
    //   }}
      zIndex={2}
    >
      <MyKnobSkin
        diameter={size}
        min={min}
        max={max}
        step={step}
        value={value}
        onValueChange={handleValue}
        unit={unit}
        defaultValue={defaultValue}
        onInteractionChange={(e) => onChange(e)}
        knobStyle={{ transformOrigin: "center" }}
        style={{
          pointer: "ns-resize",
          verticalAlign: "middle",
          overflow: "visible",
        }}
      />
      {title && (
        <Typography
          variant="body2"
          color="primary"
          style={{ textAlign: "center" }}
        >
          {title}
        </Typography>
      )}
    </Box>
  );
};

export default KnobComp;
