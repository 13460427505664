import React, { useState } from 'react'
import Lottie from 'lottie-react-web';
import logoData from './animations/logo.json'

import { Box } from '@mui/material';

const LottieBox = ({ file, animWidth, pauseTime, isLoop, ...props }) => {
    
    const [isPaused, setPaused] = useState(false)

    const defaultOptions = {
        loop: isLoop ?? false,
        autoplay: true,
        animationData: file,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    return (
        <Box {...props}>
            <Lottie 
             options={defaultOptions}
            width={animWidth ?? 100} isPaused={isPaused} 
            eventListeners={[{
                eventName: 'enterFrame',
                callback: (e) => pauseTime && e.currentTime >= pauseTime && setPaused(true)
            }]} />
        </Box>
    )
}

 const LogoAnim = (props) => {
    return <LottieBox file={logoData} {...props}/>
}


export {LogoAnim}

export default LottieBox