import { Box } from "@mui/material";
import React, { useEffect, useRef } from "react";
import { MapContainer, TileLayer } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import { useActualLayer, useMap } from "Utils/hooks";
import { setValueSelector, useStore } from "Store";
import GeolocationButton from "../Map/geolocationButton";
import tilesUrls from "Config/leafletTilesServers.json";
import LocationMarker from "../Markers/LeafletGeolocationMarker";

const defaultPosition = {
  y: 49.18619985426114,
  x: -0.3655160218477249,
};

const LeafletWrapper = ({ children }) => {
  const setStoreValue = useStore(setValueSelector);
  const setLeafletMapInstance = (v) => setStoreValue("leafletMapInstance", v);
  const { actualLayer } = useActualLayer();
  const { configuration } = useMap();

  const mapRef = useRef();

  useEffect(() => {
    const url = tilesUrls[actualLayer?.data?.tilesStyle];
    url && mapRef?.current?.setUrl?.(url);
  }, [actualLayer?.data?.tilesStyle]);

  return (
    <Box width="100%" height="100%" position="relative" zIndex={1}>
      <GeolocationButton />

      <MapContainer
        style={{
          width: "100%",
          height: "100%",
          zIndex: 1,
          position: "absolute",
          top: 0,
          left: 0,
        }}
        center={[
          actualLayer?.position?.y ?? defaultPosition.y,
          actualLayer?.position?.x ?? defaultPosition.x,
        ]}
        zoom={actualLayer?.data?.zoom ?? 12}
        whenCreated={setLeafletMapInstance}
      >
        <TileLayer
          ref={mapRef}
          //   attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          // url="https://{s}.tile.thunderforest.com/spinal-map/{z}/{x}/{y}.png?apikey=c5a3ca7dd3804d7b85401919282d5001"
          url={tilesUrls[actualLayer?.data?.tilesStyle ?? "light"]}
        />

        {children}
        {configuration?.geolocation && <LocationMarker />}
      </MapContainer>
    </Box>
  );
};

export default LeafletWrapper;
