import React from "react";
import Box from "@mui/material/Box";
import { LightsControls, LayersControls, GlobalSettingsControls } from ".";
import makeStyles from "@mui/styles/makeStyles";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import DomainDisabledIcon from "@mui/icons-material/DomainDisabled";
import { Settings } from "@mui/icons-material";
import { useLayers } from "Utils/hooks";

import { useTranslation } from "react-i18next";
import { Tooltip } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    maxWidth: "100%",
    borderColor: theme.palette.primary.main,
    border: 2,
  },
}));

export default function IconTabs() {
  const classes = useStyles();
  const [value, setValue] = React.useState("layers");
  const { layers } = useLayers();
  const { t } = useTranslation();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box
      my="36px"
      py={3}
      px={5}
      maxWidth="100%"
      width='100%'
      boxShadow={3}
      display="flex"
      flexDirection="column"
      justifyContent="flex-start"
    >
      <Tabs
        centered
        value={value}
        onChange={handleChange}
        variant="fullWidth"
        indicatorColor="primary"
        textColor="primary"
        className={classes.root}
      >
        <Tab
          icon={
            <Tooltip title={t("/configuration.layers.title")}>
              <DomainDisabledIcon />
            </Tooltip>
          }
          aria-label="layers"
          value="layers"
        />

        <Tab
          icon={
            <Tooltip title={t("/configuration.settings.title")}>
              <Settings />
            </Tooltip>
          }
          disabled={!layers}
          aria-label="background"
          value="globalSettings"
        />

        {/* <Tab icon={<EmojiObjectsIcon />} aria-label="lights" value='lights' /> */}
      </Tabs>
      <Box my={5} maxWidth="100%">
        <TabPanel value={value} />
      </Box>
    </Box>
  );
}

function TabPanel(props) {
  const { value } = props;
  switch (value) {
    case "globalSettings":
      return <GlobalSettingsControls />;
    case "lights":
      return <LightsControls />;
    case "layers":
      return <LayersControls />;
    default:
      return null;
  }
}
