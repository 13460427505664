import React, { useEffect } from 'react'
import * as THREE from 'three'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader'
import { ColladaLoader } from 'three/examples/jsm/loaders/ColladaLoader'
import { useLoader, useThree } from '@react-three/fiber'
import Layer from './Layer'

const Model = ({ context: { actualLayer, data, setLayerHeight }, ...props }) => {
  const { filesFormat } = data
  const { gl } = useThree()
  const maxAnisotropy = gl.capabilities.getMaxAnisotropy()

  let isGltf = filesFormat === 'GLTF'
  let models = data?.layers.map(layer =>
    isGltf ? `/GLTF/${layer.file}` : `/DAE/${layer.file}`)
  const collada = useLoader(isGltf ? GLTFLoader : ColladaLoader, models, isGltf && (loader => {
    const dracoLoader = new DRACOLoader()
    dracoLoader.setDecoderPath('/draco-gltf/')
    loader.setDRACOLoader(dracoLoader)
  })
  )


  useEffect(
    () => {
     
      collada.forEach((model, index) => {
      model.scene.traverse(obj => {
        if (obj.isMesh) {
          if (Array.isArray(obj.material)) {
            obj.material.map(mat =>
              mat?.map?.anisotropy && (mat.map.anisotropy = maxAnisotropy, mat.map.magFilter = THREE.NearestFilter, mat.map.minFilter = THREE.LinearMipMapNearestFilter)
            )
          } else {
            obj.material.map && (obj.material.map.anisotropy = maxAnisotropy)
          }
          obj.castShadow = obj.receiveShadow = true;
          obj.material.metalness = 0
        }
      })
    })

  }
  )

  return collada.map(({ scene }, index) => {
    return (
      <Layer scene={scene} index={index} key={index} context={{ actualLayer, data, setLayerHeight  }} {...props} />
    )
  })
}

export default Model