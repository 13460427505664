/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef, useMemo, forwardRef } from "react";
import { meshBounds, useGLTF } from "@react-three/drei";
import { animated } from "@react-spring/three";
import { MeshStandardMaterial } from "three";
import { MeshBasicMaterial } from "three";

const Flag = ({ color, rotation, opacity, ...props }, group) => {
  const { nodes, materials } = useGLTF("/GLTF/flag.gltf");
  const material = useMemo(
    () =>
      new MeshStandardMaterial({
        color: color ?? "#028090",
        roughness: 0.7,
        emissive: "#000",
        emissiveIntensity: 0.5,
        transparent: true,
        opacity: opacity ?? 1,
      }),
    [color, opacity]
  );
  return (
    <animated.group
      ref={group}
      {...props}
      rotation={[-Math.PI / 2, 0, rotation ?? 0]}
      dispose={null}
    >
      <animated.group rotation={[Math.PI, 0, 0]}>
        <animated.mesh
          raycast={meshBounds}
          geometry={nodes.Cube1351.geometry}
          material={material}
        />
        <animated.mesh
          raycast={meshBounds}
          geometry={nodes.Cube1351_1.geometry}
          material={material}
        />
        <animated.mesh
          raycast={meshBounds}
          geometry={nodes.Cube1351_2.geometry}
        >
          <animated.meshStandardMaterial
            color="#A7A7A7"
            opacity={opacity ?? 1}
            transparent
          />
        </animated.mesh>
      </animated.group>
    </animated.group>
  );
};

useGLTF.preload("/GLTF/flag.gltf");

export default forwardRef(Flag);
