import React from "react";
import { PreviewMap, SettingsControls} from "Components/Threejs/Config";
import {TopBar} from 'Components//Global'
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import { ToastContainer } from "react-toastify";

export default function Config() {
  return (
    <div style={{ height: "100%", overflowY: "auto", overflowX: "hidden" }}>
      <ToastContainer />
      <TopBar />
      <Container>
        <Grid container spacing={2} justifyContent="space-around">
          <Grid item xs={12} md={6}>
            <PreviewMap />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <SettingsControls />
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
