import React, { useRef } from "react";
import LayersList from "./LayerList";
import Typography from "@mui/material/Typography";
import UploadButton from "./Upload";
import Box from "@mui/material/Box";
import GeoPositionModal from "./GeoPositionModal";
import { useLayers } from "Utils/hooks";
import { useTranslation } from "react-i18next";

export default function LayersControls() {
  const { layers, setLayers } = useLayers();
  const { t } = useTranslation();
  const ref = useRef(null);
  const itemsSize = 170;

  return (
    <Box position="relative">
      <GeoPositionModal />
      <Typography variant="h6" color="primary">
        {t("/configuration.layers.title")}
      </Typography>
      {layers && (
        <Box
          ref={ref}
          height={layers?.length * (itemsSize + 20)}
          maxWidth="100%"
        >
          <LayersList width={ref} save={setLayers} size={itemsSize} />
        </Box>
      )}
      <UploadButton />
    </Box>
  );
}
