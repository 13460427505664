import React from 'react'
import { ApolloClient, InMemoryCache, ApolloLink,  ApolloProvider  } from '@apollo/client';
import { onError } from "@apollo/client/link/error";
import { createUploadLink } from 'apollo-upload-client'
import { setContext } from "@apollo/client/link/context";
import { GQL_URL} from 'Config/constantes'
import keycloak from 'Config/auth'
import util from 'util'


const httpLink = createUploadLink({
    uri: GQL_URL,
    credentials: 'same-origin'
})

const authLink = setContext(async (_, { headers }) => {
    return {
        headers: {
            ...headers,
            authorization: `Bearer ${keycloak.token ?? ""}`,
        }
    }
})


export const client = new ApolloClient({
    link: ApolloLink.from([
        authLink,
        onError(({ graphQLErrors, networkError }) => {
            if (graphQLErrors)
                graphQLErrors.forEach(({ message, locations, path }) =>
                    console.log(
                        `[GraphQL error]: Message: ${util.inspect(message, false, null, true)}, Location: ${util.inspect(locations, false, null, true)}, Path: ${util.inspect(path, false, null, true)}`,
                    ),
                );
            if (networkError) console.log(`[Network error]: ${networkError}`);
        }),
        httpLink
    ]),
    cache: new InMemoryCache({
        addTypename: false, 
    })
});

const HOC = ({ children }) => {
    return (
        <ApolloProvider client={client}>{children}</ApolloProvider>
    )
}

export default HOC