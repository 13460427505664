import { useQuery } from "@apollo/client";
import { MenuItem, Select, Stack, TextField } from "@mui/material";
import { GET_LANGUAGES } from "Config/API/queries";
import ReactCountryFlag from "react-country-flag";

import React, { useEffect, useState } from "react";
import useDebounce from "Utils/hooks/debounce";
import usePrevious from "Utils/hooks/previous";
import { isEqual } from "lodash";
import { useTranslation } from "react-i18next";
import useWiviContent from "Utils/hooks/wiviContent";

const replacements = {
  cs: "cz",
  zh: "cn",
  da: "dk",
  en: "gb",
  "en-US": "us",
};

const TitleInput = ({
  label,
  values,
  patchFunction,
  callback,
  placeholder,
  markerData,
  ...props
}) => {
  const { i18n } = useTranslation();
  const [selected, setSelected] = useState(i18n?.resolvedLanguage ?? "fr");
  const [text, setText] = useState(
    values?.filter((l) => l?.language === selected)?.[0]?.value?.text ?? ""
  );
  const { data } = useQuery(GET_LANGUAGES);
  const prevLanguages = usePrevious(values?.map((v) => v.language));
  const { title, refetch } = useWiviContent({
    ...markerData,
    id: markerData?.target?.id,
    type: markerData?.target?.type,
    iso: selected,
  });

  useEffect(() => {
    if (
      !isEqual(
        prevLanguages,
        values?.map((v) => v.language)
      )
    ) {
      !selected &&
        setSelected(
          values?.[0]?.language ?? data?.languages?.[0]?.code ?? "fr"
        );
      setText(
        values?.filter(
          (l) =>
            l?.language === (selected ?? data?.languages?.[0]?.code ?? "fr")
        )?.[0]?.value?.text ?? ""
      );
    }
  }, [data, values]);

  const patch = (e) => {
    patchFunction({
      code: e?.code ?? selected,
      value: e?.value ?? text,
    });
    callback?.(e);
  };
  const debounceValue = useDebounce(patch, 300);

  useEffect(() => {
    text?.length && debounceValue();
  }, [text]);

  const handleText = (e) => {
    setText(e.target.value);
    !e.target.value?.length && debounceValue({ code: selected, value: "" });
  };

  const handleSelect = (e) => {
    setSelected(e.target.value);
    const text =
      values?.filter((l) => l?.language === e.target.value)?.[0]?.value?.text ??
      "";
    setText(text ?? "");
  };

  return data?.languages?.length ? (
    <Stack direction="row" align="center" spacing={1} {...props}>
      <TextField
        size="small"
        variant="outlined"
        onChange={handleText}
        value={text}
        placeholder={title ?? placeholder}
        label={label}
      />
      <Select
        size="small"
        value={selected}
        onChange={handleSelect}
        renderValue={(v) => <Flag code={v} />}
      >
        {data?.languages?.map(({ code }, i) => (
          <MenuItem key={i} value={code}>
            <Flag code={code} />
          </MenuItem>
        ))}
      </Select>
    </Stack>
  ) : null;
};

export default TitleInput;

const Flag = ({ code }) => (
  <ReactCountryFlag
    countryCode={
      Object.keys(replacements)?.includes(code) ? replacements[code] : code
    }
  />
);
