import React from 'react'
import LeafletPlaceholders from './leafletPlaceholders'
import LeafletWrapper from './leafletWrapper'

const LeafletMap = () => {
  return (
    <LeafletWrapper>
        <LeafletPlaceholders />
    </LeafletWrapper>
  )
}

export default LeafletMap