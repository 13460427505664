import Keycloak from 'keycloak-js'
import {KC_REALM, KC_URL, KC_CLIENT } from 'Config/constantes'
// Setup Keycloak instance as needed
// Pass initialization options as required or leave blank to load from 'keycloak.json'

const keycloak = new Keycloak({
    url: KC_URL,
    realm: KC_REALM,
    clientId: KC_CLIENT,
})

export default keycloak