import React from "react";
import { animated, useSpring } from "@react-spring/web";
import { useDisplayedPlaceholders } from "Utils/hooks";
import { Box, IconButton, Typography } from "@mui/material";
import { ArrowBackSharp, Timer } from "@mui/icons-material";
import { secsToString } from "Utils/durations";
import { useQuery } from "@apollo/client";
import { GET_COLLECTION, GET_POI } from "Config/API/queries";
import { useSearchParams } from "react-router-dom";
import useWiviContent from "Utils/hooks/wiviContent";
const AnimBox = animated(Box);

const ContentTopBar = () => {
  const { parent, clearFilters } = useDisplayedPlaceholders();

  const anim = useSpring({
    from: { y: -200 },
    to: { y: parent ? 30 : -200 },
  });

  const getDuration = (secs) => {
    const { h, m } = secsToString(secs);
    return secs / 3600 >= 1 ? `${h}h${m}min` : `${secs / 60} min`;
  };

  const { data, title } = useWiviContent({
    ...parent,
    type: parent?.target?.type,
    id: parent?.target?.id,
  });

  return (
    <AnimBox
      style={{ ...anim, pointerEvents: "none" }}
      zIndex={13}
      position="fixed"
      top={0}
      display="flex"
      alignItems="center"
      justifyContent="center"
      width="100%"
      //
    >
      <Box
        height={30}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        px={2}
        py={2}
        maxWidth="calc(100% - 140px)"
        borderRadius="800px"
        style={{ background: "rgba(255,255,255,0.85)", pointerEvents: "auto" }}
      >
        <Box>
          <IconButton size="small" onClick={clearFilters}>
            <ArrowBackSharp />
          </IconButton>
        </Box>
        <Box
          m={0.5}
          style={{
            textAlign: "center",
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: 3,
            overflow: "hidden",
          }}
        >
          <Typography style={{ lineHeight: "1em" }}>
            {/* {parent?.i18n?.title?.value?.text ?? data?.i18n?.title?.value?.text} */}
            {title}
          </Typography>
        </Box>
        {parent?.duration && (
          <Box ml={2} mr={1} display="flex" alignItems="center">
            <Timer color="primary" fontSize="small" />
            <Typography variant="caption">
              {getDuration(parent?.duration)}
            </Typography>
          </Box>
        )}
      </Box>
    </AnimBox>
  );
};

export default ContentTopBar;
