import React, { useState } from "react";
import { SliderPicker } from "react-color";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import Box from "@mui/material/Box";
import Chroma from "chroma-js";
import { useMap } from "Utils/hooks";
import { useTranslation } from "react-i18next";

const BackgroundColorControls = (props) => {
  const { configuration, patchConfig } = useMap();
  const [error, setError] = useState(false);
  const mapBackground = configuration?.backgroundColor;
  const [value, setValue] = useState(mapBackground || "#ffffff");
  const setColor = (value) => patchConfig("backgroundColor", value);
  const { t } = useTranslation();

  const handleChange = (color) => {
    let hexColor = Chroma(color).hex("rgb");
    setValue(hexColor);
    setError(false);
    Chroma.valid(color) && setColor(hexColor);
  };

  const handleInput = (color) => {
    setValue(color) && setError(false);
    let iserror = color.length < 1 || !Chroma.valid(color);
    setError(iserror);
    !iserror && setColor(Chroma(color).hex("rgb"));
  };

  return (
    <>
      <Typography variant="h6" color="primary">
        {t("/configuration.settings.background_color")}
      </Typography>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        flexWrap="wrap"
      >
        <Box width={300} mx={1} my={2}>
          <SliderPicker
            onChangeComplete={(e) => handleChange(e.hsl)}
            color={mapBackground}
          />
        </Box>
        <Box mx={1} my={2}>
          <TextField
            label={t("/configuration.settings.color_code")}
            helperText={error && t("/configuration.settings.wrong_color")}
            variant="outlined"
            value={value.substring(1)}
            error={error}
            onChange={(e) => handleInput("#" + e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">#</InputAdornment>
              ),
            }}
          />
        </Box>
      </Box>
    </>
  );
};

export default BackgroundColorControls;
