
import React, { useState, useEffect } from 'react'
import { useSpring, animated } from '@react-spring/three'
import { useStore } from 'Store'

const Layer = ({ scene, index, ...props }) => {
    const { actualLayer, data: { scaleRatio }, setLayerHeight } = useStore()
    
    useEffect(()=> {
        index === actualLayer && setLayerHeight(scene.children[0].position.y * scaleRatio)
    } ,[actualLayer, index, setLayerHeight, scaleRatio, scene.children])
    
    const [visible, setVisible] = useState(null)
 
    const springs = useSpring({
        coords: index <= actualLayer ? [0, 0, 0] : [0, 0, 40],
        onStart: (e) => index <= actualLayer && setVisible(true),
        onRest: (e) => index > actualLayer && setVisible(false),
        from: { position: [0, 0, 40] }
    })

    return (
        <animated.primitive
            position={springs.coords}
            scale={[scaleRatio, scaleRatio, scaleRatio]}
            key={index}
            visible={visible}
            object={scene}
            {...props}
        />
    )
}

export default Layer