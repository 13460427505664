/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { forwardRef, useRef } from "react";
import { useGLTF, useAnimations } from "@react-three/drei";
import { useEffect } from "react";
import { animated } from "@react-spring/three";

const Druid = ({ rotation, scale, position, ...props }, group) => {
  
  const { nodes, materials, animations } = useGLTF("/GLTF/druid.gltf");
  const { actions } = useAnimations(animations, group);
  const [x, y, z] = rotation?.get?.() ?? [0, 0, 0];
  console.log("x+Math.PI/2:", x + Math.PI / 2);
  useEffect(() => {
    actions?.Waiting?.play();
  }, [actions]);

  return (
    <animated.group
      ref={group}
      scale={scale ?? [2, 2, 2]}
      position={position ?? [0, 0, 0]}
      rotation={[Math.PI / 2, y, 0]}
      {...props}
      dispose={null}
    >
      {/* <animated.group > */}
      <animated.primitive object={nodes.root} />
      <animated.skinnedMesh
        geometry={nodes.druid.geometry}
        material={materials.color_main}
        skeleton={nodes.druid.skeleton}
      />
    </animated.group>
  );
};

useGLTF.preload("/GLTF/druid.gltf");

export default forwardRef(Druid);
