import { Close, Place } from "@mui/icons-material";
import {
  Box,
  TextField,
  InputAdornment,
  List,
  MenuItem,
  Typography,
  IconButton,
} from "@mui/material";
import axios from "axios";
import { t } from "i18next";

import React, { useEffect, useState } from "react";
import useDebounce from "Utils/hooks/debounce";

const AddressFinder = ({ callback, i18n, disabled }) => {
  const [list, setList] = useState([]);
  const [value, setValue] = useState("");

  const handleChange = async (e) => {
    try {
      const { data } = await axios.get(
        "https://nominatim.openstreetmap.org/search",
        {
          params: {
            q: value,
            format: "json",
          },
        }
      );
      setList(data);
    } catch (error) {
      console.log("error:", error);
    }
  };
  const debounceValue = useDebounce(handleChange, 300);

  const clear = () => {
    setValue("");
    setList([]);
  };

  const handleInput = (e) => {
    setValue(e.target.value);
  };
  useEffect(() => {
    debounceValue();
  }, [value]);

  const handleClick = (e) => {
    callback(e);
    clear();
  };

  return (
    <Box maxWidth={500} width="100%" my={3}>
      <TextField
        
        disabled={disabled}
        label={t(i18n ?? "/leafleteditor.createlayer.search_address")}
        value={value}
        onChange={handleInput}
        fullWidth
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Place color={disabled ? "disabled" : "primary"}/>
            </InputAdornment>
          ),
          endAdornment: (
            <IconButton disabled={disabled} size="small" onClick={clear}>
              <Close />
            </IconButton>
          ),
        }}
        
        mb={1}
      />
      {list?.length > 0 && (
        <Box
          width="100%"
          px={1}
          boxShadow={2}
          maxHeight={200}
          overflow="auto"
          bgcolor="ThreeDLightShadow"
        >
          <List dense>
            {list.map((e, index) => (
              // <ListItem key={index}>
              <MenuItem key={index} onClick={() => handleClick(e)}>
                <Typography variant="caption">{e.display_name}</Typography>
              </MenuItem>
              // </ListItem>
            ))}
          </List>
        </Box>
      )}
    </Box>
  );
};

export default AddressFinder;
