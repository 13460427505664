import { Map } from "@mui/icons-material";
import {
  Button,
  Container,
  Divider,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import TitleInput from "Components/Global/TitleInput";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useMaps } from "Utils/hooks/map";

const Home = () => {
  const types = ["indoor", "outdoor"];
  const navigate = useNavigate();
  const { t } = useTranslation();
  const {
    isLeafletMap,
    isThreejsMap,
    loading,
    leafletMap,
    threejsMap,
    patchTitle,
    updateMap,
  } = useMaps();

  const maps = {
    indoor: {
      exists: isThreejsMap,
      data: threejsMap,
      route: "editor",
    },
    outdoor: {
      exists: isLeafletMap,
      data: leafletMap,
      route: "leafleteditor",
    },
  };

  const handleClick = (type) => {
    navigate(`/${maps[type].route}`);
  };

  return (
    <Box
      width="100%"
      height="100%"
      sx={{
        display: "flex",
        justifyContent: "center",
        height: "100%",
        bgcolor: "#F7F7F7",
        overflow: "auto",
      }}
    >
      <Box
        boxShadow={2}
        borderRadius={2}
        display="flex"
        alignItems="center"
        height="fit-content"
        m={3}
        flexDirection="column"
        p={4}
        sx={{ textAlign: "center", backgroundColor: "#fff" }}
      >
        <Stack direction="row" alignItems={"center"} spacing={1} mb={3}>
          <Map fontSize="large" color="primary" />
          <Typography variant="h4" color="primary">
            {t("/admin.title")}
          </Typography>
        </Stack>
        <Stack
          direction="row"
          spacing={3}
          flexWrap="wrap"
          alignItems={"center"}
          justifyContent={"center"}
          width="fit-content"
          height="100%"
          divider={
            <Divider
              orientation="vertical"
              flexItem
              variant="middle"
              sx={{ bgcolor: "grey" }}
            />
          }
        >
          {types.map((type, index) => (
            <Box
              key={index}
              p={3}
              maxWidth={500}
              display="flex"
              alignItems="center"
              flexDirection="column"
              justifyContent={"space-between"}
              height="100%"
            >
              <Typography
                variant="h6"
                color="text.primary"
                textTransform={"uppercase"}
              >
                {t(`/admin.${type}`)}
              </Typography>
              {maps?.[type]?.exists && (
                <TitleInput
                  my={1}
                  label={t("/admin.mapname")}
                  placeholder={t(
                    `/visit.${type === "outdoor" ? "leaflet" : "threejs"}`
                  )}
                  values={maps?.[type]?.data?.title}
                  patchFunction={(e) =>
                    patchTitle({ ...e, mapId: maps?.[type]?.data?.id })
                  }
                />
              )}
              <img
                src={`/assets/map_${type}.jpg`}
                alt={type}
                height={150}
                style={{ marginBottom: 16, borderRadius: 8 }}
              />
              <Typography
                variant="body1"
                color="text.primary"
                mb={3}
                maxWidth={400}
              >
                {t(`/admin.${type}_desc`)}
              </Typography>
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleClick(type)}
              >
                {t(
                  maps?.[type]?.exists ? "/admin.edit_btn" : "/admin.create_btn"
                )}
              </Button>
            </Box>
          ))}
        </Stack>
      </Box>
    </Box>
  );
};

export default Home;
