import create from "zustand";

export { useStoreInitalization } from "./initialization";

export const useStore = create((set, get) => ({
  mapType: null,
  leafletMapInstance: null,
  editorMapPan: true,
  actualLayerId: null, //valeur de départ
  activePlaceholderId: null,
  configuration: {},
  activePoi: { uuid: null },
  activePlaceholder: null,
  data: [],
  theme: null,
  geoModal: null, //data de modale de geopositionnement
  editorPlaceholders: null,
  camera: null,
  dataFilter: {
    uuid: null,
    filter: "default",
  },
  parent: null,
  geolocation: null,
  geoProtocolAvailability: false,
  // setActualLayer: (id) => set({ actualLayerId: id }), //change l'id de l'étage actuel
  actualLayer: get()?.data?.layers[0], //fonction de maj de la valeur
  setLayerHeight: (position) => set({ layerHeight: position }), //

  setActivePoi: (uuid, source, position) =>
    set({
      activePoi: { uuid: uuid, source: source, position: position },
    }),

  setData: (newData) => {
    set((state) => ({ data: { ...state.data, ...newData } }));
  },

  setActivePlaceholder: (ph) => set({ activePlaceholder: ph }),

  addPlaceholder: (ph) => {
    const { actualLayerId, data } = { ...get() };
    const newLayers = [...data.layers].map((f) => ({
      ...f,
      position:
        f.id !== "43fc1053-e9cc-4038-b5cc-6072813c0484" ? [0, 0, 1] : [0, 0, 0],
      scale: "43fc1053-e9cc-4038-b5cc-6072813c0484" === f.id ? 0.05 : 0.01,
      placeholders:
        f.id === actualLayerId ? [...f.placeholders, ph] : f.placeholders,
    }));
    set({ data: { ...data, layers: newLayers } });
  },

  updatePlaceholder: (uuid, newData) => {
    const { data } = { ...get() };
    const newLayers = [...data.layers].map((f) => ({
      ...f,
      placeholders: [...f.placeholders].map((p) =>
        p.id === uuid ? { ...p, ...newData } : p
      ),
    }));
    set({ data: { ...data, layers: newLayers } });
  },

  updateColor: (uuid, color) => {
    const { actualLayer, editorPlaceholders } = get();
    let newData = editorPlaceholders[actualLayer].map((p) => {
      p.uuid === uuid && (p.color = color);
      return p;
    });
    set({
      editorPlaceholders: { ...editorPlaceholders, [actualLayer]: newData },
    });
  },

  removePlaceholder: (uuid) => {
    const { actualLayer, editorPlaceholders } = get();
    let newData = editorPlaceholders[actualLayer].filter(
      (p) => p.uuid !== uuid
    );
    set({
      editorPlaceholders: { ...editorPlaceholders, [actualLayer]: newData },
    });
  },

  setValue: (key, value) => set({ [key]: value }),
}));

const dataSelector = (s) => s.data;
const actualLayerIdSelector = (s) => s.actualLayerId;
const setValueSelector = (s) => s.setValue;
const setDataSelector = (s) => s.setData;
const layersSelector = (s) => s?.data?.layers;
const activePlaceholderIdSelector = (s) => s.activePlaceholderId;
const cameraSelector = (s) => s.camera;
const dataFilterSelector = (s) => s.dataFilter;
const parentSelector = (s) => s.parent;
const leafletMapInstanceSelector = (s) => s.leafletMapInstance;
const geolocationSelector = (s) => s.geolocation;
const geoProtocolAvailabilitySelector = (s) => s.geoProtocolAvailability;
const mapTypeSelector = (s) => s.mapType;

export {
  dataSelector,
  setValueSelector,
  setDataSelector,
  actualLayerIdSelector,
  layersSelector,
  activePlaceholderIdSelector,
  cameraSelector,
  dataFilterSelector,
  parentSelector,
  leafletMapInstanceSelector,
  geolocationSelector,
  geoProtocolAvailabilitySelector,
  mapTypeSelector,
};
