import { is } from "@react-spring/shared";
import { useSpring, animated } from "@react-spring/three";

import { useLoader, useThree } from "@react-three/fiber";
import React, { useEffect, useMemo, useRef, useState } from "react";
import * as THREE from "three";
import { CanvasTexture, MeshStandardMaterial } from "three";
import { PlaneBufferGeometry, ImageBitmapLoader } from "three";
import { addProtocol } from "Utils/addProtocol";
import { useActualLayer, useActualPlaceholder } from "Utils/hooks";
import { useBounds } from "Utils/hooks/bounds";


const ThreeJSLayer = ({ data, configMod, gpsMod, editorMap, ...props }) => {
  const { image, position, rotation, scale } = data ?? {};
  const { url, width, height } = image?.file ?? {};
  const testRef = useRef();
  const bounds = useBounds();
  const [active, setActive] = useState(false);
  const { activePlaceholderId } = useActualPlaceholder();

  //Ce loader custom permet l'utilisation d'images en cache
  // "the problem is that THREE.TextureLoader internally uses THREE.ImageLoader which uses HTML5 image API in order to load images. So there is actually no XHR call"

  const mapImage = useLoader(ImageBitmapLoader, addProtocol(url), (l) => {
    l.setOptions({ imageOrientation: "flipY" });
  });
  const texture = new CanvasTexture(mapImage);

  const { actualLayer } = useActualLayer();
  const isActualLayer = data?.id === actualLayer?.id;

  useEffect(
    () => {
      setVisible(isActualLayer);
      isActualLayer && bounds?.refresh(testRef.current).clip().fit();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isActualLayer]
  );

  useEffect(() => {
    !editorMap &&
      !activePlaceholderId &&
      isActualLayer &&
      bounds?.refresh(testRef.current).fit().clip();
  }, [activePlaceholderId]);

  useEffect(() => {
    setActive(isActualLayer);
    setVisible(isActualLayer);
  }, [isActualLayer]);

  const [visible, setVisible] = useState(true);

  const springs = useSpring({
    coords:
      position.z < actualLayer?.position?.z
        ? [0, -2 * position?.z - 1, 0]
        : position?.z > actualLayer?.position?.z
        ? [0, 80 + position?.z, 0]
        : [0, 0, 0],
    opacity: isActualLayer ? 1 : 0,
    rotation: [-Math.PI / 2, 0, THREE.MathUtils.degToRad(rotation ?? 0)],
    scale: [scale ?? 0, scale ?? 0, scale ?? 0],
    onStart: () => setVisible(isActualLayer),
    onRest: () => {
      setVisible(isActualLayer);
    },
    // onRest: () => bounds?.refresh(testRef.current).clip().fit(),
    from: { position: [0, 0, 0], opacity: 1 },
    config: { mass: 1, tension: 180, friction: 23 },
  });

  const handleClick = (e) => {
    (configMod || isActualLayer) && props.onClick && props.onClick(e);
    isActualLayer && props.onPointerDown && props.onPointerDown(e);
  };
  const handleMove = (e) => {
    isActualLayer && props.onPointerMove && props.onPointerMove(e);
  };

  const material = useMemo(
    () =>
      new MeshStandardMaterial({
        precision: 'mediump',
        transparent: true,
        map: texture,
        side: THREE.DoubleSide,
        emissiveIntensity: 0,
        // emissive: "#000000",
        metalness: 0,
        roughness: 1,
        color: "white",
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  const geom = useMemo(() => new PlaneBufferGeometry(width, height), []);

  return (
    <animated.mesh
      {...props}
      onClick={handleClick}
      onPointerMove={handleMove}
      visible={gpsMod || isActualLayer}
      ref={testRef}
      name={data?.id}
      opacity={springs.opacity}
      // up={[0, 0, 1]}
      // opacity={visible ? 1 : 0}
      receiveShadow
      // rotation={springs.rotation}
      rotation={[-Math.PI / 2, 0, THREE.MathUtils.degToRad(rotation ?? 0)]}
      position={[0, 0, 0]}
      // position={springs.coords}
      scale={[scale ?? 0, scale ?? 0, scale ?? 0]}
      geometry={geom}
      material={material}
    />
  );
};

// const MemoLayer = React.memo(FlatLayer);
export default ThreeJSLayer;
