import { useStore } from "Store";
import deepDiff from "Utils/deepDiff";
import {
  client,
  DELETE_LAYER,
  UPDATE_LAYER,
  CREATE_LAYER,
  UPDATE_MAP,
  UPDATE_PLACEHOLDER,
  CREATE_PLACEHOLDER,
  DELETE_PLACEHOLDER,
  CREATE_MAP,
} from "./mutations";
import { GET_MAPS, GET_LAYER, GET_MAP } from "./queries";
import { difference, uniq } from "lodash";
import { useMap } from "Utils/hooks";
import { useMemo } from "react";

export const manageLayer = async (layer) => {
  try {
    const add = layer?.to;
    const remove = layer?.from;
    add ? await addLayer(add) : await deleteLayer(remove);
  } catch (error) {
    console.log("error:", error);
  }
};

export const addLayer = async (layerData, mapId) => {
  // const { x, y, z } = layerData?.position;
  try {
    await client.mutate({
      mutation: CREATE_LAYER,
      variables: {
        ...layerData,
        mapId: mapId,
        imageId: layerData?.image?.file?.id,
      },
    });
  } catch (error) {
    console.log("error:", error);
  }
};

export const deleteLayer = async (layerId) => {
  try {
    await client.mutate({
      mutation: DELETE_LAYER,
      variables: {
        layerId: layerId,
      },
    });
    return "deleted";
  } catch (error) {
    console.log("error:", error);
    return error;
  }
};

export const updateLayer = async (index) => {
  const allMapsInStore = useStore.getState().data;
  const mapType = useStore.getState().mapType;
  const storeData =
    allMapsInStore?.filter((m) => m?.type === mapType)?.[0] ?? [];
  const layer = storeData?.layers?.[index];
  try {
    await client.mutate({
      mutation: UPDATE_LAYER,
      variables: { layerId: layer?.id, ...layer },
    });
  } catch (error) {
    console.log("error:", error);
  }
};

export const updateMapConfig = async (mapId) => {
  const { configuration, type } = useStore
    .getState()
    .data.filter((m) => m.id === mapId)?.[0];

  try {
    await client.mutate({
      mutation: UPDATE_MAP,
      variables: {
        mapId: mapId,
        configuration: configuration,
        type: type,
      },
    });
    return "done";
  } catch (error) {
    console.log("error:", error);
  }
};

const updatePlaceholder = async (v) => {
  try {
    const removeChildreni18n = v.children?.map(({ i18n, ...c }) => {
      return { ...c };
    });
    await client.mutate({
      mutation: UPDATE_PLACEHOLDER,
      variables: {
        ...v,
        children: removeChildreni18n,
      },
    });
  } catch (error) {
    console.error("UPDATE PLACEHOLDER ERROR :", error);
  }
};

const createPlaceholder = async (v) => {
  try {
    const data = await client.mutate({
      mutation: CREATE_PLACEHOLDER,
      variables: { ...v },
    });
  } catch (error) {
    console.error("PLACEHOLDER CREATION ERROR :", error);
  }
};

const deletePlaceholder = async (id) => {
  try {
    await client.mutate({
      mutation: DELETE_PLACEHOLDER,
      variables: { id: id },
    });
  } catch (error) {
    console.error("DELETE PLACEHOLDER ERROR :", error);
  }
};

export const updatePlaceholders = async (layer) => {
  try {
    const apiLayer = await getOrCreateLayer(layer);

    const apiPlaceholdersIds = apiLayer?.placeholders?.map((p) => p.id);
    const storePlaceholdersIds = layer?.placeholders?.map((p) => p.id);
    const removed = difference(apiPlaceholdersIds, storePlaceholdersIds);
    const added = difference(storePlaceholdersIds, apiPlaceholdersIds);

    const unchangedOrUpdated = (layer) =>
      layer?.placeholders?.filter(
        (p) => ![...added, ...removed].includes(p.id)
      );
    removed.length >= 1 &&
      (await Promise.all(
        removed.map(async (id) => await deletePlaceholder(id))
      ));
    added.length >= 1 &&
      (await Promise.all(
        added.map(
          async (id) =>
            await createPlaceholder(
              layer?.placeholders.find((p) => p.id === id)
            )
        )
      ));

    const diff = deepDiff(
      unchangedOrUpdated(apiLayer),
      unchangedOrUpdated(layer)
    );

    const indexes = uniq(Object.keys(diff)?.map((k) => k.split(".")?.[0]));
    indexes?.length >= 1 &&
      (await Promise.all(
        indexes?.map(
          async (id) => await updatePlaceholder(unchangedOrUpdated(layer)[id])
        )
      ));
    return;
  } catch (error) {
    console.log("error:", error);
  }
};

export const uploadConfigChanges = async () => {
  try {
    const allMapsInStore = useStore.getState().data;
    const mapType = useStore.getState().mapType;
    const storeData =
      allMapsInStore?.filter((m) => m?.type === mapType)?.[0] ?? [];
    const data = await getOrCreateMap();

    const apiLayersIds = data?.layers?.map((p) => p.id);
    const storeLayersIds = storeData?.layers?.map((p) => p.id);
    const removed = difference(apiLayersIds, storeLayersIds);

    const added = difference(storeLayersIds, apiLayersIds);

    const unchangedOrUpdated = (layers) =>
      layers?.filter((p) => ![...added, ...removed].includes(p.id));
    //Suppression d'un layer
    removed.length >= 1 &&
      (await Promise.all(removed.map(async (id) => await deleteLayer(id))));

    //Ajout d'un layer
    added.length >= 1 &&
      (await Promise.all(
        added.map(
          async (id) =>
            await addLayer(
              storeData?.layers?.find((p) => p.id === id),
              data?.id
            )
        )
      ));

    //Update d'un layer
    const newdiff = deepDiff(
      unchangedOrUpdated(data?.layers),
      unchangedOrUpdated(storeData?.layers)
    );

    const indexes = uniq(Object.keys(newdiff).map((k) => k.split(".")?.[0]));

    indexes?.length >= 1 &&
      (await Promise.all(indexes.map(async (id) => await updateLayer(id))));

    //Changement de config de map
    const diff = deepDiff(data, storeData);
    await Promise.all(
      Object.keys(diff)?.map(async (d) => {
        const [type] = d.split(".");
        if (type === "configuration") {
          await updateMapConfig(data?.id);
        }
      })
    );
    return "saved";
  } catch (error) {
    console.log("error:", error);
    return "error";
  }
};

export const uploadEditorChanges = async () => {
  try {
    // const storeData = useStore.getState().data;
    const allMapsInStore = useStore.getState().data;
    const mapType = useStore.getState().mapType;
    const storeData =
      allMapsInStore?.filter((m) => m?.type === mapType)?.[0] ?? [];
    await Promise.all(
      storeData?.layers?.map(async (layer) => {
        await updatePlaceholders(layer);
      })
    );
  } catch (error) {
    console.log("error:", error);
  }
};

//crééer une map threejs ou leaflet si besoin
//renvoi les data de la map
const getOrCreateMap = async () => {
  try {
    const allMapsInStore = useStore.getState().data;
    const mapType = useStore.getState().mapType;
    const storeData =
      allMapsInStore?.filter((m) => m?.type === mapType)?.[0] ?? [];
    // const storeData = useStore.getState().data;
    // const { data: storeData } = useMap();
    let data = null;
    const query = await client.query({
      query: GET_MAP,
      variables: { iso: ["fr"], uuid: storeData.id },
    });
    data = query?.data?.map;

    if (!data) {
      console.log("creating map", storeData.id);
      const map = await client.mutate({
        mutation: CREATE_MAP,
        variables: { iso: ["fr"], ...storeData },
      });
      console.log("map:", map);

      data = map?.data?.createMap;
    }
    return data;
  } catch (error) {
    console.log("error:", error);
  }
};

const getOrCreateLayer = async (storeLayer) => {
  try {
    let data = null;
    const query = await client.query({
      query: GET_LAYER,
      variables: { uuid: storeLayer.id },
    });
    data = query?.data?.layer;
    if (!data) {
      const mapData = await getOrCreateMap();
      const layer = await client.mutate({
        mutation: CREATE_LAYER,
        variables: {
          ...storeLayer,
          mapId: mapData?.id,
          imageId: storeLayer?.image?.file?.id,
        },
      });
      data = layer?.data?.createLayer;
    }
    return data;
  } catch (error) {
    console.log("error:", error);
  }
};
