import { Marker, Popup, Tooltip } from "react-leaflet";
import L from "leaflet";
import { useEffect, useMemo, useRef } from "react";
import "leaflet-iconmaterial";
import ReactDOMServer from "react-dom/server";
import {
  useActualLayer,
  useActualPlaceholder,
  useDisplayedPlaceholders,
  useMap,
} from "Utils/hooks";
import { useTranslation } from "react-i18next";
import LeafletMarker from "./LeafletMarker";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/system";
import customProtocolCheck from "custom-protocol-check";
import useWiviContent from "Utils/hooks/wiviContent";

//maker leaflet déplaçable
const StandardLeafletMarker = ({
  position,
  marker,
  id,
  parentId,
  children,
  target,
}) => {
  const { t } = useTranslation();
  const { activePlaceholderId, set, data } = useActualPlaceholder(parentId);
  const { setFilter, clearFilters } = useDisplayedPlaceholders();
  const { set: setLayer } = useActualLayer();
  const navigate = useNavigate();
  const { geolocation } = useMap();
  
  const {
    data: fetch,
    loading,
    error,
    title,
  } = useWiviContent({ ...data, id: target?.id, type: target?.type });

  const isActive = useMemo(
    () => activePlaceholderId === id,
    [activePlaceholderId]
  );
  const markerRef = useRef(null);

  const handleMarkerClick = () => {
    if (target?.type === "mapLink") {
      navigate("/indoor");
    }
    if (children?.length >= 1) {
      isActive ? clearFilters() : setFilter({ placeholderId: id });
    } else if (target?.type === "layerLink") {
      clearFilters();
      return setLayer(target?.id);
    } else {
      markerRef.current?.openPopup();
      set(id);
    }
  };

  const eventHandlers = useMemo(
    () => ({
      click() {
        const marker = markerRef.current;
        if (marker != null) {
          handleMarkerClick();
        }
      },
    }),
    [activePlaceholderId, isActive, id]
  );

  useEffect(() => {
    isActive && markerRef.current?.openPopup();
  }, [isActive]);

  const handleGPS = () => {
    const geoLink = `geo:${position.y},${position.x};u=35`;
    // si geolocation reconnue on propose un itinéraire dans google maps autrement on indique juste la position du point
    const mapsUrl = geolocation
      ? `https://www.google.com/maps/dir/?api=1&origin=${geolocation.lat},${geolocation.lng}&destination=${position.y},${position.x}&travelmode=walking`
      : `https://www.google.com/maps/search/?api=1&query=${position.y}%2C${position.x}`;
    //adapte l'ouverture d'url en fonction du navigateur et la disponibilité du protocole geo
    customProtocolCheck(geoLink, () => window.open(mapsUrl), null, 500);
  };

  return (
    <Marker
      eventHandlers={eventHandlers}
      position={[position?.y, position?.x]}
      ref={markerRef}
      icon={L.divIcon({
        className: "custom icon",
        html: ReactDOMServer.renderToString(
          <LeafletMarker {...marker} isActive={isActive} />
        ),
      })}
    >
      <Popup direction="top">
        <Box
          onClick={handleGPS}
          display="flex"
          alignItems="center"
          flexDirection={"column"}
        >
          <Box
            maxWidth={"40vw"}
            whiteSpace="nowrap"
            overflow={"hidden"}
            textOverflow={"ellipsis"}
          >
            {!loading && title}
          </Box>
          <Box>
            <a>{t("/outdoor.openGPS")}</a>
          </Box>
        </Box>
      </Popup>
    </Marker>
  );
};

export default StandardLeafletMarker;
