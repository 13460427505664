import React, { useState, useEffect, useRef } from "react";
import { animated, useSpring } from "@react-spring/web";
import {
  useActualLayer,
  useActualPlaceholder,
  useDisplayedPlaceholders,
} from "Utils/hooks";
import { useBounds } from "Utils/hooks/bounds";
import Model2D from "./2Dmodel";
import { useNavigate } from "react-router-dom";

const AnimMarker = animated(Model2D);

//Render de chaque placeholder animé
const HTMLMarker = ({ index, data, pos, opacity }) => {
  const { marker, id, children, target } = data ?? {};
  const { set, activePlaceholderId } = useActualPlaceholder();
  const { setFilter, clearFilters, filters } = useDisplayedPlaceholders();
  const [isActive, setActive] = useState(false);
  const { set: setLayer } = useActualLayer();
  const markerRef = useRef();
  const bounds = useBounds();
  const disableFocus = Boolean(filters && children?.length);


  useEffect(() => {
    children && setActive(filters?.uuid === id);
  }, [filters]);

  useEffect(() => {
    !children?.length && setActive(activePlaceholderId === id);
    activePlaceholderId === id &&
      children?.length >= 1 &&
      setFilter({ placeholderId: id });
    activePlaceholderId === id &&
      !disableFocus &&
      bounds.refresh(markerRef.current).clip().fit();
  }, [activePlaceholderId, id]);

  const poiColor =
    data.type === "collection"
      ? window?.__CONFIG__?.theme?.primary.main
      : "#E0C03D";

  const placeholderColor = marker?.color ?? poiColor;
  const markerScale = marker?.scale ?? 1;

  const props = useSpring({
    scale: isActive ? 1.5 * markerScale : markerScale,
    config: { mass: 1, tension: 800, friction: 20 },
    useNativeDriver: true,
  });

  const handleMarkerClick = () => {
    isActive ? set(null) : set(id);
    if(target.type === 'mapLink'){
      window.location.replace('/outdoor')
    }
    if (children?.length >= 1) {
      isActive ? clearFilters() : setFilter({ placeholderId: id });
    } else if (target?.type === "layerLink") {
      clearFilters();
      return setLayer(target?.id);
    }
   
  };

  return (
    <AnimMarker
      ref={markerRef}
      position={pos}
      scale={props.scale}
      color={placeholderColor}
      iconColor={marker?.iconColor}
      icon={marker?.icon}
      opacity={opacity}
      onPointerDown={handleMarkerClick}
    />
  );
};

export default HTMLMarker;
