import { Box, Button, Typography, TextField, IconButton } from "@mui/material";
import { Delete } from "@mui/icons-material";
import React, { useEffect } from "react";
import { useGeoPositionMap } from "Utils/hooks";

import * as yup from "yup";
import { useFormik } from "formik";
import { Trans, useTranslation } from "react-i18next";

const GeoPoints = () => {
  const { geoPoints, close, addCoords } = useGeoPositionMap();
  const { t } = useTranslation();
  const isComplete = geoPoints?.length >= 2;
  const getSchema = () =>
    geoPoints?.reduce(
      (acc, val, i) => ({
        ...acc,
        [`latitude_${i}`]: yup
          .number()
          .typeError("format incorrect")
          .required("préciser une latitude"),
        [`longitude_${i}`]: yup
          .number()
          .typeError("format incorrect")
          .required("préciser une longitude"),
      }),
      {}
    );

  const validationSchema = yup.object(getSchema());

  const formik = useFormik({
    initialValues: () =>
      geoPoints?.reduce(
        (acc, val, i) => ({
          ...acc,
          [`latitude_${i}`]: val?.latitude ?? "",
          [`longitude_${i}`]: val?.longitude ?? "",
          [`altitude_${i}`]: "",
        }),
        {}
      ),
    validateOnChange: true,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      saveAndClose(values);
    },
  });

  useEffect(() => {
    formik.validateForm();
  }, [geoPoints?.length]);

  const saveAndClose = (v) => {
    addCoords(v);
    close();
  };

  return (
    <Box display="flex" flexDirection="column" height="100%">
      <Box
        display="flex"
        flexDirection="column"
        flex={1}
        p={3}
        overflow="hidden"
      >
        <Box m={2}>
          <Typography variant="h5" color="primary">
            {t("/configuration.geoPosition.title")}
          </Typography>
          <Typography variant="h6" color="primary">
            {t("/configuration.geoPosition.instruction")}
          </Typography>

          <Typography>
            <Trans i18nKey="/configuration.geoPosition.helper" t={t}>
              Veuillez ajouter au minimum deux repères sur le plan et indiquer
              leurs coordonnées GPS respective. Pour obtenir des coordonnées GPS
              vous pouvez utiliser le site
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.coordonnees-gps.fr/"
              >
                coordonnees-gps.fr
              </a>
            </Trans>
          </Typography>
        </Box>
        <form onSubmit={formik.handleSubmit}>
          <Box m={2} maxHeight="70%" flex={1} overflow="scroll">
            {geoPoints?.map((p, index) => (
              <GeoPoint formik={formik} key={index} index={index} data={p} />
            ))}
          </Box>

          <Box
            p={3}
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
          >
            <Button
              variant="contained"
              onClick={close}
              style={{ marginRight: 16 }}
            >
             {t("/configuration.geoPosition.cancel")}
            </Button>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              disabled={!isComplete || !formik?.isValid}
            >
              {t("/configuration.geoPosition.save")}
            </Button>
          </Box>
        </form>
      </Box>
    </Box>
  );
};

export default GeoPoints;

const GeoPoint = ({ data, index, formik }) => {
  const { deletePoint } = useGeoPositionMap();

  return (
    <Box
      my={1}
      p={2}
      width="90%"
      boxShadow={1}
      display="flex"
      alignItems="center"
      justifyContent="space-between"
    >
      <Box mx={2} display="flex" alignItems="center">
        <Box
          width={30}
          height={30}
          boxShadow={3}
          style={{ background: data?.color }}
          mx={1}
          borderRadius="50px"
        />
        <Typography>Point {index + 1}</Typography>
      </Box>
      <Box mx={2}>
        <TextField
          type="number"
          name={`latitude_${index}`}
          label="Latitude"
          id={`latitude_${index}`}
          defaultValue={data?.latitude}
          placeholder="latitude"
          value={formik.values[`latitude_${index}`]}
          onChange={formik.handleChange}
          error={
            formik.touched[`latitude_${index}`] &&
            Boolean(formik.errors[`latitude_${index}`])
          }
          helperText={
            formik.touched[`latitude_${index}`] &&
            formik.errors[`latitude_${index}`]
          }
        />
      </Box>
      <Box mx={2}>
        <TextField
          type="number"
          name={`longitude_${index}`}
          label="Longitude"
          id={`longitude_${index}`}
          defaultValue={data?.longitude}
          placeholder="longitude"
          value={formik.values[`longitude_${index}`]}
          onChange={formik.handleChange}
          error={
            formik.touched[`longitude_${index}`] &&
            Boolean(formik.errors[`longitude_${index}`])
          }
          helperText={
            formik.touched[`longitude_${index}`] &&
            formik.errors[`longitude_${index}`]
          }
        />
      </Box>
      <Box mx={2}>
        <IconButton
          onClick={() => deletePoint(index)}
          style={{ color: "#d63031" }}
          size="large"
        >
          <Delete />
        </IconButton>
      </Box>
    </Box>
  );
};
