import React from "react";
import Box from "@mui/material/Box";
import { Button } from "@mui/material";

export default function Error() {
  const errorColor = "#ff6b6b";
  return (
    <Box
      flex={1}
      width="100%"
      height="100%"
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
    >
      <h3 style={{ color: errorColor }}>Désolé, une erreur est survenue :'(</h3>
      <Button
        style={{ color: errorColor, borderColor: errorColor }}
        variant="outlined"
      >
        Signaler
      </Button>
    </Box>
  );
}
