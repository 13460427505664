import { Box, Button, Divider, InputLabel, TextField } from "@mui/material";
import React, { useState, useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useActualPlaceholder, useMap } from "Utils/hooks";
import AddressFinder from "Components/Leaflet/Editor/addressFinder";

const GeoPosConfig = ({ data }) => {
  const defaultCoords = { latitude: 0, longitude: 0 };
  const [coords, setCoords] = useState(defaultCoords);
  const { setPosFromCoords, setPosition } = useActualPlaceholder(
    data?.parentId
  );
  const { t } = useTranslation();
  const { isLeaflet } = useMap();

  const handlePosition = () => {
    const { latitude, longitude } = coords;
    isLeaflet
      ? setPosition({ x: longitude, y: latitude, z: 0 })
      : setPosFromCoords(latitude, longitude);
  };

  const handleAddress = ({lat,lon}) => {
    const latitude = parseFloat(lat);
    const longitude = parseFloat(lon);
    isLeaflet
      ? setPosition({ x: longitude, y: latitude, z: 0 })
      : setPosFromCoords(latitude, longitude);
  }

  useEffect(() => {
    setCoords(defaultCoords);
  }, [data?.id]);

  return (
    <>
      <Box my={2} id="latitude">
        <InputLabel>
          <Trans i18nKey={"/editor.gui.calculate_position"} t={t}>
            Calculer une{" "}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.coordonnees-gps.fr/"
            >
              position GPS
            </a>
          </Trans>
        </InputLabel>
        <Box my={1}>
          <TextField
            type="number"
            label={t("/editor.gui.latitude")}
            value={coords?.latitude}
            onChange={(e) =>
              setCoords({ ...coords, latitude: parseFloat(e?.target?.value) })
            }
          />
        </Box>
        <Box my={1}>
          <TextField
            type="number"
            label={t("/editor.gui.longitude")}
            value={coords?.longitude}
            onChange={(e) =>
              setCoords({ ...coords, longitude: parseFloat(e?.target?.value) })
            }
          />
        </Box>
        <Button
          variant="outlined"
          color="primary"
          onClick={handlePosition}
          disabled={!coords?.latitude || !coords?.longitude}
        >
          {t("/editor.gui.calculate")}
        </Button>
      </Box>
      <Divider color="#F7F7F7" />
      <AddressFinder callback={handleAddress} />
    </>
  );
};

export default GeoPosConfig;
