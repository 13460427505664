import React, { useEffect, useRef, useState } from "react";
import {useGLTF} from '@react-three/drei'
import { animated, useSpring } from "@react-spring/three";
const url = "/loading.glb";

const Loading = (props) => {
  const group = useRef();
  const { nodes } = useGLTF(url, "/draco-gltf");

  const [state, set] = useState(false);
  useEffect(() => {
    const timeout = setTimeout(() => set(!state), 1500);
    return () => {
      clearTimeout(timeout);
    };
  }, [state]);

  const transi = useSpring({
    from: {
      position: [0, 5, 0],
    },
    to: { position: state ? [0, 5, 0] : [0, 0, 0] },
    config: {
      tension: 100,
      mass: 1,
      friction: 10,
      velocity: 0,
    },
  });
  const color = useSpring({
    color: !state ? "#6C3169" : "#028090",
  });
  const Material = () => (
    <animated.meshStandardMaterial
      color={color.color}
      attach="material"
      roughness={0.2}
      metalness={0.2}
    />
  );

  return (
    <animated.group ref={group} {...props} dispose={null} position={transi.position}>
      <animated.mesh
        material={Material}
        geometry={nodes.Extrusion.geometry}
        position={[-0.16, -2.93, 0.05]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={[0.01, 0.01, 0.01]}
      >
        <animated.mesh
          material={Material}
          geometry={nodes.Couvercle_1.geometry}
          position={[46.26, -10.5, -603.97]}
        />
        <animated.mesh
          material={Material}
          geometry={nodes.Couvercle_2.geometry}
          position={[46.26, -10.5, -603.97]}
        />
      </animated.mesh>
    </animated.group>
  );
}

useGLTF.preload(url);
export default Loading
