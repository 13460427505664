import { Marker, Popup } from "react-leaflet";
import L from "leaflet";
import { useEffect, useMemo, useRef, useState } from "react";
import "leaflet-iconmaterial";
import { Box } from "@mui/system";
import ReactDOMServer from "react-dom/server";
import { Icon } from "@mui/material";
import { useActualPlaceholder } from "Utils/hooks";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import LeafletMarker from "./LeafletMarker";

//maker leaflet déplaçable
const DraggableMarker = ({ position, marker, id, parentId }) => {
  const { t } = useTranslation();
  const { setPosition, activePlaceholderId, set, data } =
    useActualPlaceholder(parentId);
  const draggable = useMemo(
    () => activePlaceholderId === id,
    [activePlaceholderId]
  );
  const markerRef = useRef(null);

  const eventHandlers = useMemo(
    () => ({
      dragend() {
        const marker = markerRef.current;
        if (marker != null) {
          // setMapPosition(marker.getLatLng());
          const { lat, lng } = marker.getLatLng();
          setPosition({ x: lng, y: lat, z: 0 });
          marker.openPopup();
        }
      },
      dblclick() {
        const marker = markerRef.current;
        if (marker != null) {
          // setMapPosition(marker.getLatLng());
          set(draggable ? null : id);
        }
      },
    }),
    [activePlaceholderId, draggable]
  );

  useEffect(() => {
    draggable && markerRef.current.openPopup();
  }, [draggable]);

  useEffect(()=> {
    
    const { lat, lng } = markerRef?.current?.getLatLng();
    markerRef?.current?.setLatLng({ lat, lng });
  }, [marker?.scale])

  return (
    <Marker
      draggable={draggable}
      eventHandlers={eventHandlers}
      position={[position?.y, position?.x]}
      ref={markerRef}
      icon={L.divIcon({
        className: "custom icon",
        html: ReactDOMServer.renderToString(
          <LeafletMarker {...marker} draggable={draggable} />
        ),
      })}
    >
      {draggable && (
        <Popup minWidth={90} open>
          <Typography textAlign="center">
            {t("/leafleteditor.createlayer.draggable_instruction")}{" "}
          </Typography>
        </Popup>
      )}
    </Marker>
  );
};

export default DraggableMarker;
