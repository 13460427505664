import { useEffect, useRef } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import { usePlaceholders } from 'Utils/hooks'
import ContentPlaceholder from './ContentPlaceholder'


const style = {
  border: '1px dashed gray',
  padding: '0.5rem 1rem',
  marginBottom: '.5rem',
  backgroundColor: 'white',
  cursor: 'move',
}

export const Card = ({  text, moveCard, data }) => {
    const {swapRow} = usePlaceholders()
    const {id, index} = data ?? {}
  const ref = useRef(null)

  const [{ handlerId, isDrop, hoverIndex }, drop] = useDrop({
    accept: 'card',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
        isDrop : monitor.didDrop(),
        hoverIndex: monitor.getItem()
      }
    },
    hover(item, monitor) {
        
      if (!ref.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index
    
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect()
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      // Determine mouse position
      const clientOffset = monitor.getClientOffset()
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }
      // Time to actually perform the action
    //   console.log('item.id:', item.id)
      swapRow(item.id, dragIndex, hoverIndex)
    //   moveCard(dragIndex, hoverIndex)
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      
    },
  })
  const [{ isDragging }, drag] = useDrag({
    type: 'card',
    item: () => {
      return { id, index: index}
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  })


  useEffect(()=>{
      isDrop && console.log('hoverIndex:', hoverIndex)
  }, [isDrop])

  
  const opacity = isDragging ? 0 : 1
  drag(drop(ref))
  return (
    <div ref={ref} style={{ ...style, opacity }} data-handler-id={handlerId}>
     <ContentPlaceholder index={index} data={data}/>
    </div>
  )
}
