import { Box, Icon } from "@mui/material";
import { animated } from "@react-spring/web";
import { Html, Plane } from "@react-three/drei";
import { forwardRef, useRef } from "react";

const AnimBox = animated(Box);
const AnimHtml = animated(Html);
const AnimPlane = animated(Plane);

const Model2D = (
  {
    htmlProps,
    position,
    onPointerDown,
    onPointerUp,
    onDoubleClick,
    icon,
    iconColor,
    color,
    scale,
    opacity,
    ...props
  },
  ref
) => {
  const htmlRef = useRef();

  return (
    <>
      <AnimPlane
        ref={ref}
        position={position}
        opacity={opacity}
        material={{ transparent: true }}
      />
      <AnimHtml
        ref={htmlRef}
        position={position}
        {...props}
        center
        opacity={opacity}
        zIndexRange={[10, 1]}
        distanceFactor={5}
        sprite
        transform
      >
        <AnimBox
          {...htmlProps}
          onDoubleClick={(e) =>
            opacity > 0 && onDoubleClick && onDoubleClick(e, htmlRef.current)
          }
          onPointerDown={(e) =>
            opacity > 0 && onPointerDown && onPointerDown(e)
          }
          onPointerUp={() => opacity > 0 && onPointerUp && onPointerUp()}
          boxShadow={6}
          borderRadius={300}
          width={50}
          height={50}
          display="flex"
          alignItems="center"
          justifyContent="center"
          style={{
            opacity: opacity,
            backgroundColor: color,
            transform: `scale(${scale ?? 1}`,
          }}
        >
          {icon?.type === "svg" ? (
            <Box
              sx={{
                backgroundColor: iconColor ?? "#000",
                mask: `url(/icons/${icon?.file}) no-repeat center`,
              }}
            >
              <img
                width={35}
                height={35}
                src={`/icons/${icon?.file}`}
                alt={icon?.name}
                draggable={false}
                style={{ opacity: 0 }}
              />
            </Box>
          ) : icon?.type ? (
            <img
              width={35}
              height={35}
              src={`/icons/${icon?.file}`}
              alt={icon?.name}
              draggable={false}
            />
          ) : (
            <Icon style={{ color: iconColor ?? "white", fontSize: "2rem" }}>
              {icon?.value || "home"}
            </Icon>
          )}
        </AnimBox>
      </AnimHtml>
    </>
  );
};

export default forwardRef(Model2D);
