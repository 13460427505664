import React, { useState } from "react";
import Settings from "@mui/icons-material/Settings";
import Fab from "@mui/material/Fab";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useMap } from "react-leaflet";

export default function ConfigButton() {
  const navigate = useNavigate();
  const [search] = useSearchParams();
  const location = useLocation();
  const [isExtended, setExtended] = useState(false);

  const isLeaflet = location.pathname.includes("/leafleteditor");

  return (
    <div
      style={{ position: "absolute", zIndex: 2, bottom: 40, right: 40 }}
      onPointerOver={() => {
        setExtended(true);
      }}
      onPointerLeave={() => setExtended(false)}
    >
      <Fab
        onClick={() =>
          navigate(
            `/${
              isLeaflet ? "leafletconfig" : "configuration"
            }?${search?.toString()}`
          )
        }
        variant={isExtended ? "extended" : "round"}
        color="primary"
        style={{ transition: "padding .3s ease-out" }}
      >
        <Settings style={{ marginRight: 2 }} />
        {isExtended && "configuration"}
      </Fab>
    </div>
  );
}
