import React from "react";
import { useActualLayer, useMap } from "Utils/hooks";
import { CropFree, GpsFixed } from "@mui/icons-material";
import { ButtonBase, Stack, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";

const GeolocationButton = () => {
  const { leafletInstance, configuration, geolocation } = useMap();
  const { actualLayer } = useActualLayer();
  const { t } = useTranslation();
  const defaultPosition = {
    y: 49.18619985426114,
    x: -0.3655160218477249,
    z: 0,
  };
  const center = () => {
    const { position } = actualLayer ?? { position: defaultPosition };
    leafletInstance.flyTo(
      [position.y, position.x],
      actualLayer?.data?.zoom ?? 12
    );
  };

  const locatePoint = async () => {
    geolocation && leafletInstance.flyTo(
      geolocation,
      actualLayer?.data?.zoom ?? 12
    );
  };

  return (
    <Stack
      direction="column"
      spacing={1}
      sx={{
        position: "absolute",
        top: 80,
        left: 0,
        zIndex: 3,
        p: 1,
      }}
    >
      <Tooltip
        title={t("/leafleteditor.createlayer.reset_button_tooltip")}
      >
        <ButtonBase
          onClick={center}
          sx={{
            p: 1,
            bgcolor: "white",
            borderRadius: 3,
            zIndex: 4,
          }}
        >
          <CropFree fontSize={"small"} />
        </ButtonBase>
      </Tooltip>

      {configuration?.geolocation && (
        <Tooltip
          title={t("/leafleteditor.createlayer.geolocation_button_tooltip")}
        >
          <ButtonBase
            onClick={locatePoint}
            sx={{
              p: 1,
              bgcolor: "white",
              borderRadius: 3,
              zIndex: 5,
            }}
          >
            <GpsFixed fontSize={"small"} />
          </ButtonBase>
        </Tooltip>
      )}
    </Stack>
  );
};

export default GeolocationButton;
