/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useMemo } from "react";
import { meshBounds, useGLTF } from "@react-three/drei";
import { MeshStandardMaterial } from "three";
import { animated } from "@react-spring/three";
import { degToRad } from "three/src/math/MathUtils";

const PinModel = React.forwardRef(
  ({ color, rotation, opacity, raycast, ...props }, ref) => {
    const { nodes } = useGLTF("/GLTF/pin.gltf");

    const material = useMemo(
      () =>
        new MeshStandardMaterial({
          color: color ?? "#bb2222",
          roughness: 0.4,
          emissive: "#000",
          emissiveIntensity: 0,
          transparent: true,
          opacity: opacity ?? 1,
        }),
      [color, opacity]
    );

    const pinMaterial = useMemo(
      () =>
        new MeshStandardMaterial({
          color: "#a7a7a7",
          transparent: true,
          opacity: opacity ?? 1,
        }),
      [opacity]
    );

    return (
      <animated.group
        ref={ref}
        {...props}
        dispose={null}
        rotation={[Math.PI / 2, degToRad(90), degToRad(-100)]}
      >
        <animated.mesh
          raycast={meshBounds}
          castShadow
          receiveShadow
          geometry={nodes.Cylinder.geometry}
          material={pinMaterial}
          position={[0, 1, 0]}
          scale={[0.1, 1, 0.1]}
        />
        <animated.mesh
          raycast={meshBounds}
          castShadow
          receiveShadow
          geometry={nodes.Sphere.geometry}
          material={material}
          position={[0, 2.1, 0]}
          scale={[0.5, 0.5, 0.5]}
        />
      </animated.group>
    );
  }
);
export default PinModel;

useGLTF.preload("/GLTF/pin.gltf");
