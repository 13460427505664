//utilisation de OrbitControls pour visualisation
import React, { useEffect, useRef, useState } from "react";
import { MapControls, OrbitControls } from "@react-three/drei";
import { useStore } from "Store";
import {
  useActualLayer,
  useActualPlaceholder,
  useLayers,
  useMap,
} from "Utils/hooks";
import { useThree } from "@react-three/fiber";
import { MOUSE } from "three";
import { TOUCH } from "three";
import { MathUtils } from "three";

const Orbital = () => {
  const activePoi = useStore((s) => s.activePoi);
  const data = useStore();
  const position = activePoi?.position;
  const { actualLayer } = useActualLayer();
  const [curlayer, setcurrlayer] = useState(actualLayer);
  const { configuration } = useMap();
  const ref = useRef();

  useEffect(() => {
    setcurrlayer(actualLayer?.id);
    actualLayer?.id !== curlayer && ref.current.reset();
  }, [actualLayer]);

  const {
    maxDist,
    minDist,
    maxPolarAngle,
    enablePan,
    enableRotate,
    rotationSpeed,
    screenSpacePanning,
    target,
    autoRotate,
    polarAngles,
  } = configuration ?? {};
  const [min, max] = polarAngles ?? [0, 90];
  return (
    <OrbitControls
      ref={ref}
      makeDefault={true}
      mouseButtons={{
        LEFT: MOUSE.PAN,
        MIDDLE: MOUSE.DOLLY,
        RIGHT: MOUSE.ROTATE,
      }}
      touches={{
        ONE: TOUCH.PAN,
        TWO: TOUCH.DOLLY_ROTATE,
      }}
      zoomSpeed={1.5}
      // enableRotate={false}
      enablePan={enablePan ?? true}
      enableRotate={enableRotate ?? true}
      enableDamping
      dampingFactor={0.5}
      screenSpacePanning={false}
      minPolarAngle={MathUtils.degToRad(min)}
      maxPolarAngle={MathUtils.degToRad(max)}
      // minAzimuthAngle={0}
      // maxAzimuthAngle={Math.PI}
      // autoRotate={autoRotate || false}
      // autoRotateSpeed={rotationSpeed || 1}
      maxDistance={maxDist || 150}
      minDistance={minDist || 5}
    />
  );
};

const Map = ({ freeControls, previewMap }) => {
  const { configuration } = useMap();
  const camera = useThree((s) => s.camera);
  const setStoreValue = useStore((s) => s.setValue);
  const editorMapPan = useStore((s) => s.editorMapPan);

  const { data } = useActualPlaceholder();
  const ref = useRef();

  useEffect(() => {
    previewMap && setStoreValue("camera", camera);
  }, [camera, previewMap]);

  const {
    maxDist,
    minDist,
    enablePan,
    enableRotate,
    rotationSpeed,
    screenSpacePanning,
    target,
    autoRotate,
    polarAngles,
  } = { ...configuration };

  const [min, max] = polarAngles ?? [0, 90];
  return (
    <MapControls
      ref={ref}
      // makeDefault={true}
      // enablePan={(freeControls && editorMapPan) ?? enablePan}
      // enableRotate={freeControls ?? enableRotate}
      // dampingFactor={0.3}
      // // target={target ?? [0, 0, 0]}
      // screenSpacePanning={screenSpacePanning ?? false}
      // minPolarAngle={MathUtils.degToRad(min)}
      // maxPolarAngle={MathUtils.degToRad(max)}
      // autoRotate={autoRotate ?? false}
      // autoRotateSpeed={rotationSpeed ?? 1}
      // maxDistance={maxDist ?? 200}
      // minDistance={minDist ?? 20}
      // maxZoom={maxDist ?? 100}
      // minZoom={minDist ?? 5}
    />
  );
};
export { Orbital, Map };
