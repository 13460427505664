import { config, useTransition, animated } from "@react-spring/three";

import React from "react";

import { useDisplayedPlaceholders } from "Utils/hooks/placeholders";
import Marker3D from "./3D/Marker";
import Marker2D from "./HTML/Marker";
const AnimMarker3D = animated(Marker3D);

//Render de tous les placeholders d'un étage
const Placeholders = React.memo(() => {
  const { placeholders } = useDisplayedPlaceholders();
  

  const transitions = useTransition(placeholders, {
    from: (item) => ({
      pos: [item?.position?.x, 5, item?.position?.z],
      opacity: 0,
    }),
    enter: (item) => ({
      pos: [item?.position?.x, 0, item?.position?.z],
      opacity: 1,
    }),
    leave: (item) => ({
      pos: [item?.position?.x, 5, item?.position?.z],
      opacity: 0,
    }),
    expires: 100,
    config: config.gentle,
  });
  if (!placeholders) {
    return null;
  } else {
    return transitions(({ pos, opacity }, data) => {
      return data?.marker?.type === "2D" ? (
        <Marker2D pos={pos} opacity={opacity} key={data?.id} data={data} />
      ) : (
        <AnimMarker3D pos={pos} opacity={opacity} key={data?.id} data={data} />
      );
    });
  }
});

export default Placeholders;
