import React from "react";
import Box from "@mui/material/Box";
import { LogoAnim } from "Components/Lottie";
import { Typography } from "@mui/material";

export default function Loader({ msg }) {
  return (
    <Box
      flex={1}
      width="100%"
      height="100%"
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
    >
      <LogoAnim isLoop />
      <Typography>{msg ?? "Chargement"}</Typography>
    </Box>
  );
}
