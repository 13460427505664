import chroma from "chroma-js";
import { useStore } from "Store";
import { useLayers } from "./layers";
/**
 * Hook de géopositionnement.
 * Retourne les données du layer utilisé et les méthodes addPoint
 */
export const useGeoPositionMap = () => {
  const layer = useStore((s) => s.geoModal);
  const setValue = useStore((s) => s.setValue);
  const geoPoints = layer?.geoPoints ? [...layer?.geoPoints] : [];
  const { setGeoPoints } = useLayers(layer?.id);

  const addPoint = (point) => {
    const newData = {
      ...layer,
      geoPoints: [
        ...geoPoints,
        { position: point, color: chroma.random().hex() },
      ],
    };
    setValue("geoModal", newData);
  };

  const addCoords = (formValues) => {
    const test = Object.keys(formValues).reduce((acc, value, index) => {
      const [param, geoIndex] = value.split("_");
      return {
        ...acc,
        [geoIndex]: { ...acc[geoIndex], [param]: formValues[value] },
      };
    }, {});

    const newPoints = [...layer.geoPoints].map((p, i) => ({
      ...p,
      ...test[i],
    }));
    setGeoPoints(newPoints);
  };

  const deletePoint = (index) => {
    let newArr = [...geoPoints]?.filter((p, i) => i !== index);
    let newData = { ...layer, geoPoints: newArr };
    setValue("geoModal", newData);
  };

  const close = () => setValue("geoModal", null);

  return {
    layer,
    geoPoints: layer?.geoPoints,
    addPoint,
    addCoords,
    close,
    deletePoint,
  };
};