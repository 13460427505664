import React from "react";
import { SettingsControls } from "Components/Threejs/Config";
import { TopBar } from "Components//Global";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import { ToastContainer } from "react-toastify";
import ConfigPanel from "Components/Leaflet/Config/configPanel";
import LeafletEditorMap from "Components/Leaflet/Editor/LeafletAdminMap";
import { Box } from "@mui/material";


const LeafletConfigPage = () => {
  return (
    <Box display='flex' flexDirection='column' height='100%' overflow={"auto"}>
      <ToastContainer />
      <TopBar />
      <Container maxWidth='xl' sx={{pt:4, height:'100%'}} >
        <Grid container spacing={2} justifyContent="space-around" height="100%" >
          <Grid item xs={12} md={6} maxHeight={'70vh'} minHeight={400}>
            <LeafletEditorMap />
          </Grid>
          <Grid item xs={12} sm={12} md={6} maxHeight={'70vh'} >
            <ConfigPanel />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default LeafletConfigPage;
