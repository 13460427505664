import React, { forwardRef, useMemo, useRef } from "react";
import { meshBounds, useGLTF } from "@react-three/drei";
import { animated } from "@react-spring/three";
import { MeshStandardMaterial } from "three";
import { MathUtils } from "three";

const url = "/GLTF/placeholder.glb";
//Model 3D-Three du placeholder
const BasicModel = ({ color, rotation, scale, opacity, ...props }, ref) => {
  const group = useRef();
  const { nodes } = useGLTF(url, "/draco-gltf");

  const material = useMemo(
    () =>
      new MeshStandardMaterial({
        color: color ?? "#028090",
        roughness: 0.3,
        emissive: "#000",
        emissiveIntensity: 0.2,
        transparent: true,
        opacity: opacity ?? 1,
      }),
    [color, opacity]
  );

  return (
    <>
      <animated.group
        ref={group}
        {...props}
        scale={scale.map((s) => s * 0.05)}
        rotation={[0, 0, MathUtils.degToRad(0)]}
        dispose={null}
      >
        <animated.mesh
          ref={ref}
          castShadow
          receiveShadow
          geometry={nodes.Extrude.geometry}
          material={material}
          position={[0, 0, 0]}
          raycast={meshBounds}
        >
          <animated.mesh
            castShadow
            receiveShadow
            geometry={nodes.Cap_1.geometry}
            position={[0.13, 22.2, 8.19]}
            material={material}
          />
          <animated.mesh
            castShadow
            receiveShadow
            geometry={nodes.Cap_2.geometry}
            position={[0.13, 22.2, 8.19]}
            material={material}
          />
        </animated.mesh>
      </animated.group>
    </>
  );
};
useGLTF.preload(url);

export default forwardRef(BasicModel);
