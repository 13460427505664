import { client, GET_SINGLE_PLACEHOLDER } from "Config/API/queries";
import { setIn } from "formik";
import { useCallback, useEffect, useRef, useState } from "react";
import { useLocation, useParams } from "react-router";
import { useSearchParams } from "react-router-dom";
import useDebounce from "./debounce";
// import { useParams} from 'react-router-dom'
import { useActualLayer } from "./layers";
import { useActualPlaceholder, useDisplayedPlaceholders } from "./placeholders";

//NE PEUT ETRE UTILISE QUE DANS UN CHILD DU ROUTER
export const useQueryParam = () => {
  // const query = new URLSearchParams(useLocation().search);

  let [query, setSearchParams] = useSearchParams();

  const placeholderId = query.get("placeholderId");
  const layerId = query.get("layerId");
  const filter = query.get("filter");

  const { id, set } = useActualLayer();
  const { setFilter, placeholders } = useDisplayedPlaceholders();
  const { set: setActivePh } = useActualPlaceholder();
  const [initialized, setInitialized] = useState(false);
  const countRef = useRef(0);
  const debounceClearParams = useDebounce(() => setSearchParams({}), 1000);

  const handlePlaceholder = useCallback(async () => {
    try {
      if (placeholderId) {
        const { data } = await client.query({
          query: GET_SINGLE_PLACEHOLDER,
          variables: { id: placeholderId },
        });

        const { layerId, parentId, children } = data?.placeholder ?? {};

        layerId !== id && set(layerId);
        parentId && setFilter({ placeholderId: parentId, filter: filter });
        placeholderId && setActivePh(placeholderId);
        // setSearchParams({  });
        children?.length
          ? setFilter({ placeholderId: placeholderId, filter: filter })
          : setActivePh(placeholderId);

        (!parentId || !children?.length) && setActivePh(placeholderId);
        debounceClearParams();
      } else if (layerId) set(layerId);
    } catch (error) {
      console.log("error:", error);
    }
  }, [id, placeholderId, layerId, filter, set, setFilter, setActivePh]);

  useEffect(() => {
    if (
      placeholderId &&
      countRef.current !== placeholders?.length &&
      !initialized
    ) {
      countRef.current = placeholders?.length;
      placeholderId && handlePlaceholder();
      // setInitialized(true);
    }
  }, [placeholders, initialized, placeholderId]);

  return { placeholderId, layerId };
};
