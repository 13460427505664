import { MathUtils } from "three";
const { projectId } = window.__CONFIG__ ?? {};

const scaleValue = (value, from, to) => {
  var scale = (to[1] - to[0]) / (from[1] - from[0]);
  var capped = Math.min(from[1], Math.max(from[0], value)) - from[0];
  return capped * scale + to[0];
};

export const getXFromLongitude = (longitude, geoPoints) => {
  const longitudes = geoPoints
    .reduce((acc, val) => [...acc, val.longitude], [])
    .sort((a, b) => a - b);
  const xValues = geoPoints
    .reduce((acc, val) => [...acc, val.position.x], [])
    .sort((a, b) => a - b);
  const newX = scaleValue(longitude, longitudes, xValues);

  return newX;
};

export const getYFromLatitude = (latitude, geoPoints) => {
  const latitudes = geoPoints
    .reduce((acc, val) => [...acc, val.latitude], [])
    .sort((a, b) => a - b);
  const yValues = geoPoints
    .reduce((acc, val) => [...acc, val.position.y], [])
    .sort((a, b) => a - b);

  const newY = scaleValue(latitude, latitudes, yValues);
  return newY;
};

const calculMatrix = (geoPoints) => {
  if (!geoPoints || geoPoints?.length < 2) return null;
  const points = geoPoints.map(geoPoint => {
    const { longitude, latitude, position } = geoPoint
    return { position, ...rotateCoords({ longitude, latitude })}
  })
  const sx =
    (points[1].position.x - points[0].position.x) /
    (points[1].longitude - points[0].longitude);
  const sy =
    (points[1].position.y - points[0].position.y) /
    (points[1].latitude - points[0].latitude);
  const dx = points[0].position.x - sx * points[0].longitude;
  const dy = points[0].position.y - sy * points[0].latitude;
  return [
    [sx, 0, dx],
    [0, sy, dy],
    [0, 0, 1],
  ];
};
const getMapPosition = (matrix, point) => {
  return {
    position: {
      x:
        matrix[0][0] * point.longitude +
        matrix[0][1] * point.latitude +
        matrix[0][2],
      y:
        matrix[1][0] * point.longitude +
        matrix[1][1] * point.latitude +
        matrix[1][2],
        z:0
    },
    longitude: point.longitude,
    latitude: point.latitude,
  };
};

export const convertCoords = (latitude, longitude, geoPoints) => {
  const matrix = calculMatrix(geoPoints);
  
  if (!matrix) return null;
  const point = getMapPosition(matrix, rotateCoords({ latitude, longitude }));

  return point;
};


const rotateCoords = ({ longitude, latitude }) => {
  const teta = MathUtils.degToRad(projectId === 'chfb' ? 110 : 0)

  return {
    longitude: Math.cos(teta) * longitude - Math.sin(teta) * latitude,
    latitude: Math.sin(teta) * longitude + Math.cos(teta) * latitude
  }
}
