import { Box, Button, Stack } from "@mui/material";
import { render } from "@testing-library/react";
import AddressFinder from "Components/Leaflet/Editor/addressFinder";
import React, { useCallback } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useTranslation } from "react-i18next";
import {
  useActualPlaceholder,
  usePlaceholders,
  useLayers,
  useMap,
} from "Utils/hooks";
import { Card } from "./Card";
import ContentPlaceholder from "./ContentPlaceholder";

// import SearchBar from "./SearchBar";

const EditorSidePanel = () => {
  const { placeholders, addPlaceholder } = usePlaceholders();
  const { layers } = useLayers();
  const { set } = useActualPlaceholder();
  const { leafletInstance } = useMap();

  const handleAdd = (val) => {
    const newPh = addPlaceholder(val);
    set(newPh?.id);
    leafletInstance?.flyTo?.([newPh?.position.y, newPh.position.x], 13, {
      animate: true,
      duration: 2,
    });
  };

  const handleAddressAdd = async (data) => {
    const { lon, lat, display_name } = data ?? {};
    const position = {
      x: parseFloat(lon),
      y: parseFloat(lat),
      z: 0,
    };
    handleAdd({ position, name: display_name });
  };

  const { t } = useTranslation();
  const { type } = useMap();
  const renderCard = useCallback((card, index) => {
    return (
      <Card
        key={card.id}
        index={index}
        id={card.id}
        data={card}
      />
    )
  }, [placeholders])
  return (
    <Box minWidth={400} height="100%" boxShadow={2} px={4}>
      <Box
        height="calc(100% - 80px)"
        // px={1}
        style={{ overflowY: "scroll", overflowX: "hidden" }}
        width="100%"
      >
        <Box
          mt={1}
          py={3}
          zIndex={2}
          style={{ backgroundColor: "white" }}
          position="sticky"
          top={8}
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
        >
          {type === "leaflet" && (
            <AddressFinder
              disabled={!layers?.length}
              callback={handleAddressAdd}
            />
          )}
          <Button
            onClick={() => handleAdd()}
            variant="contained"
            color="primary"
            disabled={!layers?.length}
          >
            {t("/editor.sideBar.add_placeholder")}
          </Button>
        </Box>
        {/* <DndProvider backend={HTML5Backend}> */}
          {placeholders?.map(
            (p, i) => !p?.parentId && 
            // <Card key={p?.id} data={p} index={i} />
            <ContentPlaceholder index={i} key={p.id} data={p} />
          )}
        {/* </DndProvider> */}
      </Box>
    </Box>
  );
};

export default EditorSidePanel;
