import { addProtocol } from "Utils/addProtocol";
const appUrl = window.__CONFIG__.visitUrl || window.location.host;
const split = appUrl.split(".");
const appDomain =
  split.length > 1
    ? split[split.length - 2] + "." + split[split.length - 1]
    : appUrl;

const GQL_URL = addProtocol(window.__CONFIG__.apiUrl + "/gql");
const DOMAIN = appDomain;
const API_URL = addProtocol(window.__CONFIG__.apiUrl);
const KC_URL = addProtocol(window.__CONFIG__.keycloak.authUrl);
const KC_REALM = window.__CONFIG__.keycloak.realm;
const KC_CLIENT = 'wivi-login';
const PROJECT_ID = window.__CONFIG__.projectId

export {
  GQL_URL,
  DOMAIN,
  API_URL,
  KC_CLIENT,
  KC_URL,
  KC_REALM,
  PROJECT_ID
};
