import { client } from "./apollo";
import { gql } from "@apollo/client";

export { client };

export const GET_MAP = gql`
  query GET_MAP($uuid: String!, $iso: [LanguageCode!]!) {
    map(id: $uuid) {
      id
      type
      i18n(languages: $iso) {
        title {
          value {
            text
          }
        }
      }
      layers {
        data
        name

        title {
          value {
            text
            html
          }
          language
        }
        position {
          x
          y
          z
        }
        geoPoints {
          color
          latitude
          longitude
          altitude
          position {
            x
            y
            z
          }
        }
        scale
        rotation
        i18n(languages: $iso) {
          title {
            value {
              text
            }
          }
          description {
            value {
              text
            }
          }
        }
        placeholders {
          i18n(languages: $iso) {
            title {
              value {
                text
              }
            }
          }
          id
          index
          data
          layerId
          position {
            x
            y
            z
            unit
          }
          marker
          duration
          name
          title {
            value {
              text
              html
            }
            language
          }
          target
          parentId
          children {
            id
            i18n(languages: $iso) {
            title {
              value {
                text
                html
              }
            }
          }
            index
            layerId
            name
            title {
              value {
                text
                html
              }
              language
            }
            marker
            duration
            parentId
            target
            position {
              x
              y
              z
            }
          }
        }
        geographyTransformationMatrix
        id
        image {
          id
          file {
            width
            height
            url
          }
        }
      }
      configuration
    }
  }
`;

export const GET_MAPS = gql`
  query getMaps($iso: [LanguageCode!]!) {
    maps {
      id
      type
      title {
        language
        value {
          text
          html
        }
      }
      i18n(languages: $iso) {
        title {
          value {
            text
            html
          }
        }
      }
      layers {
        name
        title {
          value {
            text
            html
          }
          language
        }
        data
        position {
          x
          y
          z
        }
        geoPoints {
          color
          latitude
          longitude
          altitude
          position {
            x
            y
            z
          }
        }
        scale
        rotation
        i18n(languages: $iso) {
          title {
            value {
              text
              html
            }
          }
          description {
            value {
              text
            }
          }
        }
        placeholders {
          i18n(languages: $iso) {
            title {
              value {
                text
                html
              }
            }
          }
          id
          data
          index
          layerId
          position {
            x
            y
            z
            unit
          }
          marker
          duration
          name
          title {
            value {
              text
              html
            }
            language
          }
          target
          parentId
          children {
            i18n(languages: $iso) {
            title {
              value {
                text
                html
              }
            }
          }
            id
            index
            layerId
            name
            title {
              value {
                text
                html
              }
              language
            }
            marker
            duration
            parentId
            target
            position {
              x
              y
              z
            }
          }
        }
        geographyTransformationMatrix
        id
        image {
          id
          file {
            width
            height
            url
          }
        }
      }
      configuration
    }
  }
`;

export const SEARCH_CONTENT = gql`
  query search($text: String!) {
    search(text: $text) {
      collections {
        id
        i18n(languages: "fr") {
          title {
            value {
              text
              html
            }
          }
        }
      }
      pointsOfInterest {
        id
        i18n(languages: "fr") {
          title {
            value {
              text
              html
            }
          }
        }
      }
    }
  }
`;

export const GET_POI = gql`
  query getSinglePoi($uuid: UUID!, $iso: LanguageCode!, $target: I18nTarget) {
    pointOfInterest(id: $uuid) {
      duration
      collections {
        id
        i18n(languages: [$iso]) {
          title {
            value {
              text
              html
            }
          }
        }
      }
      i18n(languages: [$iso], target: $target) {
        title {
          value {
            text
            html
          }
        }
      }
      title {
        language
        target
        value {
          text
          html
        }
      }
      image {
        formats {
          file {
            url
          }
          name
        }
      }
    }
  }
`;

export const GET_COLLECTION = gql`
  query getSingleCollection(
    $uuid: UUID!
    $iso: LanguageCode!
    $target: I18nTarget
  ) {
    collection(id: $uuid) {
      duration
      i18n(languages: [$iso], target: $target) {
        title {
          value {
            text
            html
          }
        }
      }
      title {
        language
        target
        value {
          text
          html
        }
      }
      image {
        formats {
          file {
            url
          }
          name
        }
      }
      pointsOfInterest {
        itemsCount
        items {
          i18n(languages: [$iso]) {
            title {
              value {
                text
                html
              }
            }
          }
          id
          # placeholders {
          #   id
          #   layer {
          #     id
          #   }
          #   marker
          # }
        }
      }
    }
  }
`;

export const ALL_COLLECTIONS = gql`
  query getAllCollections {
    __typename
    collections {
      items {
        id
        duration
        image {
          formats {
            name
            file {
              url
            }
          }
        }
        name
        i18n(languages: "fr") {
          title {
            value {
              text
            }
          }
        }
      }
    }
  }
`;

export const ALL_POIS = gql`
  query getAllPois {
    __typename
    pointsOfInterest {
      items {
        id
        duration
        collections {
          id
          i18n(languages: "fr") {
            title {
              value {
                text
              }
            }
          }
        }
        image {
          formats {
            name
            file {
              url
            }
          }
        }
        name
        i18n(languages: "fr") {
          title {
            value {
              text
            }
          }
        }
      }
    }
  }
`;

export const GET_DATASET = gql`
  query MyQuery {
    dataset(key: "fibermap") {
      value
    }
  }
`;

export const GET_IMAGE_PATH = gql`
  query GetImagePath($uuid: UUID!) {
    image(id: $uuid) {
      formats {
        file {
          url
        }
        name
      }
    }
  }
`;

export const GET_LAYER = gql`
  query getLayer($uuid: String!) {
    layer(id: $uuid) {
      data
      placeholders {
        data
        id
        position {
          x
          y
          z
        }
        marker
        name
      }
    }
  }
`;

export const GET_SINGLE_PLACEHOLDER = gql`
  query getSinglePlaceholder($id: String!) {
    placeholder(id: $id) {
      layerId
      id
      parentId
      children {
        id
      }
    }
  }
`;

export const GET_ALL_PLACEHOLDERS_TARGETS = gql`
  query getAllPlaceHolders {
    placeholders {
      target
      data
    }
  }
`;

export const GET_LANGUAGES = gql`
  query getLanguages {
    languages(isEnabled: true) {
      code
      name
    }
  }
`;
